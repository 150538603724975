import * as React from "react";
import { callApi } from "../../utils/api";
import Select from 'react-select';
interface DsaItem {
  id?: string;
  name: string;
  mobile: string;
  email: string;
}
type AllProps = {
  navigateToDocs: any;
  initialValue: any;
  required: any;
};
export class DSAForm extends React.Component<AllProps> {
  state = this.props.initialValue;
  componentDidMount() {
    this.getBanks();
    this.getBankBranches();
    this.getLawHandsBranches();
    if (!this.state.dsalist || !this.state.dsalist.length) {
      this.setState({ dsalist: [] });
      this.addItem();
    }
    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.dsalist) {
      delete_items.dsalist = [];
    }
    this.setState({ delete_items });
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const state = this.props.initialValue;
      this.setState({ ...this.props.initialValue })
    }
  }
  getBanks() {
    callApi("GET", "bank_get.php")
      .then(res => res.data)
      .then(result => {
        this.setState({ banks: result.data });
      })
      .catch(err => {
        console.log(err);
      });
  }
  getBankBranches() {
    callApi("GET", "bank_branch_get.php")
      .then(res => res.data)
      .then(result => {
        this.setState({ branches: result.data, filteredBranches: [] });
        if (this.state.bank_name) {
          this.filterBranches(this.state.bank_name);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  getLawHandsBranches() {
    callApi("GET", "get_branch.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ lawhandsBranches: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  }
  filterBranches = bankid => {
    const filteredBranches = this.state.branches.filter(
      branch => branch.bank_id === bankid
    );
    const state = this.state;
    state.filteredBranches = filteredBranches;
    this.setState({ ...state });
  };
  addItem = (): void => {
    const dsalist: DsaItem[] = this.state.dsalist ? this.state.dsalist : [];
    dsalist.push({ name: "", mobile: "", email: "" });
    this.setState({ dsalist });
  };
  removeItem = (modal: DsaItem, index: number): void => {
    const dsalist: DsaItem[] = this.state.dsalist;
    dsalist.splice(index, 1);

    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.dsalist) {
      delete_items.dsalist = [];
    }
    delete_items.dsalist.push(modal.id);
    this.setState({ dsalist, delete_items });
    this.handleSubmit();
  };
  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    if (fieldName === "lawhands_branch") {
      const branch = fieldValue;
      // const filteredUsers = this.state.users.filter(
      // 	(itm: any) => itm.branch_id === branch
      // );
      const filteredBranches = this.state.branches.filter(
        branch => branch.bank_id === branch
      );
      const state = this.state;
      state[fieldName] = fieldValue;
      this.setState({ filteredBranches });
    }
    if (fieldName === "product_id") {
      console.log("e---", e.product_id);

      let newProductId = e.product_id;
      const state = this.state;
      state[fieldName] = newProductId;
      console.log(`${fieldName} ---- ${fieldValue}`);
      this.filterBranchesproduct(fieldValue);

      console.log("filteredProduct", this.state);
    }
    if (fieldName === "bank_name") {
      // this.setState({ product_id: 0 })
      const state = this.state;
      console.log("192", state);
      state[fieldName] = fieldValue;
      this.filterProduct(fieldValue);
      this.filterBranches(fieldValue);
    }
    if (fieldName != "product_id") {
      const state = this.state;
      state[fieldName] = fieldValue;
      this.setState({ ...state });
    }
    console.log("this.state", this.state);
    this.props.navigateToDocs(this.state);
  };
  filterBranchesproduct = productid => {
    const filteredBranches = this.state.branches.filter(branch => {
      return branch.product_id === productid && branch.bank_id === this.state.bank_name;
    });

    const state = this.state;
    state.filteredBranches = filteredBranches;
    this.setState({ ...state });
    console.log("filteredBranches", filteredBranches);
  };

  filterProduct = bankid => {
    const filteredProduct = this.state.products.filter(
      branch => branch.bank_id === bankid
    );
    const state = this.state;
    state.filteredProduct = filteredProduct;
    this.setState({ ...state });
  };
  handleDsaChange = (e: any, index: number) => {
    const dsalist: DsaItem[] = this.state.dsalist;
    dsalist.forEach((itm: any, i) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ dsalist });
    this.props.navigateToDocs(this.state);
  };

  handleSubmit = () => {
    const state = this.state;
    // state.isNext = true;
    this.props.navigateToDocs(state);
  };
  render() {
    const filteredProduct = [
      { value: "", label: "" },
      ...this.state.filteredProduct
    ];
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Bank Name<span style={{ color: "red" }}>*</span></label>

              {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                opt.label = opt.name; opt.value = opt.id
              }) &&
                <Select options={this.state.banks} name="bank_name" className={this.props.required && this.state.bank_name === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.banks.find((o: any) => o.value === this.state.bank_name)}
                  onChange={(e) => this.handleChange(e, 'bank_name', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Product<span style={{ color: "red" }}>*</span></label>
              {this.state.products && this.state.products.map((opt: any) => {
                opt.label = opt.product_name; opt.value = opt.id
              }) &&
                <Select options={filteredProduct} name="product_id" className={this.props.required && this.state.product_id === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.filteredProduct.find((o: any) => o.product_id === this.state.product_id ? this.state.product_id : "")}
                  onChange={(e) => this.handleChange(e, 'product_id', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Bank Branch<span style={{ color: "red" }}>*</span></label>
                </div>
              </div>
              {this.state.branches && this.state.branches.length > 0 && this.state.branches.map((opt: any) => {
                opt.label = opt.name; opt.value = opt.id
              }) &&
                <Select options={this.state.filteredBranches} name="branch" className={this.props.required && this.state.branch === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.filteredBranches.find((o: any) => o.value === this.state.branch)}
                  onChange={(e) => this.handleChange(e, 'branch', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          {/* </div>
        <div className="row"> */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Lawhands Branch<span style={{ color: "red" }}>*</span></label>
              {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                opt.label = opt.name; opt.value = opt.id
              }) &&
                <Select options={this.state.lawhandsBranches} name="lawhands_branch" className={this.props.required && this.state.lawhands_branch === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
                  onChange={(e) => this.handleChange(e, 'lawhands_branch', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Borrower Name<span style={{ color: "red" }}>*</span></label>
                </div>
              </div>
              <input
                className={this.props.required && this.state.borrower_name === '' ? 'form-control is-invalid' : 'form-control'}
                name="borrower_name"
                type="text"
                value={this.state.borrower_name}
                onChange={e => this.handleChange(e)}
              />
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Agent Name</label>
                </div>
              </div>
              <input
                className="form-control"
                name="name"
                type="text"
                value={this.state.name}
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Mobile</label>
                </div>
              </div>
              <input
                className="form-control"
                name="mobile"
                type="number"
                value={this.state.mobile}
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Email</label>
                </div>
              </div>
              <input
                className="form-control"
                name="email"
                type="email"
                value={this.state.email}
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className=" text-right">
          <button
            type="button"
            className="btn btn-primary btn-sm "
            onClick={() => this.addItem()}
          >
            Add +
          </button>
        </div>
        {this.state.dsalist && this.state.dsalist.length
          ? this.state.dsalist.map((dsaItem: DsaItem, index: number) => (
            <div className="row" key={index}>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        <a onClick={() => this.removeItem(dsaItem, index)}>
                          <i className="feather icon-trash" />
                        </a>
                        Property Owner Name
                      </label>
                    </div>
                  </div>
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={dsaItem.name}
                    onChange={e => this.handleDsaChange(e, index)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Mobile</label>
                    </div>
                  </div>
                  <input
                    className="form-control"
                    name="mobile"
                    type="number"
                    value={dsaItem.mobile}
                    onChange={e => this.handleDsaChange(e, index)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                    </div>
                  </div>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={dsaItem.email}
                    onChange={e => this.handleDsaChange(e, index)}
                  />
                </div>
              </div>
            </div>
          ))
          : ""}
        {/* <div className="text-right">
          <button
            type="button"
            className="btn btn-primary btn-sm "
            onClick={this.handleSubmit}
          >
            Next
          </button>
        </div> */}
      </div>
    );
  }
}

export default DSAForm;
