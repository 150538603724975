import React, { useState, useEffect } from 'react'
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import moment from 'moment';
import ReactFileReader from "react-file-reader";
// import AddQuery from '../query/AddQuery';
import ReplyQuery from './ReplyQuery';
import { callApi } from '../../utils/api';
import _ from 'lodash';

const FolderPlusIconSvg = ({ className }) => {
  return (
    <svg className={`svg-small-icon ${className}`} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g xmlns="http://www.w3.org/2000/svg">
        <rect x="-1" y="-1" width="22" height="22" />
        <image id="image0" width="20" height="20" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAANklEQVRIiWNgGAWDCfwngDtobQFZljCiWUBNwMjAwMDARGVDMcCoBaMWjFowasFgsGAUDDwAABZHExtUx3tkAAAAAElFTkSuQmCC" />
        <path d="M16.164 11.076H13.608V13.668H12.588V11.076H10.044V10.152H12.588V7.548H13.608V10.152H16.164V11.076Z" fill="white" />
      </g>
    </svg>
  )
}

const FolderMinusIconSvg = ({ className }) => {
  return (
    <svg className={`svg-small-icon ${className}`} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g xmlns="http://www.w3.org/2000/svg">
        <rect x="-1" y="-1" width="22" height="22" />
        <image id="image0" width="20" height="20" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAANklEQVRIiWNgGAWDCfwngDtobQFZljCiWUBNwMjAwMDARGVDMcCoBaMWjFowasFgsGAUDDwAABZHExtUx3tkAAAAAElFTkSuQmCC" />
        <path d="M15.484 10.14V11.064H10.696V10.14H15.484Z" fill="white" />
      </g>
    </svg>
  )
}
const FolderIconSvg = ({ className }) => {
  return (
    <svg className={`svg-small-icon ${className}`} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g xmlns="http://www.w3.org/2000/svg">
        <rect x="-1" y="-1" width="22" height="22" />
        <image id="image0" width="20" height="20" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAANklEQVRIiWNgGAWDCfwngDtobQFZljCiWUBNwMjAwMDARGVDMcCoBaMWjFowasFgsGAUDDwAABZHExtUx3tkAAAAAElFTkSuQmCC" />
      </g>
    </svg>
  )
}

const QueryDetails = (props) => {
  const [open, setOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isReplied, setIsReplied] = useState(false);
  const [replies, setReplies] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState({
    name: '', id: '', children: [],
    details: {
      title: "", raice_by: "", to: "", status: "", reason: ""
    }
  })
  const [showPopup, setShowPopup] = useState(false)
  const [isBtnLoad, setIsBtnLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);

  const { fodFk, history, queryIndex, queryData, getQueryTree, queryreason } = props
  const { details, children } = queryData
  const { raice_by, reason, title, to, status, created_date } = details

  const titleColorClass = status === "3" ? "text-c-green" : ""
  const replyBtnStyle = { margin: "6px", height: "30px" };
  const handleShowPopup = () => {
    setSelectedQuery({ ...queryData })
    setShowPopup(true)
  }
  useEffect(() => {
    // console.log("replies", replies);
  }, [])

  const toggleReadMore = (e) => {
    e.stopPropagation()
    setIsReadMore(!isReadMore);
  };

  const uploadFiles = (files, type) => {
    setIsBtnLoad(true);
    const base = [];
    files.base64.forEach((file, index) => {
      base.push({ base: file, details: files.fileList[index], type });
    });
    callApi("POST", "query_upload.php", {
      file_id: fodFk,
      query_id: selectedQuery.id,
      base
    })
      .then(res => {
        setIsBtnLoad(false);
        ToastsStore.success("File(s) Uploaded Successully");
      })
      .catch(err => {
        setIsBtnLoad(false);
      });
  };

  const closeQueryStatus = () => {
    const user = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "query_close.php", {
      id: selectedQuery.id,
      status: 3,
      userId
    })
      .then(res => res.data)
      .then(response => {
        getQueryTree();
        setShowPopup(false)
      })
      .catch(err => {
        console.log(err);
        setShowPopup(false)
      });
  };
  const addReply = () => {
    const childObj = {
      query: '',
      raice_by: '',
      reason: '',
    }
    replies.push(childObj);
    setReplies(replies);
    setRefresh(!refresh);
    queryData.replies = replies;
    props.getReplies(queryData);
    console.log("replies-1", replies);
  }
  const removeReply = (i) => {
    console.log(replies);
    // _.remove(replies, (o, index) => {
    //   return i === index;
    // });
    replies.splice(i, 1);
    setReplies(replies);
    setRefresh(!refresh);
  }
  const handleChange = (e, i) => {
    replies[i][e.target.name] = e.target.value;
    queryData.replies = replies;
    setReplies(replies);
    setRefresh(!refresh);
    props.getReplies(queryData);
  }
  const handleDetailsChange = (e, key, i) => {
    console.log(`key:${key}--e:${e}--i:${i}`);
    replies[i][key] = e;
    queryData.replies = replies;
    setReplies(replies);
    setRefresh(!refresh);
    props.getReplies(queryData);
  }
  const childReplies = (data, i) => {
    queryData.children.forEach(query => {
      if (query.id === data.id) {
        query.replies = data.replies;
      }
    });
    props.getReplies(queryData);
  }
  const selectedItem = (e, data) => {
    if (e.target.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    const obj = {
      query: data.details.title,
      review: data.details.reason,
      query_id: data.id,
      location: data.details.location,
      created_date: moment(data.details.created_date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
    }
    props.setSelectedItem(obj)
    console.log("obj", obj);
  }
  const replyQuery = () => {
    isReplied ? setIsReplied(false) : setIsReplied(true);
    if (replies.length === 0) {
      addReply();
    }
  }
  return (
    <div className="display-flex flex-direction-column query-wrapper">
      <div className="query-checkbox">
        {!isReplied && status !== "3" &&
          <div className="form-check" style={{ zIndex: 5 }}>
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => selectedItem(e, queryData)} />
          </div>
        }
      </div>
      <div className={`display-flex query-child-wrapper${open ? ' query-child-wrapper-open' : ''}`}>
        <div onClick={children.length ? () => setOpen(!open) : () => { }} className={children.length ? "cursor-pointer" : ""}>
          {children.length <= 0 ? <FolderIconSvg className="query-folder-icon" /> : open ? <FolderMinusIconSvg className="query-folder-icon" /> : <FolderPlusIconSvg className="query-folder-icon" />}
        </div>
        <div className="query-title">{queryIndex}</div>
        <div className={`query-title cursor-pointer ${titleColorClass}`} style={{ width: "89%" }}>
          {/* {console.log("Status", status, raice_by)} */}
          <div onClick={() => status !== "3" ? handleShowPopup() : null}>
            <span className={`query-text text-c-magenta ${titleColorClass}`}>{title}</span>
            <br />
            {raice_by && <><b>Raised by</b><span className={`query-text text-c-dark-blue ${titleColorClass}`}>{raice_by}</span></>}
            <br />
            {to && <><b>To</b><span className={`query-text text-c-dark-blue ${titleColorClass}`}>{isReadMore ? to.slice(0, 80) : to}
              <span onClick={toggleReadMore} >
                <u>{isReadMore ? "...read more" : " show less"}</u>
              </span></span></>}
            <br />
            {reason && <><b>Reason for</b><span className={`query-text text-c-magenta ${titleColorClass}`}>{reason}</span></>}
            {created_date && <><b>at</b><span className={`query-text text-c-green ${titleColorClass}`}>{moment(created_date).format('DD MMMM YYYY, HH:mm')}</span></>}
          </div>
          <div className="collapse" id={`replyCollapse${queryData.id}`}>
            {replies &&
              // && replies.length ? replies.map((reply, index) => {
              //   console.log("replies-2", replies);
              //   return (
              <ReplyQuery
                key={"1"}
                reply={""}
                addReply={addReply}
                removeReply={(index) => removeReply(index)}
                handleChange={(e, index) => handleChange(e, index)}
                handleDetailsChange={(e, key, queryIndex) => handleDetailsChange(e, key, queryIndex)}
                queryreason={queryreason}
                addnewQueryList={replies}

              />
              // );
            }
            {/* ) : null} */}
          </div>
        </div>
        {status !== "3" && !isChecked &&
          <button type="button" className="btn btn-info btn-sm" data-toggle="collapse"
            data-target={`#replyCollapse${queryData.id}`} aria-expanded="false"
            aria-controls="collapseExample" onClick={replyQuery} style={replyBtnStyle}>
            Requery
            <i className="fa fa-reply" aria-hidden="true"></i>
          </button>
        }
      </div>
      {open && children && children.length ?
        <div className="query-child">
          {children.map((queryData, index) =>
            <QueryDetails key={queryData.id} queryData={queryData} history={history} fodFk={fodFk} queryIndex={`${queryIndex}.${index + 1}`} getQueryTree={getQueryTree} setSelectedItem={(query) => props.setSelectedItem(query)}
              getReplies={(queryData) => childReplies(queryData, index)} queryreason={queryreason}
            />
          )}
        </div>
        : null}
      {showPopup &&
        <div
          className="dialog-modal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="dialog-modal-body" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{selectedQuery.name}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowPopup(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{selectedQuery.details.title}</p>
                <div style={{ width: '100%', textAlignLast: 'center' }}>
                  <button
                    onClick={closeQueryStatus}
                    className="justify-content-center ml2 btn btn-success btnupload text-center"
                  // disabled={isBtnLoad}
                  >
                    Complete
                  </button>
                </div>
                {/* <p>Raise by : {selectedQuery.details.raice_by} </p>
              <p>to : {selectedQuery.details.to} </p>
              <p>Status : {selectedQuery.details.status} </p>
              <p>Reason : {selectedQuery.details.reason}</p>
              <div className="btn-group mb-2 mr-2">
                <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
                <div className="dropdown-menu" x-placement="top-start" style={{ position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, -2px, 0px)' }}>
                  <a className="dropdown-item" data-dismiss="modal" onClick={() => { history.push(`/query/add/${selectedQuery.id}/${fodFk}`) }}>Reply</a>
                  <a className="dropdown-item" onClick={closeQueryStatus}>Close</a>
                </div>
              </div> */}
                {/* <div className="btn-group mb-2 mr-2">
                <ReactFileReader
                  fileTypes={[".pdf"]}
                  base64={true}
                  multipleFiles={true}
                  handleFiles={e => uploadFiles(e, "other")}
                >
                  <button
                    className="ml2 btn btn-primary btnupload float-right"
                    disabled={isBtnLoad}
                  >
                    {isBtnLoad && (
                      <i className="fa fa-spinner fa-spin"></i>
                    )}
                              Upload
                            </button>
                </ReactFileReader>
              </div> */}
              </div>
              {/* <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={() => setShowPopup(false)} data-dismiss="modal">Close</button>
              </div> */}
            </div>
          </div>
        </div>
      }
    </div>)
}

export default QueryDetails