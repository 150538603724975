import * as React from "react";
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import "./eodStyles.css";
import Select from 'react-select';
import moment from "moment";
import { DatePicker } from "antd";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
declare var $: any;

interface AllProps {
    history: any;
    match: any;
}
class EodReport extends React.Component<AllProps> {
    state = {
        queries: [],
        fromdate: "",
        todate: "",
        banks: [],
        bank_name: "",
        lawhandsBranches: [],
        lawhands_branch: "",
        users: [],
        assigned_to: "",
        id: "",
        outreason: "",
        selectVal: false,
        isLoading: false
    };
    tableDetails: any;
    table: any;
    componentDidMount() {
        this.getQueries();
        this.getLawHandsBranches();
        this.getBanks();
        this.getUsers();
        this.getOutTime();
        this.ouTime();
    }
    getQueries = () => {
        const user: any = sessionStorage.getItem("user");
        const user_idfk = user ? JSON.parse(user)["userId"] : "";
        callApi("POST", "get_verification_eod.php", {
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            // bank: this.state.bank_name,
            // branch: this.state.lawhands_branch,
            // emp_name: this.state.assigned_to,
            // file_id: this.state.id,
        })
            .then(res => res.data)
            .then(response => {
                let responseData = response.data.map((data, index) => {
                    return {
                        query: data.eod_data,
                        detail: {
                            ...data
                        }
                    }
                });
                $(document).ready(() => {
                    this.tableDetails = $("#eodReportTable").DataTable({
                        dom: 'Bfrtip',
                        buttons: [
                            { extend: 'excel', text: 'Download as Excel' }
                        ],
                        columnDefs: [{
                            orderable: false,
                            className: 'select-checkbox',
                            targets: 0
                        }],
                        order: [[1, 'asc']],
                        select: {
                            style: 'os',
                            selector: 'td:first-child'
                        },
                    });

                });
                console.log("responseData1", responseData)
                if (responseData) {
                    this.setState({ queries: responseData }, () => {
                        console.log("responseData", responseData)
                    });
                }
                else {
                    this.setState({ queries: [] });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawhandsBranches: response.data });


            })
            .catch(err => {
                console.log(err);
            });
    }

    getBanks() {
        callApi("GET", "bank_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ banks: result.data });
            })
            .catch(err => {
                console.log(err);
            });
    }
    getUsers = () => {
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const user_id = user ? JSON.parse(user)["userId"] : null;
        const userDetails = user ? JSON.parse(user) : "";
        const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
        const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
        if (isAdmin) {
            callApi("GET", "user_get.php")
                .then(res => res.data)
                .then(response => { this.setState({ users: response.data }); })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            callApi("POST", "team_leader.php", { "user_id": user_id })
                .then(res => res.data)
                .then(response => {
                    console.log("210", response);
                    this.setState({ users: response.data });

                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    // sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem('currentfileId');
                    }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };

    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };

    getQueriesWithoutDT = () => {
        const user: any = sessionStorage.getItem("user");
        const user_idfk = user ? JSON.parse(user)["userId"] : "";
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const user_id = user ? JSON.parse(user)["userId"] : null;
        const userDetails = user ? JSON.parse(user) : "";
        const currentDate = new Date();
        const dateString = moment(currentDate).format('DD-MM-YYYY');

        callApi("POST", "get_verification_eod.php", {
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            bank: this.state.bank_name,
            branch: this.state.lawhands_branch,
            emp_name: this.state.assigned_to,
            file_id: this.state.id,
        })
            .then(res => res.data)
            .then(response => {
                let responseData = [];
                if (response.data) {
                    responseData = response.data.map((data, index) => {
                        return {
                            query: data.eod_data,
                            detail: {
                                ...data
                            }
                        }
                    });
                }

                // Destroy the DataTable before updating the state
                if ($.fn.dataTable.isDataTable('#eodReportTable')) {
                    const table = $('#eodReportTable').DataTable();
                    table.destroy();
                }

                // Update the state with the response data
                this.setState({ queries: responseData }, () => {
                    console.log("querylist------->>>", this.state.queries);
                    console.log("query---->>", responseData);

                    // Reinitialize the DataTable with the updated state
                    if ($.fn.dataTable.isDataTable('#eodReportTable')) {
                        this.table = $('#eodReportTable').DataTable().clear().destroy();
                    }

                    if (responseData.length > 0) {
                        this.table = $('#eodReportTable').DataTable({
                            paging: false,
                            dom: 'Bfrtip',
                            buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
                        });
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    };
    downloadData = () => {
        // this.tableDetails && this.tableDetails.button('.buttons-excel').trigger();
    }

    getActionText = (value) => {
        return value ? "Yes" : "No";
    }

    selectAndDeselect = (isChecked: boolean) => {
        if (this.tableDetails) {
            if (isChecked) {
                this.tableDetails.rows().select();
            } else {
                this.tableDetails.rows().deselect();
            }
        }
    }

    handleChange = (e, stateName?, type?) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDateChange = (date, dateStr, type) => {
        // console.log(date, dateStr);
        this.setState({ [type]: dateStr });
    };

    handleChangefileno = (e, stateName?, type?) => {
        if (e.target.name === "bank_name") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "toDate") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "fromDate") {
            this.setState({ fromdate: e.target.value })
        }
        if (e.target.name === "id") {
            this.setState({ id: e.target.value })
        }
        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChange1 = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        if (fieldName === "lawhands_branch") {
            const branch = fieldValue;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ [fieldName]: fieldValue });
            this.setState({ filteredUsers });
        }
        if (fieldName === "assigned_to") {
            const employee_id = fieldValue;
            this.setState({ [fieldName]: fieldValue });
        }
        if (fieldName === "bank_name") {
            const bank_name = fieldValue;
            console.log("267", bank_name);
            this.setState({ [fieldName]: fieldValue });
        }
    }

    render() {
        const lawhandsBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.lawhandsBranches
        ];
        const banksoptions = [
            { value: "", label: "All" },
            ...this.state.banks
        ];
        const usersoptions = [
            { value: "", label: "All" },
            ...this.state.users
        ];
        return (
            <div className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Verification EOD Report</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row" style={{ alignItems: 'end' }}>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>From Date</h6>
                                                            {/* <MaskedInput
                                                                className="form-control"
                                                                mask={[
                                                                    /[0-3]/,
                                                                    /\d/,
                                                                    ".",
                                                                    /[0-1]/,
                                                                    /\d/,
                                                                    ".",
                                                                    /[1-2]/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/
                                                                ]}
                                                                placeholder="dd.mm.yyyy"
                                                                value={this.state.fromdate}
                                                                onChange={e => this.handleChange(e)}
                                                                name="fromdate"
                                                            /> */}
                                                            <DatePicker format={'DD-MM-YYYY'}
                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromdate')} />
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>To Date</h6>
                                                            {/* <MaskedInput
                                                                className="form-control"
                                                                mask={[
                                                                    /[0-3]/,
                                                                    /\d/,
                                                                    ".",
                                                                    /[0-1]/,
                                                                    /\d/,
                                                                    ".",
                                                                    /[1-2]/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/
                                                                ]}
                                                                placeholder="dd.mm.yyyy"
                                                                value={this.state.todate}
                                                                onChange={e => this.handleChange(e)}
                                                                name="todate"
                                                            /> */}
                                                            <DatePicker format={'DD-MM-YYYY'}
                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'todate')} />
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>Bank Name</h6>
                                                            {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={banksoptions} name="bank_name"
                                                                    value={this.state.banks.find((o: any) => o.value === this.state.bank_name)}
                                                                    onChange={(e) => this.handleChange1(e, 'bank_name', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>LH Branch</h6>
                                                            {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={lawhandsBranchesoptions} name="lawhands_branch"
                                                                    value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
                                                                    onChange={(e) => this.handleChange1(e, 'lawhands_branch', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>Employee Name</h6>
                                                            {this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id

                                                            }) &&

                                                                <Select options={usersoptions} name="assigned_to"
                                                                    value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
                                                                    onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
                                                                    required
                                                                />

                                                            }
                                                            {/* {this.state.userVAlue ? (<><p style={{ color: 'red', fontSize: '10px', textAlign: 'center' }}>*Please Select The Employe Name</p></>) : null} */}
                                                        </div>
                                                        <div className="col-xl-1 col-md-1">
                                                            <h6>File.No</h6>
                                                            <input type="id" name="id" className="form-control" id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={this.state.id}
                                                                onChange={e => this.handleChangefileno(e)}
                                                            />
                                                        </div>
                                                        <div className="col-xl-1 col-md-1">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                // disabled={
                                                                //     !this.state.fromdate ||
                                                                //     !this.state.todate
                                                                // }
                                                                onClick={() => this.getQueriesWithoutDT()}
                                                            >
                                                                Search
                                                            </button>
                                                        </div>

                                                        {/* <div className="row">
                                                            <div className="col-lg-12">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                    // disabled={
                                                                    //     !this.state.fromdate ||
                                                                    //     !this.state.todate
                                                                    // }
                                                                    onClick={() => this.getQueriesWithoutDT()}
                                                                >
                                                                    Search
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="dt-responsive table-responsive" style={{ marginTop: '4%' }}>
                                                        <table
                                                            id="eodReportTable"
                                                            className="table table-striped table-bordered "
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th><input
                                                                        type="checkbox"
                                                                        className=""
                                                                        onChange={e =>
                                                                            this.selectAndDeselect(e.target.checked)
                                                                        }
                                                                    /></th>
                                                                    <th>S. No.</th>
                                                                    <th>File ID</th>
                                                                    <th>Branch</th>
                                                                    <th>Verification Officer</th>
                                                                    <th>Front Officer</th>
                                                                    <th>Processer Officer</th>
                                                                    <th>Highlight</th>
                                                                    <th>Data Entry</th>
                                                                    <th>Flow Chart</th>
                                                                    <th>Hint About the File</th>
                                                                    <th>Error Identified</th>
                                                                    <th>Number of LOD's</th>
                                                                    <th>Error Location</th>
                                                                    <th>Error Type</th>
                                                                    <th>Error Description</th>
                                                                    <th>Total Verification Time(Mins)</th>
                                                                    <th>Login Date</th>
                                                                    <th>Verificaiton Picked Date</th>
                                                                    <th>Completed Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.queries && this.state.queries.length
                                                                    ? this.state.queries.map(
                                                                        ({ query, detail }: any, index: number) => {
                                                                            return (
                                                                                <tr className="query-table-row cursor-pointer" key={index}>
                                                                                    <td></td>
                                                                                    <td className="text-align-center">{index + 1}</td>
                                                                                    <td className="click">
                                                                                        {query && query.fileId ? `${detail.branchcode ? detail.branchcode : ""}-${query.fileId}` : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {detail && detail.branch ? detail.branch : ""}
                                                                                    </td>
                                                                                    <td>{detail && detail.user_name ? detail.user_name : ""}</td>
                                                                                    <td>{detail && detail.front_officer_name ? detail.front_officer_name : ""}</td>
                                                                                    <td>{detail && detail.processer_officer_name ? detail.processer_officer_name : ""}</td>
                                                                                    <td className="click">
                                                                                        {query && query.highlight ? this.getActionText(query.highlight) : "No"}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.dataEntry ? this.getActionText(query.dataEntry) : "No"}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.flowChart ? this.getActionText(query.flowChart) : "No"}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.hintFile ? this.getActionText(query.hintFile) : "No"}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.errorIdentified ? this.getActionText(query.errorIdentified) : "No"}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.noOflod ? query.noOflod : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.errorSection ? query.errorSection : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.errorTypes ? query.errorTypes : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.errorDetails ? query.errorDetails : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query && query.totalTime ? query.totalTime : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {detail && detail.login_dt ? detail.login_dt : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {detail && detail.verfication_picked ? detail.verfication_picked : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {detail && detail.Completed_dt ? detail.Completed_dt : ""}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                    : ""}
                                                                {this.state.queries.length === 0 &&
                                                                    <tr style={{ textAlign: 'center' }}>
                                                                        <td colSpan={16}>No Details Found</td></tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                >  {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default EodReport;
