import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { callApi } from '../../utils/api';
import QueryDetails from './QueryDetails';
import { remove } from 'lodash';
import ReplyQuery from './ReplyQuery';
import moment from 'moment';

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const QueryTree = ({ fod_fk, history, setSelectedItem, getRepliedQueries, getnewquery, queryreason }, ref) => {
  const [treeList, setTreeList] = useState([]);
  const [isRaiseQueryShown, setIsRaiseQueryShown] = useState(false);
  const [newQueryList, setNewQueryList] = useState([]);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    getQueryTree();
  }, [])

  useImperativeHandle(ref, () => ({
    updateQuery() {
      setTreeList([]);
      setNewQueryList([]);
      getQueryTree();
    },
    updateRaiseQuery() {
      setIsRaiseQueryShown(true);
      addQueryList()
      forceUpdate();
    },
    updateNewQueryList() {
      setSelectedItem(newQueryList);
      setNewQueryList([]);
    }
  }), [newQueryList]);

  const payload: any = [];
  const getQueryTree = () => {
    callApi("POST", "query_tree.php", { fod_fk })
      .then(res => res.data)
      .then(response => {
        setTreeList(response)
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getReplies = (queryData) => {
    console.log("getReplies---", queryData);

    const queries: any = treeList;
    queries.forEach(query => {
      if (query.id === queryData.id) {
        query = queryData;
      }
    });
    setTreeList(queries);
    getRepliedQueries(queries);
  }

  const addQueryList = () => {
    // @ts-ignore
    setNewQueryList(prevState => {
      return [...prevState, {
        query: "",
        location: "",
        review: "",
        other_review: "",
        created_date: moment().format("DD-MM-YYYY HH:mm:ss")
      }]
    })
  }

  useEffect(() => {
    getnewquery(newQueryList);
    console.log("newQueryList-----", newQueryList); 
  }, [newQueryList]);

  // const removeQueryList = (index) => {
  //   let newQueryListTemp = newQueryList.filter((val, indexValue) => index !== indexValue);
  //   setNewQueryList(newQueryListTemp);
  //   getnewquery(newQueryList);
  //   console.log("newQueryList", newQueryList);
  // }

  const handleChange = (e, index) => {
    let newQueryListTemp = [...newQueryList]
    newQueryListTemp[index][e.target.name === "reason" ? "review" : e.target.name] = e.target.value as never;

    setNewQueryList([...newQueryListTemp]);
    getnewquery(newQueryList);
  }
  const removeQueryList = (index) => {
    const querydetail = [...newQueryList];
    querydetail.splice(index, 1);
    setNewQueryList([...querydetail]);
    getnewquery(querydetail);
  };
  const handleDetailsChange = (e, key, index) => {
    let newQueryListTemp = [...newQueryList];
    if (newQueryListTemp[index]) {
      newQueryListTemp[index][key] = e as never;
    }
    setNewQueryList([...newQueryListTemp]);
    getnewquery(newQueryList);
  };

  const renderExpansionPanel = (list) => {
    // console.log("queryData list", list);
    return (<>
      {list && list.length ? list.map((queryData, index) => {
        // console.log("queryData", queryData);

        return <QueryDetails key={queryData.id} queryData={queryData} fodFk={fod_fk} history={history} queryIndex={index + 1}
          getQueryTree={getQueryTree}
          setSelectedItem={setSelectedItem}
          getReplies={getReplies}
          queryreason={queryreason}
        />
      }) : null}
      {isRaiseQueryShown && newQueryList &&
        <>
          <div className='raise-query-style' style={{ marginTop: '4%', flex: 1 }}>
            <ReplyQuery
              key={"1"}
              reply={""}
              addReply={() => addQueryList()}
              removeReply={(queryIndex) => removeQueryList(queryIndex)}
              handleChange={(e, queryIndex) => handleChange(e, queryIndex)}
              queryreason={queryreason}
              handleDetailsChange={(e, key, queryIndex) => handleDetailsChange(e, key, queryIndex)}
              addnewQueryList={newQueryList}
            />
          </div>
        </>
      }
    </>)
  }
  return (
    <div>
      {renderExpansionPanel(treeList)}
    </div>
  )
}
export default forwardRef(QueryTree);
