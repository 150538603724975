import * as React from "react";
import { callApi } from "../../utils/api";
import Select from 'react-select';

import { Bank } from "../bank/ManageBank";
export interface Officer {
  name: string;
  email: string;
  mobile: string;
  designation: string;

}
interface AllProps {
  history: any;
  match: any;
}
class AddBankBranch extends React.Component<AllProps> {
  state = {
    branchdetail: {
      id: 0,
      name: "",
      bank_idfk: "",
      product_id: "",
      mob_no: "",
      branch: "",
      address: "",
      complete_fees: "",
      vetting_fees: "",
      login_fees: "",
      officer_list: []
    },
    banks: [],
    products: [],
    filteredProduct: [],
    lawhandsBranches: []
  };
  componentDidMount() {
    this.listBank();
    this.listBankproduct();
    this.getLawHandsBranches();
    if (Number(this.props.match.params.id)) {
      this.getSingleBranch();
    } else {
      this.addItem();
    }
  }
  listBank = () => {
    callApi("GET", "bank_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ banks: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  listBankproduct = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "bank_product_get.php")
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ products: files, filteredProduct: files });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getLawHandsBranches() {
    callApi("GET", "get_branch.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ lawhandsBranches: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  }
  getSingleBranch() {
    callApi("POST", "bank_branch_get_one.php", {
      id: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.length &&
          response.data[0].arr &&
          response.data[0].arr[0]
        ) {
          const branchdetail = Object.assign(response.data[0].arr[0]);
          branchdetail.officer_list = response.data[0].rl;
          this.setState({ branchdetail });
          console.log("branchdetail---", branchdetail);
          if (branchdetail.bank_idfk) {
            this.filterProduct(branchdetail.bank_idfk);
          }

        }
      });
  }
  handleChange1 = (e: any, propName?, fieldType?) => {
    const branchdetail: any = this.state.branchdetail;
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    branchdetail[fieldName] = fieldValue;
    this.setState({ branchdetail });
    if (fieldName === "bank_idfk") {
      this.filterProduct(fieldValue);
    }
  };
  filterProduct = bankid => {
    if (bankid) {
      const filteredProduct = this.state.products.filter(
        (branch: any) => branch.bank_id === bankid
      );
      const state = this.state;
      state.filteredProduct = filteredProduct;
      this.setState({ ...state });
    } else {
      this.setState({ product_id: "" })
    }
  };
  handleChange = (e: any) => {
    const branchdetail: any = this.state.branchdetail;
    branchdetail[e.target.name] = e.target.value;
    this.setState({ branchdetail });
  };
  addItem = (): void => {
    const officer_list: Officer[] = this.state.branchdetail.officer_list
      ? this.state.branchdetail.officer_list
      : [];
    officer_list.push({ name: "", mobile: "", email: "", designation: "" });
    this.setState({
      branchdetail: { ...this.state.branchdetail, officer_list }
    });
  };
  removeItem = (index: number): void => {
    const officer_list: Officer[] = this.state.branchdetail.officer_list;
    officer_list.splice(index, 1);
    this.setState({
      branchdetail: { ...this.state.branchdetail, officer_list }
    });
  };
  handleOfficerChange = (e: any, index: number) => {
    const officer_list: Officer[] = this.state.branchdetail.officer_list;
    officer_list.forEach((itm: any, i) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({
      branchdetail: { ...this.state.branchdetail, officer_list }
    });
  };
  saveBranch = () => {
    this.props.match.params.id = Number(this.props.match.params.id);
    if (this.props.match.params.id) {
      callApi("POST", "bank_branch_update.php", this.state.branchdetail)
        .then(res => res.data)
        .then(response => {
          console.log("111", response);
          this.props.history.push("/bank-branch");
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      callApi("POST", "bank_branch_create.php", this.state.branchdetail)
        .then(res => res.data)
        .then(response => {
          this.props.history.push("/bank-branch");
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  render() {
    const filteredProduct = [
      { value: "", label: "" },
      ...this.state.filteredProduct
    ];
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Bank Branch Form</h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Bank Name</label>
                                {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                                  opt.label = opt.name; opt.value = opt.id
                                }) &&
                                  <Select options={this.state.banks} name="bank_idfk"
                                    value={this.state.banks.find((o: any) => o.value === this.state.branchdetail.bank_idfk)}
                                    onChange={(e) => this.handleChange1(e, 'bank_idfk', 'singleselect')}
                                  />
                                }
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label>Product</label>
                              {this.state.products && this.state.products.map((opt: any) => {
                                opt.label = opt.product_name; opt.value = opt.id
                              }) &&
                                <Select options={filteredProduct} name="product_id"
                                  value={this.state.filteredProduct.find((o: any) => o.value === this.state.branchdetail.product_id)}
                                  onChange={(e) => this.handleChange1(e, 'product_id', 'singleselect')}
                                />
                              }
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Branch Name</label>
                                {/* {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                                  opt.label = opt.name; opt.value = opt.name
                                }) &&
                                  <Select options={this.state.lawhandsBranches} name="name"
                                    value={this.state.lawhandsBranches.find((o: any) => o.name === this.state.branchdetail.name)}
                                    onChange={(e) => this.handleChange1(e, 'name', 'singleselect')}
                                  />
                                } */}
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.branchdetail.name}
                                  placeholder="Branch Name"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Address</label>
                                <textarea
                                  name="address"
                                  onChange={e => this.handleChange(e)}
                                  className="form-control"
                                  value={this.state.branchdetail.address}
                                  placeholder="Address"
                                />
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Complete Fees</label>
                                <input
                                  type="number"
                                  name="complete_fees"
                                  className="form-control"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.branchdetail.complete_fees}
                                  placeholder="Complete Fees"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Vetting Fees</label>
                                <input
                                  type="number"
                                  name="vetting_fees"
                                  className="form-control"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.branchdetail.vetting_fees}
                                  placeholder="Vetting Fees"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Login Fees</label>
                                <input
                                  type="number"
                                  name="login_fees"
                                  className="form-control"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.branchdetail.login_fees}
                                  placeholder="Login Fees"
                                />
                              </div>
                            </div>
                            <div className="text-right col-md-11">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => this.addItem()}
                              >
                                Add +
                              </button>
                            </div>
                            <br />
                            {this.state.branchdetail.officer_list &&
                              this.state.branchdetail.officer_list.map(
                                (officer: Officer, index: number) => {
                                  return (
                                    <div className="row" key={index}>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label>Name</label>
                                          <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Name"
                                            value={officer.name}
                                            onChange={e =>
                                              this.handleOfficerChange(e, index)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label>Designation</label>
                                          <input
                                            type="text"
                                            name="designation"
                                            value={officer.designation}
                                            onChange={e =>
                                              this.handleOfficerChange(e, index)
                                            }
                                            className="form-control"
                                            placeholder="Designation"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label>Phone</label>
                                          <input
                                            type="number"
                                            name="mobile"
                                            value={officer.mobile}
                                            onChange={e =>
                                              this.handleOfficerChange(e, index)
                                            }
                                            className="form-control"
                                            placeholder="Phone"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label>Email</label>
                                          <div className="row">
                                            <div className="col-md-10">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                name="email"
                                                value={officer.email}
                                                onChange={e =>
                                                  this.handleOfficerChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-2">
                                              <button
                                                type="button"
                                                className="btn btn-icon btn-rounded btn-danger"
                                                onClick={() =>
                                                  this.removeItem(index)
                                                }
                                              >
                                                <i className="feather icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={this.saveBranch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddBankBranch;
