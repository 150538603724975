import React, { Component } from "react";
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
declare var $: any;
interface Scrutiny {
  obtined: string;
  index: number;
  status: string;
  current_survey: string;
  date: string;
  deed: string;
  doc_no: string;
  extent: string;
  fod_fk: string;
  mother_doc: string;
  nature_doc: string;
  old_surevey: string;
  particulars: string;
  scrutiny_id: string;
  verify: string;
  color: string;
  type: string;
  ec_id: string;
}
interface AllProps {
  id: any;
  history: any;
}
export default class ScrutinizedDocument extends Component<AllProps> {
  state = {
    scrutinies: []
  };
  componentDidMount() {
    this.getScrutinizedDocs();
    $(document).ready(function() {
      $("tbody").sortable({
        distance: 5,
        delay: 100,
        opacity: 0.6,
        cursor: "move",
        update: function(e, ui) {}
      });
    });
  }
  reOrderDocs = () => {
    const table: any = document.getElementById("myTable");
    const reorderInput: any = [];
    this.state.scrutinies.forEach((itm, index) => {
      const cell = table.rows[index + 1]?.cells[0]; // Check if the cell is valid
      if (cell) {
        const scrutiny_id: any = cell.innerHTML
          .split("~")[1]
          .replace("</span>", "");
        const type: any = cell.innerHTML
          .split("~")[2]
          .replace("</span>", "");
        const position = reorderInput.length + 1;
        reorderInput.push({
          scrutiny_id,
          position,
          type
        });
      }
    });
    const scr = reorderInput.filter(itm => itm.type === "scrutiny");
    const ec = reorderInput.filter(itm => itm.type === "ec-form");
    if (scr && scr.length) {
      scr.forEach((doc, index) => {
        doc.position = index + 1;
      });
    }
    if (ec && ec.length) {
      ec.forEach((doc, index) => {
        doc.position = index + 1;
      });
    }
    const scrDocs = scr.concat(ec);
    this.setState({ scrutinies: [] });
    callApi("POST", "reorder.php", {
      file_idfk: this.props.id,
      reorder: scrDocs
    })
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('overviewDocument');
        this.getScrutinizedDocs();
      })
      .catch(err => {
        console.log(err);
      });
  };
  getScrutinizedDocs = () => {
    callApi("POST", "doc_scrutinize_list.php", { id: this.props.id })
      .then(res => res.data)
      .then(response => {
        const scr = response.data.filter(itm => itm.type === "scrutiny");
        const ec = response.data.filter(itm => itm.type === "ec-form");
        if (scr && scr.length) {
          scr.forEach((doc, index) => {
            doc.index = index + 1;
          });
        }
        if (ec && ec.length) {
          ec.forEach((doc, index) => {
            doc.index = index + 1;
          });
        }
        const scrutinies = scr.concat(ec);
        this.setState({ scrutinies });
      })
      .catch(err => {
        console.log(err);
      });
  };
  modifyScrutinyStatus = modal => {
    callApi("POST", "scrutiny_status.php", {
      id: modal.type === "scrutiny" ? modal.scrutiny_id : modal.ec_id,
      type: modal.type,
      status: modal.status === "Approved" ? 0 : 1
    })
      .then(res => res.data)
      .then(response => {
        this.getScrutinizedDocs();
      })
      .catch(err => {
        console.log(err);
      });
  };
  modifyScrutinyVerification = modal => {
    callApi("POST", "scrutiny_verify.php", {
      id: modal.type === "scrutiny" ? modal.scrutiny_id : modal.ec_id,
      type: modal.type,
      verify: modal.verify === "UnVerified" ? 1 : 0
    })
      .then(res => res.data)
      .then(response => {
        this.getScrutinizedDocs();
      })
      .catch(err => {
        console.log(err);
      });
  };
  goToFile = (file: Scrutiny) => {
    this.props.history.push(
      "/" + file.type + "/" + this.props.id + "/" + file.fod_fk + '/3'
    );
  };
  render() {
    return (
      <div id="step-3">
        <div className="container">
          <button
            type="button"
            id="test"
            className="btn btn-primary"
            onClick={this.reOrderDocs}
          >
            SAVE ORDER
          </button>
          <div className="row">
            <div className="col-lg-12 table-responsive">
              <table className="table table-hover" id="myTable">
                <thead>
                  <tr>
                    {/* <th>S.No</th> */}
                    <th scope="col" className="text-center">
                      Approval Status
                    </th>
                    <th scope="col">Date</th>
                    <th scope="col">Doc.No</th>
                    <th scope="col">Particulars of deeds</th>
                    <th scope="col">survey no</th>
                    <th scope="col">Extent</th>
                    <th scope="col">Nature of.Doc</th>
                    <th scope="col">Original Verify</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.scrutinies && this.state.scrutinies.length
                    ? this.state.scrutinies.map(
                        (scr: Scrutiny, index: number) => {
                          return (
                            <tr
                              className="pointer"
                              key={index}
                              style={{ background: scr.color }}
                            >
                              {/* <td>{index + 1}</td> */}
                              <td
                                onClick={() => this.goToFile(scr)}
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle"
                                }}
                              >
                                <i
                                  className={
                                    scr.status === "Pending"
                                      ? "fa fa-times-circle"
                                      : "fa fa-check-circle"
                                  }
                                  style={
                                    scr.status === "Pending"
                                      ? { fontSize: "32px", color: "#dc3545" }
                                      : { fontSize: "32px", color: "#28a745" }
                                  }
                                ></i>
                                <span style={{ visibility: "hidden" }}>
                                  {"~" +
                                    (scr.scrutiny_id
                                      ? scr.scrutiny_id
                                      : scr.ec_id) +
                                    "~" +
                                    scr.type}
                                </span>
                              </td>
                              <td
                                className="indexInput"
                                onClick={() => this.goToFile(scr)}
                              >
                                {scr.date}
                              </td>
                              <td onClick={() => this.goToFile(scr)}>
                                <a>{scr.doc_no}</a>
                              </td>
                              <td onClick={() => this.goToFile(scr)}>
                                {scr.particulars}
                              </td>
                              <td onClick={() => this.goToFile(scr)}>
                                {scr.old_surevey}
                              </td>
                              <td onClick={() => this.goToFile(scr)}>
                                {scr.extent}
                              </td>
                              <td onClick={() => this.goToFile(scr)}>
                                {scr.obtined}
                              </td>
                              <td>
                                {/* <a>
                                  <span
                                    className={
                                      scr.status === "Pending"
                                        ? "badge badge-danger f-14"
                                        : "badge badge-success f-14"
                                    }
                                  >
                                    {scr.status}
                                  </span>
                                </a>
                                <br /> */}
                                <a>
                                  <span
                                    className={
                                      scr.verify === "UnVerified"
                                        ? "badge badge-danger f-14"
                                        : "badge badge-success f-14"
                                    }
                                  >
                                    {scr.verify}
                                  </span>
                                </a>
                              </td>
                              <td>
                                <div className="btn-group mb-2 mr-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Action
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span className="sr-only">approve</span>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    x-placement="top-start"
                                    style={{
                                      position: "absolute",
                                      top: "0px",
                                      left: "0px",
                                      transform: "translate3d(88px, -2px, 0px)"
                                    }}
                                  >
                                    <Link
                                      to={"/query/add/0/" + this.props.id}
                                      className="dropdown-item"
                                    >
                                      Raise Query
                                    </Link>
                                    {/* <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.modifyScrutinyStatus(scr)
                                      }
                                    >
                                      {scr.status === "Approved"
                                        ? "Pending"
                                        : "Approve"}
                                    </a> */}
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.modifyScrutinyVerification(scr)
                                      }
                                    >
                                      {scr.verify === "UnVerified"
                                        ? "Original Verify"
                                        : "Original Unverify"}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
