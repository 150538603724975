import React, { useEffect, useState } from "react";

const GraphModal = ({
  selectedNode,
  updateChange = (node, name, color) => {},
  createNode = (obj) => {},
  hideGraphModal = () => {},
}) => {
  const [name, setName] = useState<null | string>("");
  const [color, setColor] = useState<null | string>("");
  const documentList = [
    {
      id: "Origin Document",
      name: "Origin Document (violet)",
      color: "violet",
    },
    {
      id: "Mother Document",
      name: "Mother Document (orange)",
      color: "orange",
    },
    {
      id: "Title Document",
      name: "Title Document (green)",
      color: "green",
    },
    {
      id: "Doubt Location",
      name: "Doubt Location (yellow)",
      color: "yellow",
    },
    {
      id: "Doubt/Question",
      name: "Doubt/Question (red)",
      color: "red",
    },
  ];
  const [selectedType, setSelectedType] = useState("");
  useEffect(() => {
    if (selectedNode) {
      setName(selectedNode.model.attr(".label/text").toString().split("?")[0]);
      setColor(selectedNode.model.attr("rect/fill"));
      setSelectedType(selectedNode.model.attr("rect/fill"));
    } else {
      setName("");
      setColor("");
    }
  }, [selectedNode]);
  const saveChange = () => {
    if (name && color) {
      if (selectedNode) {
        updateChange(selectedNode, name, color);
      } else createNode({ name: name, color });
    }
  };
  return (
    <div
      className="modal fade"
      id="graphModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="graphModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Graph Node
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hideGraphModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group" style={{ display: "flex" }}>
              <label className="label col-md-4">Name</label>
              <input
                className="form-control"
                name="surveyNo"
                type="text"
                value={name as string}
                placeholder="Circle Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <label className="label col-md-4">Document Type</label>
              <select
                className="custom-select"
                name="documentType"
                onChange={(obj) => {
                  if (obj.target.value) {
                    setColor(obj.target.value);
                    setSelectedType(obj.target.value);
                  }
                }}
                value={selectedType}
              >
                <option value="">Select Document Type</option>
                {documentList.map((type: any, index: number) => {
                  return (
                    <option key={index} value={type.color}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
              {/* <input
                className="form-control"
                name="surveyNo"
                type="text"
                value={color as string}
                placeholder="Color Code / Name"
                onChange={(e) => setColor(e.target.value)}
              /> */}
            </div>
          </div>
          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button> */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveChange}
              disabled={
                (selectedType !== "#012060" && !selectedType) ||
                !selectedType ||
                !name
              }
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphModal;
