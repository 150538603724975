import React, { Component } from "react";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ReactDOM from "react-dom";
import { Resizable } from "react-resizable";
import { Prompt } from "react-router";
import ReactFileReader from "react-file-reader";
// import QueryTree from "./QueryTree";
import { cloneDeep } from 'lodash';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { callApi } from "../../utils/api";
import FlowMap from "./FlowMap";
import QueryTree from "../file/QueryTree";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CustomAudioRecorder from "../CustomAudioRecorder";
import useRecorder from "./useRecorder";
import { MouseEventHandler } from 'react';
import CountdownTimer from "../file/CountdownTimer";
declare var $: any;
interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}

export default class SaleDeed extends Component<AllProps> {
  query: any = React.createRef();
  state = {
    isSorting: false,
    deedData: [],
    deedResponseData: [],
    flowGraphData: null,
    documentWidth: 50,
    documentHeight: 850,
    formWidth: 50,
    formHeight: 600,
    isSaved: true,
    fileName: "",
    userName: "",
    bankName: "",
    cuDate: "",
    queryBase: [],
    repliedQueries: [],
    queryPayload: {
      requery: [],
      resend: []
    },
    multiSelectedQuery: [],
    user_idfk: "",
    reasons: [],
 };
  countdownTimerRef: CountdownTimer | null = null;
  onDocumentResize = (event, { element, size, handle }) => {
    if (size.width <= 60) {
      this.setState({
        documentWidth: size.width,
        documentHeight: size.height,
        formWidth: 90 - size.width,
      });
    }
  };
  onFormResize = (event, { element, size, handle }) => {
    this.setState({
      formWidth: size.width,
      formHeight: size.height,
      documentWidth: 90 - size.width,
    });
  };
  componentDidMount() {
    if (!this.state.isSaved) {
      window.onbeforeunload = () => true;
    } else {
      // window.onbeforeunload = undefined
    }
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const user_name = user ? JSON.parse(user)["firstName"] : "";
    const userDetails = user ? JSON.parse(user) : "";
    const get_date: any = localStorage.getItem("Intimedate");
    this.setState({
      cuDate: JSON.parse(get_date),
      fileName: localStorage.getItem("fileNamCode"),
      userName: user_name,
      bankName: localStorage.getItem("bankName"),
    });
    this.getFlowGraphData();
    $(document).ready(function () {
      $("#deedTable").sortable({
        distance: 5,
        delay: 100,
        opacity: 0.6,
        cursor: "move",
        update: function (e, ui) { },
      });
    });
    this.listreason();
  }


  listreason = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "query_reason_get.php")
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ reasons: files });
        console.log("query reason list:", this.state.reasons);
      })
      .catch(err => {
        console.log(err);
      });
  };

  detectChanges = () => {
    this.setState({ isSaved: false });
  };
  BacktoFile = () => {
    // this.props.history.push("/file-dashboard/" + this.props.match.params.id);
    this.props.history.goBack()
  }

  getFlowGraphData = () => {
    let promise = new Promise((resolve, reject) => {
      callApi("POST", "get_flowchart.php", {
        fileId: this.props.match.params.id,
      })
        .then((res) => res.data)
        .then((response) => {
          if (response.data && response.data) {
            this.setState({ flowGraphData: response.data });
          }
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          resolve(true);
        });
    });

    promise.then(() => {
      this.getDeedData();
    });
  };

  saveFlowDeed = async (graphData) => {
    const user: any = sessionStorage.getItem("user");
    const user_idfk = user ? JSON.parse(user)["userId"] : "";
    this.setState({ user_idfk: user_idfk })
    callApi("POST", `flowchart_add.php`, {
      fileId: this.props.match.params.id,
      loginId: user_idfk,
      ...graphData,
    })
      .then(() => {
        ToastsStore.success("Flow saved successfully");
        this.setState({ isSaved: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDeedData = () => {
    callApi("POST", "doc_scrutinize_list.php", {
      id: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        if (response.data) {
          const scr = response.data.filter((itm) => itm.type === "scrutiny");
          const ec = response.data.filter((itm) => itm.type === "ec-form");
          if (scr && scr.length) {
            scr.forEach((doc, index) => {
              doc.index = index + 1;
            });
          }
          if (ec && ec.length) {
            ec.forEach((doc, index) => {
              doc.index = index + 1;
            });
          }
          const scrutinies = scr.concat(ec);
          this.setState({ deedData: scrutinies });
        } else {
          ToastsStore.success("No Data Found");
          setTimeout(() => {
            this.props.history.goBack();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  reOrderDocs = () => {
    this.setState({ isSaved: false });
    const table: any = document.getElementById("myTable");
    const reorderInput: any = [];
    this.state.deedData.forEach((itm, index) => {
      const scrutiny_id: any = table.rows[index + 1].cells[0].innerHTML
        .split("~")[1]
        .replace("</span>", "");
      const type: any = table.rows[index + 1].cells[0].innerHTML
        .split("~")[2]
        .replace("</span>", "");
      const position = reorderInput.length + 1;
      reorderInput.push({
        scrutiny_id,
        position,
        type,
      });
    });
    const scr = reorderInput.filter((itm) => itm.type === "scrutiny");
    const ec = reorderInput.filter((itm) => itm.type === "ec-form");
    if (scr && scr.length) {
      scr.forEach((doc, index) => {
        doc.position = index + 1;
      });
    }
    if (ec && ec.length) {
      ec.forEach((doc, index) => {
        doc.position = index + 1;
      });
    }
    const scrDocs = scr.concat(ec);
    this.setState({ deedData: [] });
    this.setState({ isSaved: true });
    // callApi("POST", "reorder.php", {
    //     file_idfk: this.props.id,
    //     reorder: scrDocs
    // })
    //     .then(res => res.data)
    //     .then(response => {
    //         // this.getScrutinizedDocs();
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
  };

  repliedQueries = (queries: any) => {
    this.setState({
      repliedQueries: queries,
      queryPayload: {
        requery: [],
        resend: []
      }
    }, () => this.formatData(queries));
  }
  getNewQuery = (newquerys: any) => {
    console.log("newQueryList  2", newquerys);
  }

  formatData = (queries) => {
    const queryData: any = this.state.queryPayload;
    queries.forEach((query) => {
      if (query.hasOwnProperty('replies') && query.replies && query.replies.length > 0) {
        queryData.requery.push(query);
        this.setState({ queryPayload: queryData }, () => {
          if (query.children.length > 0) {
            this.formatData(query.children);
          }
        });
      } else if (query.children.length > 0) {
        this.formatData(query.children);
      }
    });
  }

  handleFiles = (files: any) => {
    let baseFile: any = this.state.queryBase || [];
    const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
    if (Number(fileSize) > 26214400) {
      ToastsStore.error("File(s) size shouldn't exceed 25 MB");
    } else {
      files.base64.map((file: any, index: number) => {
        baseFile.push({ file, fileType: files.fileList[index].type, fileName: files.fileList[index].name });
      });
      this.setState({ queryBase: [...baseFile] });
    }
  };

  previewDoc = (modal) => {
    this.setState({ previewDoc: modal.file });
  };

  setSelectedItem = (item: any) => {
    let itemList = Array.isArray(item) ? [...item] : [item];
    let { multiSelectedQuery } = this.state;
    let tempArray: any[] = multiSelectedQuery;
    for (let i = 0; i < itemList.length; i++) {
      if (tempArray.find((val: any) => val.query_id === itemList[i].query_id)) {
        tempArray.splice(multiSelectedQuery.findIndex((val: any) => val.query_id === itemList[i].query_id), 1);
      } else tempArray.push(itemList[i]);
    }
    this.setState({ multiSelectedQuery: tempArray });
  }

  deleteDoc = (modal, index) => {
    const baseFile: never[] = cloneDeep(this.state.queryBase);
    baseFile.splice(index, 1);
    this.setState({ queryBase: [...baseFile] });
  };

  updateNewQueryList = () => {
    this.getQueryQuestion();
    // this.getUsers();
  }

  getQueryQuestion = () => {
    callApi("POST", "query_question_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({
          queryQues: response.data,
          oldQueryQues: JSON.parse(JSON.stringify(response.data))
        });
      });
  }

  renderTableData = () => {
    const reasontemplatesoption = [
      { value: "other", label: "Other" },
      ...this.state.reasons
    ];
    return (
      <div className="container">
        <Accordion className="mainAccordion">
          <Tabs
            defaultActiveKey="FlowChartKey"
            style={{ padding: 0 }}
            onSelect={(name) => {
              this.setState(
                { activeMainTab: name }
                // () => {
                //   if (name === "ManualView" && !isManualClicked) {
                //     this.setInitialManualPaperView();
                //   }
                // }
              );
            }}
          >
            <Tab eventKey="FlowChartKey" title="Table">
              <div className="row">
                <div
                  className="col-lg-12 table-responsive"
                  ref="tableMain"
                  style={{ flexWrap: "wrap", height: "600px", overflowY: "scroll" }}
                >
                  <table className="table table-hover" id="myTable">
                    <thead>
                      <tr>
                        <th scope="col">S. NO</th>
                        {/* <th scope="col" className="text-center">
                          Approval Status
                        </th> */}
                        <th scope="col">Date</th>
                        <th scope="col">Doc.No</th>
                        <th scope="col">Particulars of deeds</th>
                        <th scope="col">survey no</th>
                        <th scope="col">Extent</th>
                        <th scope="col">Nature of.Doc</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody id="deedTable">
                      {this.state.deedData && this.state.deedData.length
                        ? this.state.deedData.map((scr: any, index: number) => {
                          return (
                            <tr
                              className="pointer"
                              key={index}
                              style={{ background: scr.color }}
                            >
                              <td>{index + 1}</td>
                              {/* <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <i
                                  className={
                                    scr.status === "Pending"
                                      ? "fa fa-times-circle"
                                      : "fa fa-check-circle"
                                  }
                                  style={
                                    scr.status === "Pending"
                                      ? { fontSize: "32px", color: "#dc3545" }
                                      : { fontSize: "32px", color: "#28a745" }
                                  }
                                ></i>
                                <span style={{ visibility: "hidden" }}>
                                  {"~" +
                                    (scr.scrutiny_id
                                      ? scr.scrutiny_id
                                      : scr.ec_id) +
                                    "~" +
                                    scr.type}
                                </span>
                              </td> */}
                              <td
                                className="indexInput"
                              // onClick={() => this.goToFile(scr)}
                              >
                                {scr.date}
                              </td>
                              <td
                              // onClick={() => this.goToFile(scr)}
                              >
                                <a>{scr.doc_no}</a>
                              </td>
                              <td
                              // onClick={() => this.goToFile(scr)}
                              >
                                {scr.particulars}
                              </td>
                              <td
                              // onClick={() => this.goToFile(scr)}
                              >
                                {scr.old_surevey}
                              </td>
                              <td
                              // onClick={() => this.goToFile(scr)}
                              >
                                {scr.extent}
                              </td>
                              <td
                              // onClick={() => this.goToFile(scr)}
                              >
                                {scr.nature_doc}
                              </td>
                              <td>
                                <a>
                                  <span
                                    className={
                                      scr.verify === "UnVerified"
                                        ? "badge badge-danger f-14"
                                        : "badge badge-success f-14"
                                    }
                                  >
                                    {scr.verify}
                                  </span>
                                </a>
                              </td>
                              <td>
                                <div className="btn-group mb-2 mr-2">
                                  <button type="button" className="btn btn-primary">
                                    Action
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span className="sr-only">approve</span>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    x-placement="top-start"
                                    style={{
                                      position: "absolute",
                                      top: "0px",
                                      left: "0px",
                                      transform: "translate3d(88px, -2px, 0px)",
                                    }}
                                  >
                                    {/* <Link
                                                                to={"/query/add/0/" + this.props.id}
                                                                className="dropdown-item"
                                                            >
                                                                Raise Query
                                                            </Link> */}
                                    <a
                                      className="dropdown-item"
                                    // onClick={() =>
                                    //     this.modifyScrutinyVerification(scr)
                                    // }
                                    >
                                      {scr.verify === "UnVerified"
                                        ? "Verify"
                                        : "Unverify"}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="RecordingsKey" title="Recordings">
              <div>
                <Audiorecord />
                {/* <Example/> */}
              </div>
              {/* <div>
                <CustomAudioRecorder
                  fileId={this.props.match.params.id}
                  user_idfk={this.state.user_idfk}
                />
              </div> */}
            </Tab>
          </Tabs>
        </Accordion>
        {/* <button
          type="button"
          id="test"
          className="btn btn-primary"
        // onClick={this.reOrderDocs}
        >
          SAVE ORDER
        </button> */}

        {this.state.queryBase ?
          (<div className="row">
            <div className="col-sm-12">
              <div className="card query-card">
                <div className="card-header d-flex justify-content-between">
                  <h5>Query Manage</h5>
                  <div className="d-flex">
                    <button type="button" className="btn btn-info btn-sm" onClick={() => {
                      this.query.current.updateRaiseQuery()
                    }}>
                      Raise Query
                    </button>
                    <ReactFileReader
                      fileTypes={[".pdf"]}
                      base64={true}
                      multipleFiles={true}
                      handleFiles={e => this.handleFiles(e)}
                    >
                      <button type="button" className="btn btn-info btn-sm" >
                        Upload Document
                      </button>
                    </ReactFileReader>
                    <button type="button" className="btn btn-info btn-sm" onClick={() => this.updateNewQueryList()} data-toggle="modal" data-target="#send-mail">
                      Submit All
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <div className="query-document">
                    {this.state.queryBase && this.state.queryBase.length
                      ? this.state.queryBase.map(
                        (itm: any, index: number) => {
                          return (
                            <div key={index}>
                              <div className="card card-border-c-green">
                                <span
                                  onClick={() =>
                                    this.deleteDoc(itm, index)
                                  }
                                >
                                  <i className="feather icon-x cursor-pointer" />
                                </span>
                                <div className="card-body">
                                  <h5 className="card-title">
                                    <span className="mb-1 text-c-green">
                                      <i className="feather icon-file-text" />
                                    </span>
                                    <span style={{ fontSize: "14px" }}>
                                      {itm.fileName || ""}
                                    </span>
                                    <span style={{ float: 'right' }}
                                      className="cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#preview-document"
                                      onClick={() =>
                                        this.previewDoc(itm)
                                      }
                                    >
                                      <i className="fa fa-eye" />
                                    </span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                      : ""}
                  </div>
                  <QueryTree fod_fk={this.props.match.params.id}
                    history={this.props.history} ref={this.query}
                    setSelectedItem={item => this.setSelectedItem(item)}
                    getRepliedQueries={this.repliedQueries}
                    getnewquery={this.getNewQuery}
                    queryreason={reasontemplatesoption}
                  />
                </div>
              </div>
            </div>
          </div>
          ) : ""}
      </div>
    );
  };

  downloadFlowGraph = async (refElement, questionList = []) => {
    let pdf = new jsPDF("p", "mm", "a4");
    let deedData: any = this.state.deedData;

    if (deedData.length > 0) {
      const imageData = domtoimage.toPng(ReactDOM.findDOMNode(refElement));
      imageData.then((data) => {
        var width = 100;
        var height = 100;
        pdf.text(`Law No : ${this.props.match.params.id}- Flow Chart`, 10, 15);

        autoTable(pdf, {
          head: [{ color: "Color", detail: "Detail" }],
          body: [
            { color: "COLOR DETAILS", detail: "" },
            {
              key: "#4846b2",
              color: "Violet Color",
              detail: "Origin Document",
            },
            {
              key: "#bb7c37",
              color: "Orange",
              detail: "Mother Document",
            },
            {
              key: "#00924b",
              color: "Green",
              detail: "Title Document",
            },
            {
              key: "Yellow",
              color: "Yellow",
              detail: "Doubt Location",
            },
            {
              key: "#e73c16",
              color: "Red",
              detail: "Doubt/Question",
            },
          ],
          startY: 15,
          showHead: false,
          // columnStyles: {
          //     color: { fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold' },
          // },
          didParseCell: (cellData) => {
            cellData.cell.styles.fillColor = (cellData.row.raw as any).key;
            cellData.cell.styles.textColor = "black";
            cellData.cell.styles.fontStyle = "bold";
            if (cellData.row.index === 0) {
              cellData.cell.styles.fillColor = "#2980ba";
              cellData.cell.styles.textColor = "white";
              cellData.cell.styles.fontStyle = "bold";
            }
          },
          margin: { left: 130 },
        });

        pdf.text(
          "",
          35,
          70,
          {
            align: "center",
          },
          {}
        );
        pdf.addImage(data, 40, 70, 150, 200);

        let fileTableHeader = [{ id: "SI.No", name: "Name", value: "Value" }];
        let fileTableValues = [
          { id: "", name: "FILE DETAILS", value: "" },
          { id: "1", name: "Date", value: `${this.state.cuDate}` },
          { id: "2", name: "Law No", value: `${this.props.match.params.id}` },
          { id: "3", name: "File Name", value: `${this.state.fileName}` },
        ];

        autoTable(pdf, {
          head: fileTableHeader,
          body: fileTableValues,
          showHead: false,
          // columnStyles: {
          //     name: { fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold' },
          // },
          tableWidth: 80,
          didParseCell: (cellData) => {
            if (cellData.row.index === 0) {
              cellData.cell.styles.fillColor = "#2980ba";
              cellData.cell.styles.textColor = "white";
              cellData.cell.styles.fontStyle = "bold";
            }
          },
          startY: (pdf as any).lastAutoTable.finalY * 5.7,
          margin: { right: 10 },
          styles: {},
        });

        autoTable(pdf, {
          head: fileTableHeader,
          body: [
            { id: "", name: "FILE DETAILS", value: "" },
            { id: "1", name: "Bank Name", value: `${this.state.bankName}` },
            {
              id: "2",
              name: "Flow Chart prepared by",
              value: `${this.state.userName}`,
            },
            { id: "3", name: "No of Times Flow Chart", value: "1" },
          ],
          showHead: false,
          // columnStyles: {
          //     name: { fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold' },
          // },
          tableWidth: 80,
          didParseCell: (cellData) => {
            if (cellData.row.index === 0) {
              cellData.cell.styles.fillColor = "#2980ba";
              cellData.cell.styles.textColor = "white";
              cellData.cell.styles.fontStyle = "bold";
            }
          },
          startY: 14,
          margin: { left: 100 },
          styles: {},
        });

        if (questionList.length > 0) {
          let questionTableHeader = [{ name: "Name", value: "Value" }];
          let leftTable = [];
          let rightTable = [];
          questionList.forEach((val, key) => {
            if (key % 2 === 0) {
              leftTable.push(val);
            } else {
              rightTable.push(val);
            }
          });

          let questionLeftTableValues = [
            { name: "QUESTIONS", value: "" },
            ...leftTable,
          ];

          let questionRightTableValues = [
            { name: "QUESTIONS", value: "" },
            ...rightTable,
          ];

          pdf.addPage();

          autoTable(pdf, {
            head: questionTableHeader,
            body: questionLeftTableValues,
            showHead: false,
            // columnStyles: {
            //     name: { fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold' },
            // },
            didParseCell: (cellData) => {
              if (cellData.row.index === 0) {
                cellData.cell.styles.fillColor = "#2980ba";
                cellData.cell.styles.textColor = "white";
                cellData.cell.styles.fontStyle = "bold";
              }
            },
            startY: 10,
            margin: { right: 10 },
            tableWidth: 80,
          });

          autoTable(pdf, {
            head: questionTableHeader,
            body: questionRightTableValues,
            showHead: false,
            // columnStyles: {
            //     name: { fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold' },
            // },
            didParseCell: (cellData) => {
              if (cellData.row.index === 0) {
                cellData.cell.styles.fillColor = "#2980ba";
                cellData.cell.styles.textColor = "white";
                cellData.cell.styles.fontStyle = "bold";
              }
            },
            startY: 10,
            margin: { left: 100 },
            tableWidth: 80,
          });
        }

        let deedTableHeader = [
          { dataKey: "id", header: "Si.No" },
          { dataKey: "date", header: "DATE" },
          { dataKey: "docNo", header: "DOC.No" },
          { dataKey: "deedParticular", header: "PARTICULARS OF DEEDS" },
          { dataKey: "surveyNo", header: "SURVEY NO" },
          { dataKey: "extent", header: "EXTENT" },
        ];
        let deedTableValues: any = [];
        for (let i = 0; i < deedData.length; i++) {
          deedTableValues.push({
            id: i + 1,
            date: deedData[i].date,
            docNo: deedData[i].doc_no,
            deedParticular: deedData[i].particulars,
            surveyNo: deedData[i].old_surevey,
            extent: deedData[i].extent,
          });
        }

        // pdf.text("Law No : 12345", 10, 15);
        autoTable(pdf, {
          columns: deedTableHeader,
          body: deedTableValues,
          showHead: "everyPage",
          rowPageBreak: "avoid",
          startY: (pdf as any).lastAutoTable.finalY + 300,
        });

        pdf.save(`${this.props.match.params.id}- Flow Chart`);
      });
    }
  };

  addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };
  updatedRemainingTime(time) {
  }
  render(): React.ReactNode {
    const { deedData, isSorting, flowGraphData, isSaved } = this.state;
    const user: any = sessionStorage.getItem("user");
    const user_idfk = user ? JSON.parse(user)["userId"] : "";
    let initialTimeInMinutes: any = sessionStorage.getItem('updatedRemainingTime');
    return (
      <div className="pcoded-main-container sales-deed">
        {!!deedData.length && (
          <Prompt
            // when={!isSaved}
            // message='You have unsaved changes, are you sure you want to leave?'
            message={(location, action) => {
              if (action === "POP") {
                console.log("Backing up...");
              }

              return location.pathname.includes("/sale-deed")
                ? true
                : `You have unsaved changes, are you sure you want to leave ?`;
            }}
          />
        )}
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    {initialTimeInMinutes && initialTimeInMinutes > 0 && (
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-header" style={{ height: "39px" }}>
                            <div className="row">
                              <div className="col-md-12">
                                <CountdownTimer
                                  initialTime={initialTimeInMinutes}
                                  updatedTime={(time) => this.updatedRemainingTime(time)}
                                  ref={(ref) => (this.countdownTimerRef = ref)}
                                />
                              </div><br />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <Resizable
                      className="box"
                      height={this.state.documentHeight}
                      width={this.state.documentWidth}
                      onResize={this.onDocumentResize}
                      resizeHandles={["e"]}
                    >
                      <div style={{ width: this.state.documentWidth + "%" }}>
                        {this.renderTableData()}
                      </div>
                    </Resizable>
                    <Resizable
                      className="box"
                      height={this.state.formHeight}
                      width={this.state.formWidth}
                      onResize={this.onFormResize}
                      resizeHandles={[]}
                    >
                      <div
                        id="right-graph"
                        style={{
                          width: this.state.formWidth + "%",
                          height: this.state.formHeight + "px",
                          paddingLeft: "25px",
                        }}
                      >
                        {/* <Accordion defaultActiveKey="flowchart">
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="flowchart"
                              // onClick={() => {
                              //   this.setState({ isFlowChartExpanded: !isFlowChartExpanded });
                              // }}
                            >
                              <div className="row">
                                <div className="col-md-10">
                                  <h5 style={{ margin: "2%" }}>
                                    <b>Recordings</b>
                                  </h5>
                                </div>
                                <div className="col-md-2">
                                  <i
                                    // className={`feather icon-${isFlowChartExpanded ? "minus" : "plus"
                                    //   }`}
                                    style={{
                                      color: "black",
                                      fontSize: "20px",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            </Accordion.Toggle>
                          </Card>
                        </Accordion> */}

                        {deedData && deedData.length > 0 ? (
                          <><FlowMap
                            deedData={this.state.deedData}
                            tableRef={this.refs.tableMain}
                            saveFlowDeed={this.saveFlowDeed}
                            flowGraphData={flowGraphData}
                            fileId={this.props.match.params.id}
                            downloadFlowGraph={this.downloadFlowGraph}
                            onChanges={this.detectChanges}
                            BacktoFile={this.BacktoFile}
                            user_idfk={user_idfk}
                          />
                          </>

                        ) : null}
                      </div>
                    </Resizable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export const Audiorecord = () => {
  let [audioURL, isRecording, startRecording, stopRecording, timer, audioList] = useRecorder();
  const validAudioList: { created_by: string, created_dt: string, audio_base: string }[] = Array.isArray(audioList) ? audioList : [];
  console.log("validAudioList------------->>>", timer);
  const formatTime = (startTime) => {
    const minutes = Math.floor(startTime / 60000);
    const seconds = Math.floor((startTime % 60000) / 1000).toFixed(0).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };
  return (
    <div className="audio">
      {audioURL && <audio src={String(audioURL)} controls />}
      <div style={{ float: "right", marginRight: "10%", gap: "30px", display: "flex" }}>
        {timer && <p style={{
          margin: "auto",
          fontSize: "17px",
          color: "#1890ff"
        }}>Recording...</p>}
        <button style={{ border: "none", outline: "none", width: "50px", height: "50px" }} onClick={startRecording as MouseEventHandler<HTMLButtonElement>} disabled={isRecording ? true : undefined}>
          <i className="fa fa-microphone" aria-hidden="true"></i>
        </button>
        <button style={{ border: "none", outline: "none", width: "50px", height: "50px" }} onClick={stopRecording as MouseEventHandler<HTMLButtonElement>} disabled={!isRecording ? true : undefined}>
          <i className="fa fa-stop" aria-hidden="true"></i>
        </button>
      </div>
      <div className="container"
        style={{ height: "480px", overflowY: "scroll" }}>
        <div
          className="row"
          style={{
            padding: "0px 20px",
            display: "block"
          }}
        >
          {validAudioList.length > 0 ?
            validAudioList.map((audio: { created_by: string, created_dt: string, audio_base: string }, audioIndex: number) => {
              return (
                <div key={audio.created_by + audioIndex}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {audioIndex + 1}.
                    </div>
                    <div
                      style={{
                        marginLeft: "5px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div>{audio.created_by}</div>
                      <div
                        style={{
                          marginLeft: "15px",
                        }}
                      >
                        {audio.created_dt}
                      </div>
                    </div>
                  </div>
                  <div
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   alignItems: "center",
                  // }}
                  >
                    {/* <audio controls src={audio.audio_base} /> */}
                    <audio controls>
                      <source src={audio.audio_base} />
                    </audio>
                    {/* <div
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        deleteAudio(audio.audio_id);
                      }}
                    >
                      <img src={DeleteIcon} />
                    </div> */}
                  </div>
                </div>
              );
            })
            : <div style={{
              textAlign: 'center',
              width: '100%',
              fontSize: '14px'
            }}>
              No Recordings found
            </div>
          }
        </div>
      </div>
    </div>
  );
}
