import React, { Component } from "react";
import Spinner from "./loader.js";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Resizable } from 'react-resizable';
// import { pdfjs } from "react-pdf";
import { Link } from "react-router-dom";
import Select from 'react-select';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { setTimeout } from "timers";

import { API_ENDPOINT, callApi } from "../../utils/api";
import SurveyItem, { Survey } from "./SurveyItem";
import PDFHighlighter from '../PDF-Highlighter';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import { Loader } from "react-hyper-tree";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import CountdownTimer from "../file/CountdownTimer";
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.min.js`;

export interface GeneralDetailEntity {
  id: string;
  fod_fk: string;
  applicant_name: string;
  co_applicant_name: string;
  // plot_no: string;
  address: string;
  created_by: string;
  door_no: string;
  flat_no: string;
  floor: string;
  buildup: string;
  appt_name: string;
  survey: Survey[];
  // measure_list: MeasureList[];
  // measurement_list: MeasureLabel[];
}
interface ScheduleList {
  survey_details: [];
  // measure_details: [];
  // measurement_list: [];
  nature_doc: string;
  builtup_unit: string,
  uds_unit: string,
  applicant_name: string;
  co_applicant_name: string;
  // plot_no: string;
  collapseid?: boolean;
  address: string;
  created_by: string;
  door_no: string;
  flat_no: string;
  floor: string;
  buildup: string;
  appt_name: string;
  delete_items_schedule: [];
  uds: string;
  remark: string;
}
interface AllProps {
  history: any;
  location: any;
  match: any;
  id: any;
  goToTab: any;
}
export default class GeneralDetailForm extends Component<AllProps> {
  state = {
    showTable: false,
    surveyDetailsTD: [],
    address: "",
    boundary: [],
    measures: [],
    natureDocN: "",
    measureList: [],
    measDirList: [],
    timer: 0,
    loader: false,
    base: "",
    nature_doc: "",
    builtup_unit: "",
    uds_unit: "",
    numPages: 0,
    pageNumber: 1,
    type: "",
    categories: [],
    schedules: [],
    isLoading: false,
    documentWidth: 50, documentHeight: 850,
    formWidth: 50, formHeight: 600,
    total_extent: "",
    extent_unit: "",
    selectedDoc: "",
    allDocuments: [],
    nature_docs: [],
    extentUnits: [],
    inactive: 0,
    activeNormal: 0,
    activeEditor: 0,
    url: '',
    highlights: [],
    rotation: 0,
    disabled: true,
    enableWheel: false,
    panningEnabled: false,
    transformEnabled: false,
    pinchEnabled: false,
    enableTouchPadPinch: false,
    isDocPreviewLoading: false,
    velocityEqualToMove: false,
    enableVelocity: false,
    isHightlightsLoaded: false,
    docReportForPreview: "",
    docToPreview: false,
    required: false,
    normalMode: true,
    editorMode: false,
    qaList: [],
    editorcontent: "",
    autoAssign: [],
    showdoor_no: false
  };
  document = {
    base: "",
    numPages: 0,
    pageNumber: 1
  };
  countdownTimerRef: CountdownTimer | null = null;
  componentDidMount() {
    this.getCategories();  // For getting category
    this.getGeneralDetail(); // For getting SOP over all
    this.getRevenueDocs(); // For getting PDF
    this.getAllDocuments(); // For getting Document dropdown
    this.getNatureDocs();
    this.getExtentUnits();
    this.liveinsert();
    this.getAutoAssignStatus();
    setInterval(() => {
      this.incrementTimer();
    }, 1000);
  }
  incrementTimer = () => {
    const { timer } = this.state;
    this.setState({ timer: timer + 1 })
  }
  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  liveinsert = () => {

    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["team_type"] : "";
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    console.log("214", role);
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const bankName: any = localStorage.getItem("bankName");
    const fileNo: any = localStorage.getItem("filenumber");
    const in_reason: any = localStorage.getItem("inreasonlive");
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    const textRay = `File_No : ${this.props.match.params.id} On General detail}`;
    let obj = {
      file_no: fileNo,
      in_time: curentTime,
      reason: in_reason,
      user_id: userId,
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: textRay,
      bank_name: bankName,
    }
    console.log("231", obj);
    callApi("POST", "live_track_insert.php", obj)
      .then(res => res.data)
      .then(response => {

      })
  }
  onDocumentResize = (event, { element, size, handle }) => {
    if (size.width <= 60) {
      this.setState({ documentWidth: size.width, documentHeight: size.height, formWidth: 90 - size.width });
    }
  };
  onFormResize = (event, { element, size, handle }) => {
    this.setState({ formWidth: size.width, formHeight: size.height, documentWidth: 90 - size.width });
  };
  toggleDocStatus = () => {
    const inactive = this.state.inactive ? 0 : 1;
    callApi("POST", "doc_status_update.php", { id: this.props.match.params.docid, inactive })
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('overviewDocument');
        this.setState({ inactive })
      })
      .catch(err => {
        console.log(err);
      });
  };
  getCategories = () => {
    callApi("POST", "category.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ categories: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getGeneralDetail = () => {
    callApi("POST", "general_details_get.php", {
      id: this.props.match.params.docid
    })
      .then(res => res.data)
      .then(response => {
        if (response.data) {
          const schedules = response.data.schedules;
          if (schedules?.[0]?.nature_doc != "") {
            this.setState({
              natureDocN: schedules?.[0]?.nature_doc,
              showTable: true,
              surveyDetailsTD: schedules?.[0]?.survey_details,
              address: schedules?.[0]?.address,
              boundary: schedules?.[0]?.survey_details?.[0]?.boundary_details,
              measureList: schedules?.[0]?.survey_details?.[0]?.boundary_details?.[0]?.measurement_list,
              measDirList: schedules?.[0]?.survey_details?.[0]?.boundary_details?.[0]?.measurement_list?.[0]?.meas_dir_list,
              total_extent: schedules?.[0]?.total_extent,
              extent_unit: schedules?.[0]?.extent_unit,
              editorcontent: schedules?.[0]?.editorcontent
            })
            if (schedules?.[0]?.nature_doc == "Existing building" || schedules?.[0]?.nature_doc == "appartment") {
              this.setState({ showdoor_no: true });
            } else {
              this.setState({ showdoor_no: false });
            }
          } else {
            this.setState({ showTable: false });
          }
          // state.survey_details = response.data[0].survey_details;
          // state.measure_details = response.data[0].measure_details;
          // state.measurement_details = response.data[0].measurement_list;
          this.setState({ schedules });
          this.setState({ highlights: schedules?.[0]?.highlights });
          if (!schedules || !schedules.length) {
            this.addItem();
          } else {
            const inactive = schedules?.[0]?.inactive;
            this.setState({ inactive });
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getRevenueDocs = () => {
    this.setState({ loader: true });
    callApi("POST", "rev_get.php", { id: this.props.match.params.docid })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const document = this.document;
          document.base = response.data[0].base[0].base;
          const state = this.state;
          state.type = response.data[0].base[0].type;
          state.selectedDoc = "";
          this.setState({ ...document, state, isHightlightsLoaded: true, loader: false });
          // loader:false
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getAllDocuments = () => {
    callApi("POST", "all_doc_no_list.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        this.setState({ allDocuments: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  getNatureDocs = () => {
    const nature_docs = [
      {
        id: '',
        name: 'Select the Nature of property'
      },
      {
        id: 'Agri',
        name: 'Agri'
      },
      {
        id: 'Existing building',
        name: 'Existing building'
      },
      {
        id: 'Under construction',
        name: 'Under construction'
      },
      {
        id: 'vacant land',
        name: 'Vacant Land'
      },
      {
        id: 'appartment',
        name: 'Appartment'
      }
    ]
    this.setState({ nature_docs })
  };

  getExtentUnits = () => {
    const extentUnits = [
      {
        id: 'Sq.Ft',
        name: 'Sq.Ft'
      },
      {
        id: 'Sq.Yards',
        name: 'Sq.Yards'
      },
      {
        id: 'Sq.Meter',
        name: 'Sq.Meter'
      },
      {
        id: 'Acres',
        name: 'Acres'
      },
      {
        id: 'Cents',
        name: 'Cents'
      },
      {
        id: 'Hectares',
        name: 'Hectares'
      },
      {
        id: 'Ground',
        name: 'Ground'
      },
      {
        id: 'Kuzhi',
        name: 'Kuzhi'
      },
      {
        id: 'Kaani',
        name: 'Kaani'
      },
      {
        id: 'Ares',
        name: 'Ares'
      },
      {
        id: 'Veesam',
        name: 'Veesam'
      },
      {
        id: 'Santhiyar',
        name: 'Santhiyar'
      },
    ]
    this.setState({ extentUnits })
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  addItem = (): void => {
    const schedules: ScheduleList[] = this.state.schedules
      ? this.state.schedules
      : [];
    schedules.push({
      survey_details: [],
      // measure_details: [],
      // measurement_list: [],
      nature_doc: "",
      builtup_unit: "",
      uds_unit: "",
      applicant_name: "",
      co_applicant_name: "",
      // plot_no: "",
      address: "",
      created_by: "",
      door_no: "",
      flat_no: "",
      floor: "",
      buildup: "",
      appt_name: "",
      delete_items_schedule: [],
      uds: "",
      remark: "",
    });
    this.setState({ schedules });
  };
  removeItem = (modal, index) => {
    const schedules: ScheduleList[] = this.state.schedules;
    const delete_items_schedule: any = this.state.schedules[index][
      "delete_items_schedule"
    ]
      ? this.state.schedules[index]["delete_items_schedule"]
      : [];
    delete_items_schedule.push(modal.schedule_id);
    schedules.splice(index, 1);
    this.setState({ schedules, delete_items_schedule });
  };
  collapsearray = (modal: ScheduleList, index: number): void => {
    const schedules: ScheduleList[] = this.state.schedules;
    if (schedules[index] !== undefined) {
      if (schedules[index].collapseid) {
        schedules[index].collapseid = false;
      }
      else {
        schedules[index].collapseid = true;
        this.setState({ schedules });
      }
    }
  };

  handleChange = (e, index, propName?, fieldType?) => {
    const schedules: any = this.state.schedules;
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    schedules[index][fieldName] = fieldValue;
    this.setState({ schedules });
    console.log("nature_doc", fieldName, fieldValue);
    if (fieldValue == "Existing building" || fieldValue == "appartment") {
      this.setState({ showdoor_no: true });
    } else {
      this.setState({ showdoor_no: false });
    }
  };
  categChange = (e, propName?, fieldType?) => {
    const state: any = this.state;
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    state[fieldName] = fieldValue;
    this.setState({ ...state });
  };
  changeFileType = () => {
    callApi("POST", "file_type.php", {
      id: this.props.match.params.docid,
      type: this.state.type
    })
      .then(res => res.data)
      .then(response => {
        if (this.state.type === "ec_doc") {
          this.props.history.push(
            "/ec-form/" +
            this.props.match.params.id +
            "/" +
            this.props.match.params.docid +
            "/2"
          );
        } else if (
          this.state.type === "deed" ||
          this.state.type === "rev_rec_doc" ||
          this.state.type === "sale_agreement" ||
          this.state.type === "mother_doc"
        ) {
          this.props.history.push(
            "/scrutiny/" +
            this.props.match.params.id +
            "/" +
            this.props.match.params.docid +
            "/2"
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  stateToProps = (event, index) => {
    const schedules: any = this.state.schedules;
    schedules[index][event.name] = event.value;
    this.setState({ schedules });
  };
  saveGeneralDoc = status => {
    console.log("editorcontent------------>", this.state.editorcontent);
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const state: any = this.state;
    state.fod_fk = this.props.match.params.id;
    state.id = this.props.match.params.docid;
    state.status = status;
    state.delete_items = {
      schedules: [],
      survey: [],
      measure_list: [],
      measurement_list: [],
      meas_dir_list: []
    };
    this.state.schedules.forEach((sch: any) => {
      state.delete_items.schedules = state.delete_items_schedule;
      state.delete_items.survey = state.delete_items.survey
        .concat(sch.delete_items_survey)
        .filter(itm => itm);
      state.delete_items.measure_list = state.delete_items.measure_list
        .concat(sch.delete_items_measure)
        .filter(itm => itm);
      state.delete_items.measurement_list = state.delete_items.measurement_list
        .concat(sch.delete_items_measurements)
        .filter(itm => itm);
      state.delete_items.meas_dir_list = state.delete_items.meas_dir_list
        .concat(sch.delete_items_measure_label)
        .filter(itm => itm);
    });
    let remark: any;
    let address: any;
    let natureDoc: any;
    let flatNo: any
    let floorNo: any;
    let apptName: any;
    let buildupArea: any;
    let builtupUnit: any;
    let uds: any;
    let udsUnit: any;
    const validateRemark = this.state.schedules.find((sch: ScheduleList) => {
      remark = sch.remark;
      address = sch.address;
      natureDoc = sch.nature_doc;
      flatNo = sch.flat_no;
      floorNo = sch.floor;
      // apptName = sch.appt_name;
      buildupArea = sch.buildup;
      builtupUnit = sch.builtup_unit;
      uds = sch.uds;
      udsUnit = sch.uds_unit;
    });
    if (this.state.inactive == 1) {
      this.setState({ isLoading: true });
      sessionStorage.removeItem('overviewDocument');
      callApi("POST", "general_details.php", state)
        .then(res => res.data)
        .then(response => {
          delete state.base;
          delete state.categories;
          delete state.state;
          delete state.measure_details;
          delete state.measurement_list;
          delete state.survey_details;
          delete state.numPages;
          delete state.pageNumber;
          delete state.delete_items_survey;
          delete state.delete_items_measure;
          delete state.delete_items_measure_label;
          delete state.delete_items_schedule;
          // this.props.goToTab("step2");
          this.setState({ isLoading: false }, () => {
            this.getGeneralDetail();
            if (isVerifyFlow) {
              ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
              setTimeout(() => {
                window.close();
              }, 3000);
            } else {
              if (state.status == 1) {

                ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
              } else {
                this.props.history.push("/work-file/2/" + this.props.match.params.id);

              }
            }

          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    } else {
      if (natureDoc == "appartment") {
        if (flatNo == "" || floorNo == "" || natureDoc == "" || apptName == "" || buildupArea == "" || builtupUnit == "" || uds == "" || udsUnit == "" || remark == "" || address == "" || natureDoc == "" || state.schedules[0].survey_details[0].boundary_details[0].land_aries == "" || state.schedules[0].survey_details[0].boundary_details[0].direction == "" || state.schedules[0].survey_details[0].boundary_details[0].unit == "" || state.schedules[0].survey_details[0].boundary_details[0].north == "" || state.schedules[0].survey_details[0].boundary_details[0].south == "" || state.schedules[0].survey_details[0].boundary_details[0].west == "" || state.schedules[0].survey_details[0].boundary_details[0].east == "") {
          this.setState({ required: true });
          ToastsStore.error("Required fields are Empty");
        } else {
          if (state.schedules[0].survey_details[0].survey_type == "ts_no") {
            if (state.schedules[0].survey_details[0].ts_no == "" || state.schedules[0].survey_details[0].ts_ward == "" || state.schedules[0].survey_details[0].ts_block == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {
              this.setState({ required: true });
              ToastsStore.error("Required fields are Empty");
            }
            else {
              this.setState({ isLoading: true });
              sessionStorage.removeItem('overviewDocument');
              callApi("POST", "general_details.php", state)
                .then(res => res.data)
                .then(response => {
                  delete state.base;
                  delete state.categories;
                  delete state.state;
                  delete state.measure_details;
                  delete state.measurement_list;
                  delete state.survey_details;
                  delete state.numPages;
                  delete state.pageNumber;
                  delete state.delete_items_survey;
                  delete state.delete_items_measure;
                  delete state.delete_items_measure_label;
                  delete state.delete_items_schedule;
                  // this.props.goToTab("step2");
                  this.setState({ isLoading: false }, () => {
                    this.getGeneralDetail();
                    if (isVerifyFlow) {
                      ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                      setTimeout(() => {
                        window.close();
                      }, 3000);
                    } else {
                      if (state.status == 1) {

                        ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                      } else {
                        this.props.history.push("/work-file/2/" + this.props.match.params.id);

                      }
                    }
                  });
                })
                .catch(err => {
                  console.log(err);
                  this.setState({ isLoading: false });
                });
            }
          } else if (state.schedules[0].survey_details[0].survey_type == "natham_survey_no") {
            if (state.schedules[0].survey_details[0].natham_survey_no == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {
              this.setState({ required: true });
              ToastsStore.error("Required fields are Empty");
            } else {
              this.setState({ isLoading: true });
              sessionStorage.removeItem('overviewDocument');
              callApi("POST", "general_details.php", state)
                .then(res => res.data)
                .then(response => {
                  // this.props.goToTab("step2");
                  this.setState({ isLoading: false }, () => {
                    this.getGeneralDetail();
                    if (isVerifyFlow) {
                      ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                      setTimeout(() => {
                        window.close();
                      }, 3000);
                    } {
                      if (state.status == 1) {

                        ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                      } else {
                        this.props.history.push("/work-file/2/" + this.props.match.params.id);

                      }
                    }
                  });
                })
                .catch(err => {
                  console.log(err);
                  this.setState({ isLoading: false });
                });
            }
          } else if (state.schedules[0].survey_details[0].survey_type == "survey_no") {
            if (state.schedules[0].survey_details[0].survey_no == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {
              this.setState({ required: true });
              ToastsStore.error("Required fields are Empty");
            }
            else {
              this.setState({ isLoading: true });
              sessionStorage.removeItem('overviewDocument');
              callApi("POST", "general_details.php", state)
                .then(res => res.data)
                .then(response => {
                  // this.props.goToTab("step2");
                  this.setState({ isLoading: false }, () => {
                    this.getGeneralDetail();
                    if (isVerifyFlow) {
                      ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                      setTimeout(() => {
                        window.close();
                      }, 3000);
                    } {
                      if (state.status == 1) {

                        ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                      } else {
                        this.props.history.push("/work-file/2/" + this.props.match.params.id);

                      }
                    }
                  });
                })
                .catch(err => {
                  console.log(err);
                  this.setState({ isLoading: false });
                });
            }
          }
        }
      }
      else if (natureDoc == "Agri") {
        if (remark == "" || address == "" || natureDoc == "") {
          if (state.schedules[0].survey_details[0].survey_type == "ts_no") {
            if (state.schedules[0].survey_details[0].ts_no == "" || state.schedules[0].survey_details[0].ward == "" || state.schedules[0].survey_details[0].block == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {

            }
          } else if (state.schedules[0].survey_details[0].survey_type == "natham_survey_no") {
            if (state.schedules[0].survey_details[0].natham_survey_no == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {

            }
          } else if (state.schedules[0].survey_details[0].survey_type == "survey_no") {
            if (state.schedules[0].survey_details[0].survey_no == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {

            }
          }
          this.setState({ required: true });
          ToastsStore.error("Required fields are Empty");
        }
        else {
          this.setState({ isLoading: true });
          sessionStorage.removeItem('overviewDocument');
          callApi("POST", "general_details.php", state)
            .then(res => res.data)
            .then(response => {
              // this.props.goToTab("step2");
              this.setState({ isLoading: false }, () => {
                this.getGeneralDetail();
                if (isVerifyFlow) {
                  ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                  setTimeout(() => {
                    window.close();
                  }, 3000);
                } {
                  if (state.status == 1) {

                    ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                  } else {
                    this.props.history.push("/work-file/2/" + this.props.match.params.id);
                  }
                }
              });
            })
            .catch(err => {
              console.log(err);
              this.setState({ isLoading: false });
            });
        }
      }
      else {
        if (remark == "" || address == "" || natureDoc == "" || state.schedules[0].survey_details[0].boundary_details[0].land_aries == "" || state.schedules[0].survey_details[0].boundary_details[0].direction == "" || state.schedules[0].survey_details[0].boundary_details[0].unit == "" || state.schedules[0].survey_details[0].boundary_details[0].north == "" || state.schedules[0].survey_details[0].boundary_details[0].south == "" || state.schedules[0].survey_details[0].boundary_details[0].west == "" || state.schedules[0].survey_details[0].boundary_details[0].east == "") {
          if (state.schedules[0].survey_details[0].survey_type == "ts_no") {
            if (state.schedules[0].survey_details[0].ts_no == "" || state.schedules[0].survey_details[0].ward == "" || state.schedules[0].survey_details[0].block == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {

            }
          } else if (state.schedules[0].survey_details[0].survey_type == "natham_survey_no") {
            if (state.schedules[0].survey_details[0].natham_survey_no == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {

            }
          } else if (state.schedules[0].survey_details[0].survey_type == "survey_no") {
            if (state.schedules[0].survey_details[0].survey_no == "" || state.schedules[0].survey_details[0].extent == "" || state.schedules[0].survey_details[0].sur_extent_unit == "") {

            }
          }
          this.setState({ required: true });
          ToastsStore.error("Required fields are Empty");
        }
        else {
          this.setState({ isLoading: true });
          sessionStorage.removeItem('overviewDocument');
          callApi("POST", "general_details.php", state)
            .then(res => res.data)
            .then(response => {
              // this.props.goToTab("step2");
              this.setState({ isLoading: false }, () => {
                this.getGeneralDetail();
                if (isVerifyFlow) {
                  ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                  setTimeout(() => {
                    window.close();
                  }, 3000);
                } {
                  if (state.status == 1) {

                    ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                  } else {
                    this.props.history.push("/work-file/2/" + this.props.match.params.id);
                  }
                }
              });
            })
            .catch(err => {
              console.log(err);
              this.setState({ isLoading: false });
            });
        }
      }
    }

  };
  onModifyTotalExtent = () => {
    if (this.state.total_extent && this.state.extent_unit) {
      callApi("POST", "general_total_extent.php", {
        file_id: this.props.match.params.id,
        doc_id: this.props.match.params.docid,
        total_extent: this.state.total_extent,
        extent_unit: this.state.extent_unit
      })
        .then(res => res.data)
        .then(response => {
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
  viewDocument = (e) => {
    // this.getAllDocuments();
    const selectedValue = e?.target?.value;
    const selectedIndex = e?.target?.options?.selectedIndex;
    const selectedId = e?.target?.options?.[selectedIndex]?.getAttribute('data-id')?.split("|")?.[0];
    if (selectedId) {
      callApi("POST", "getbaseby_id.php", { id: selectedId })
        .then(res => res.data)
        .then(response => {
          if (response.data && response.data.length) {
            const document = this.document;
            if (response.data && response.data?.[0]?.base && response.data[0].base?.length > 0) {
              document.base = response.data[0].base[0].base;
            }
            this.setState({ ...document, selectedDoc: selectedValue });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  questionHandleChange = (e) => {
    const list: any[] = this.state.qaList;
    // list[i]['question'] = e;
    console.log("list--->>>", list);
    this.setState({
      isEdited: true,
      qaList: list
    });
  }
  handleChangeNormal = (e) => {
    this.state.normalMode = !this.state.normalMode;
    this.state.editorMode = !this.state.editorMode;
  };
  // handleChangeEditor = (e) => {
  //   this.state.normalMode = !this.state.normalMode;
  //   this.state.editorMode = !this.state.editorMode;
  // }
  previewDocument = () => {

    var docOverview = sessionStorage.getItem("overviewDocument");
    console.log("docOverview-------->>>", docOverview);
    console.log("currentFile-------->>>", sessionStorage.getItem("currentFile"));
    if (this.props.match.params.id == sessionStorage.getItem("currentFile")) {
      if (docOverview != null) {
        this.setState({ docReportForPreview: docOverview, enableRefresh: true });
        this.setState({ isDocPreviewLoading: false })
      } else {
        this.setState({ isDocPreviewLoading: true })
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        callApi("POST", "template/tests/scrutiny_report.php", {
          file_idfk: this.props.match.params.id,
          check: false, userId
        })
          .then(res => res.data)
          .then(response => {
            if (response && response.data) {
              console.log("response.data------>>>>>", response);
              sessionStorage.setItem("overviewDocument", response.data);
              var currentFile = response.file_name.split("-");
              sessionStorage.setItem("currentFile", currentFile[1]);
              this.setState({ docReportForPreview: response.data });
            }
          }).finally(() => this.setState({ isDocPreviewLoading: false }))
      }
    } else {
      this.setState({ isDocPreviewLoading: true })
      const user: any = sessionStorage.getItem("user");
      const userId = user ? JSON.parse(user)["userId"] : "";
      callApi("POST", "template/tests/scrutiny_report.php", {
        file_idfk: this.props.match.params.id,
        check: false, userId
      })
        .then(res => res.data)
        .then(response => {
          if (response && response.data) {
            console.log("response.data------>>>>>", response);
            sessionStorage.setItem("overviewDocument------->>>", response.data);
            var currentFile = response.file_name.split("-");
            sessionStorage.setItem("currentFile", currentFile[1]);
            this.setState({ docReportForPreview: response.data });
          }
        }).finally(() => this.setState({ isDocPreviewLoading: false }))
    }

  }
  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({ rotation: newRotation })
  }
  onHighlightsChange = (highlights) => {
    this.setState({ highlights });
  }
  setContent(e) {
    console.log("editor mode value----->", e);
    this.setState({ editorcontent: e });
  }
  updatedRemainingTime(time) {
  }
  render() {
    const { rotation, velocityEqualToMove, enableVelocity, highlights, isHightlightsLoaded, base, docToPreview, loader, timer } = this.state;
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const takenfile_id: any = sessionStorage.getItem("takenfile_id");
    const currentfile_Id: any = sessionStorage.getItem("currentfileId");
    let takenfile = null;
    if (takenfile_id) {
      try {
        takenfile = JSON.parse(takenfile_id);
      } catch (error) {
        console.error("Error parsing takenfile_id JSON:", error);
      }
    };
    const currentfile = JSON.parse(currentfile_Id);
    // console.log("currentfile", currentfile);
    // console.log("takenfile", takenfile);
    const user: any = sessionStorage.getItem("user");
    const roletype = user ? JSON.parse(user)["roleType"] : "";
    let initialTimeInMinutes: any = sessionStorage.getItem('updatedRemainingTime');
    return (
      <>

        <div className="pcoded-content">
          <ToastsContainer
            store={ToastsStore}
            position={ToastsContainerPosition.TOP_RIGHT}
          />

          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header" style={{ padding: "11px", fontWeight: 900 }}>
                        <div className="row">
                          <div className="col-md-12">
                            {initialTimeInMinutes && initialTimeInMinutes > 0 && (
                              <CountdownTimer
                                initialTime={initialTimeInMinutes}
                                updatedTime={(time) => this.updatedRemainingTime(time)}
                                ref={(ref) => (this.countdownTimerRef = ref)}
                              />
                            )}
                          </div><br />
                        </div>
                        <h5>Scheduled of property</h5>
                      </div>
                      <div className="card-body">
                        <div id="step-1">
                          <h5>General Detail</h5>
                          {/* <Link
                            to={"/query/add/0/" + this.props.match.params.id}
                            style={{ marginTop: "-42px" }}
                            className="btn btn-square btn-info float-right"
                          >
                            Raise Query
                          </Link> */}
                          {/* <button
                            data-toggle="modal"
                            data-target="#doc-preview"
                            onClick={this.previewDocument}
                            style={{ marginTop: "-42px", backgroundColor: '#b21fc6', color: "#fff" }}
                            className="btn btn-square btn-default float-right"
                          >
                            Document Preview
                          </button> */}
                          <hr />
                          <div className="row">
                            <Resizable id="frame" className="box" height={this.state.documentHeight} width={this.state.documentWidth} onResize={this.onDocumentResize} resizeHandles={['e']}>
                              <div
                                style={{
                                  overflow: "auto", marginRight: '0', maxWidth: '60%', minWidth: '40%', float: 'left', width: this.state.documentWidth + '%'
                                }}>
                                <div className="container">
                                  <div className="row" style={{
                                    width: "100%",
                                    border: "1px solid #e2e5e8",
                                    borderRadius: "10px",
                                    margin: "auto",
                                    padding: "7px"
                                  }}>
                                    <div className="col-md-4">
                                      <label>Document</label>
                                      {this.state.allDocuments && this.state.allDocuments.length > 0 && this.state.allDocuments.map((opt: any) => {
                                        // opt.label = opt.status ? `Document-${opt.id}-${opt.status}` : `Document-${opt.id}`;
                                        opt.label = opt.id + (opt.status == 1 ? "- (Not Related)" : "");
                                        opt.value = opt.base_id
                                      }) &&
                                        <select
                                          className="custom-select"
                                          name="selectedDoc"
                                          value={this.state.selectedDoc}
                                          onChange={this.viewDocument}
                                          required
                                        >
                                          <option value="">Select</option>
                                          {this.state.allDocuments &&
                                            this.state.allDocuments.length
                                            ? this.state.allDocuments.map(
                                              (doc: any, index: number) => {
                                                return (
                                                  <option
                                                    key={`${doc.id}|${index}`}
                                                    data-id={`${doc.value}|${index}`}
                                                    value={this.state.allDocuments.find((o: any) => o.value === this.state.selectedDoc)}
                                                  >
                                                    {doc.label}
                                                  </option>
                                                );
                                              }
                                            )
                                            : ""}
                                        </select>
                                        // <Select options={this.state.allDocuments} name="selectedDoc" style={{
                                        //   height: "2.1em",
                                        //   marginTop: "-2px"
                                        // }}

                                        //   value={this.state.allDocuments.find((o: any) => o.value === this.state.selectedDoc)}
                                        //   onChange={(e) => this.viewDocument(e)}
                                        // />

                                      }
                                    </div>
                                    <div className="col-md-2"
                                      style={{ marginTop: "27px" }}>
                                      <button
                                        type="button"
                                        style={{ float: "right" }}
                                        className="btn btn-warning"
                                        onClick={this.getRevenueDocs}
                                      >
                                        Original
                                      </button>
                                    </div>
                                    <div className="col-md-4" style={{ marginLeft: "1%" }}>
                                      <label style={{ marginLeft: "-12px" }}>Not Related Doc</label>
                                      <div>
                                        <label className="switch">
                                          <input type="checkbox" checked={this.state.inactive === 1 ? true : false} onChange={this.toggleDocStatus} />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                      <label style={{ margin: '0px 10px' }}>No/Yes</label>
                                    </div>
                                  </div>
                                  <br />
                                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                      <a className="nav-link active" id="old-view-tab" data-toggle="tab"
                                        href="#old-viewer" role="tab" aria-controls="home" aria-selected="true">
                                        Old Viewer
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id="new-view-tab" data-toggle="tab"
                                        href="#new-viewer" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.setState({ docToPreview: true })}>
                                        New Viewer
                                      </a>
                                    </li>
                                  </ul>
                                  {loader ? (
                                    <>
                                      <Spinner />
                                      <div className="text-center">
                                        <h4 style={{ marginTop: '49%', marginLeft: '90px' }}>
                                          <span >Please wait...{timer}</span>
                                        </h4>
                                      </div>
                                    </>

                                  ) : (
                                    <div className="tab-content" id="tab-content" style={{
                                      borderBottomRightRadius: "10px",
                                      borderBottomLeftRadius: "10px"
                                    }}>
                                      <div className="tab-pane fade show active" id="old-viewer" role="tabpanel" aria-labelledby="old-viewer">
                                        <iframe
                                          src={this.document.base}
                                          width='100%'
                                          height={this.state.documentHeight}
                                        />
                                      </div>
                                      <div className="tab-pane fade" id="new-viewer" role="tabpanel" aria-labelledby="new-viewer">
                                        <p style={{ color: 'red' }}>NOTE: TO HIGHLIGHT [ ALT + RIGHT CLICK ON MOUSE AND DRAG ]</p>
                                        <br />
                                        <div className="App" style={{ display: 'flex', height: "820px" }}>
                                          <TransformWrapper
                                            pan={{
                                              disabled: true,
                                              velocityEqualToMove,
                                              velocity: enableVelocity
                                            }}
                                            wheel={{
                                              disabled: true
                                            }}
                                          >
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                              <React.Fragment>
                                                <div className="tools">
                                                  <Rotate90DegreesCcwIcon className="pdf-icon rotate-icon" onClick={this.rotate} />
                                                  <ZoomInIcon className="pdf-icon" onClick={zoomIn} />
                                                  <ZoomOutIcon className="pdf-icon" onClick={zoomOut} />
                                                  <FullscreenExitIcon className="pdf-icon" onClick={resetTransform} />
                                                  {this.state.base && this.state.isHightlightsLoaded && <FullscreenIcon className="pdf-icon" onClick={() => this.setState({ docToPreview: true })} />}
                                                </div>
                                                <TransformComponent>
                                                  <div
                                                    style={{
                                                      height: "820px",
                                                      width: '45vw',
                                                      position: 'relative',
                                                      transform: `rotate(${rotation}deg)`
                                                    }}
                                                  >
                                                    {base && !docToPreview && <PDFHighlighter url={base} onHighlightsChange={this.onHighlightsChange} highlights={highlights} />}
                                                  </div>
                                                </TransformComponent>
                                              </React.Fragment>
                                            )}
                                          </TransformWrapper>
                                        </div>
                                      </div>
                                    </div>)}

                                </div>
                              </div>
                            </Resizable>
                            <Resizable className="box" height={this.state.formHeight} width={this.state.formWidth} onResize={this.onFormResize} resizeHandles={[]}>
                              <div style={{ width: this.state.formWidth + '%', height: this.state.formHeight + 'px' }}>
                                <div className="row" style={{
                                  width: "100%",
                                  border: "1px solid #e2e5e8",
                                  borderRadius: "10px",
                                  margin: "auto",
                                  padding: "7px",
                                  height: "19%"
                                }}>
                                  <div className="col-md-9">
                                    <label>Category</label>
                                    {this.state.categories && this.state.categories.length > 0 && this.state.categories.map((opt: any) => {
                                      opt.label = opt.name; opt.value = opt.id
                                    }) &&
                                      <Select options={this.state.categories} name="type" style={{ marginTop: "-2px" }}
                                        value={this.state.categories.find((o: any) => o.value === this.state.type)}
                                        onChange={(e) => this.categChange(e, 'type', 'singleselect')}
                                      />
                                    }
                                  </div>
                                  <div
                                    className="col-md-2"
                                    style={{ marginTop: "21px" }}
                                  >
                                    <button
                                      className="btn btn-primary sw-btn-next"
                                      type="button"
                                      onClick={() => this.changeFileType()}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label style={{
                                        textAlign: "center",
                                        display: "block"
                                      }}>Normal Mode</label>
                                      <input
                                        style={{ fontSize: "10px" }}
                                        className="form-control cursor-pointer"
                                        name="normalMode"
                                        type="checkbox"
                                        checked={this.state.normalMode}
                                        onChange={this.handleChangeNormal}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">

                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label style={{
                                        textAlign: "center",
                                        display: "block"
                                      }}>Editor Mode</label>
                                      <input
                                        style={{ fontSize: "10px" }}
                                        className="form-control border-0 cursor-pointer"
                                        name="editorMode"
                                        type="checkbox"
                                        checked={this.state.editorMode}
                                        onChange={this.handleChangeNormal}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {this.state.editorMode == true && (
                                  <SunEditor
                                    autoFocus={true}
                                    width="100%"
                                    height="850px"
                                    setOptions={{
                                      buttonList: [
                                        // default
                                        ["undo", "redo"],
                                        ["bold", "underline"],
                                        ["table"],
                                        //  ["link", "image"],
                                        ["fullScreen"]
                                      ]
                                    }}
                                    setContents={this.state.editorcontent}
                                    onChange={e => this.setContent(e)}
                                  />
                                )}
                                {/* <div>{content}</div>
                                  <button onClick={() => onSubmit(content)}>Submit</button> */}
                                {this.state.normalMode == true && (
                                  <div style={{
                                    width: "100%",
                                    border: "1px solid #e2e5e8",
                                    borderRadius: "10px",
                                    margin: "auto",
                                    marginTop: "24px",
                                    maxHeight: "200%",
                                    height: "152%",
                                    maxWidth: "150%"
                                  }}>
                                    <div className="row" style={{
                                      marginBottom: "-2%",
                                      padding: "20px"
                                    }}>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label>Total Extent</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Total Extent"
                                            name="total_extent"
                                            value={this.state.total_extent}
                                            onChange={e => this.categChange(e)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label style={{ width: "300px" }}>Select Unit <span className="inputEgMsg">Eg.434 Sq.Meter / Sq.Yards Etc...</span></label>
                                          {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                            <Select options={this.state.extentUnits} name="extent_unit"
                                              value={this.state.extentUnits.find((o: any) => o.value === this.state.extent_unit)}
                                              onChange={(e) => this.categChange(e, "extent_unit", 'singleselect')}
                                            />
                                          }
                                        </div>
                                      </div>
                                      <div
                                        className="col-md-1"
                                        style={{ margin: "23px 0px 0px -13px" }}
                                      >
                                        <button
                                          className="btn btn-primary sw-btn-next"
                                          type="button"
                                          onClick={() => this.onModifyTotalExtent()}
                                        >
                                          Modify
                                        </button>
                                      </div>
                                      <div
                                        className="col-md-4"
                                        style={{ margin: "23px 0px 0px 13px" }}
                                      >
                                        <a
                                          className="btn btn-primary m-b-20"
                                          onClick={this.addItem}
                                          style={{
                                            marginLeft: "15px"
                                          }}
                                        >
                                          + Add Schedule
                                        </a>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="form-document" style={{ maxHeight: "761px" }}>
                                      {this.state.schedules &&
                                        this.state.schedules.length > 0
                                        ? this.state.schedules.map(
                                          (sched: ScheduleList, schIndex) => {
                                            return (
                                              <div
                                                className="sec_measurement"
                                                style={{
                                                  border: "5px solid rgb(204, 204, 204)",
                                                  padding: "10px",
                                                  borderRadius: "10px",
                                                  width: "99%",
                                                  margin: "auto"
                                                }}
                                                key={schIndex}
                                              >
                                                <Accordion defaultActiveKey="0">
                                                  <Card style={{ borderRadius: "10px" }}>
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.collapsearray(sched, schIndex)} className="card-border-c-green">
                                                      <div className="row">
                                                        <div className="col-md-9" style={{ paddingTop: "6px" }}>
                                                          <h2>
                                                            {sched.collapseid ? <i className="fa fa-angle-down rotate-icon"></i> : <i className="fa fa-angle-up rotate-icon"></i>}&nbsp;&nbsp;
                                                            Schedule - {schIndex + 1}
                                                          </h2>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <button style={{
                                                            float: "right",
                                                            marginTop: "2px",
                                                            paddingTop: "8px"
                                                          }}
                                                            type="button"
                                                            className="btn btn-icon btn-rounded btn-danger"
                                                            disabled={
                                                              this.state.schedules
                                                                .length === 1
                                                            }
                                                            onClick={() =>
                                                              this.removeItem(
                                                                sched,
                                                                schIndex
                                                              )
                                                            }
                                                          >
                                                            <i className="feather icon-minus"></i>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                      <Card.Body>
                                                        <div className="row" style={{
                                                          marginTop: "-9px"
                                                        }}>
                                                          <div className="col-md-12">
                                                            <div className="form-group">
                                                              <label>
                                                                Nature of property<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Eg. Agri / Existing Building / Under Construction / Vacant Land / Appartment</span></label>
                                                              {this.state.nature_docs && this.state.nature_docs.length > 0 && this.state.nature_docs.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                                                <Select options={this.state.nature_docs} name="nature_doc"
                                                                  className={this.state.required && sched.nature_doc === '' ? 'form-select is-invalid' : 'form-select'}
                                                                  value={this.state.nature_docs.find((o: any) => o.value === sched.nature_doc)}
                                                                  onChange={(e) => this.handleChange(e, schIndex, 'nature_doc', 'singleselect')}
                                                                />
                                                              }
                                                              <div className="invalid-feedback">This is a required field, kindly add Total Extent here.</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {sched.nature_doc ===
                                                          "Existing building" && (
                                                            <div className="row">
                                                              <div className="col-md-12">
                                                                <div className="form-group">
                                                                  <label>Door No</label>
                                                                  <input
                                                                    className="form-control"
                                                                    placeholder="Maximum 100 Characters"
                                                                    id="door_no"
                                                                    name="door_no"
                                                                    type="text"
                                                                    value={sched.door_no}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                    required
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )}
                                                        {sched.nature_doc ===
                                                          "appartment" && (
                                                            <div className="row">
                                                              <div className="col-md-6">
                                                                <div className="form-group">
                                                                  <label>Flat No<span style={{ color: "#FF0000" }}>*</span></label>
                                                                  <input
                                                                    className={this.state.required && sched.flat_no === '' ? 'form-control is-invalid' : 'form-control'}
                                                                    id="flat_no"
                                                                    name="flat_no"
                                                                    type="text"
                                                                    value={sched.flat_no}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                  />
                                                                  <div className="invalid-feedback">This is a required field, kindly add Flat Number here.</div>
                                                                </div>
                                                              </div>
                                                              <div className="col-md-6">
                                                                <div className="form-group">
                                                                  <label>Door No</label>
                                                                  <input
                                                                    className="form-control"
                                                                    id="door_no"
                                                                    name="door_no"
                                                                    type="text"
                                                                    value={sched.door_no}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                    required
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="col-md-6">
                                                                <div className="form-group">
                                                                  <label>Floor No<span style={{ color: "#FF0000" }}>*</span></label>
                                                                  <input
                                                                    className={this.state.required && sched.floor === '' ? 'form-control is-invalid' : 'form-control'}
                                                                    id="floor"
                                                                    name="floor"
                                                                    type="text"
                                                                    value={sched.floor}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                  />
                                                                  <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                                                                </div>
                                                              </div>
                                                              <div className="col-md-6">
                                                                <div className="form-group">
                                                                  <label>
                                                                    Apartment Name
                                                                    {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                                                  </label>
                                                                  <input
                                                                    className={'form-control'}
                                                                    name="appt_name"
                                                                    type="text"
                                                                    value={sched.appt_name}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                  />
                                                                  {/* <div className="invalid-feedback">This is a required field, kindly add Apartment Name here.</div> */}
                                                                </div>
                                                              </div>
                                                              <div className="col-md-3">
                                                                <div className="form-group">
                                                                  <label>Builtup Area<span style={{ color: "#FF0000" }}>*</span></label>
                                                                  <input
                                                                    className={this.state.required && sched.buildup === '' ? 'form-control is-invalid' : 'form-control'}
                                                                    name="buildup"
                                                                    type="text"
                                                                    value={sched.buildup}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                  />
                                                                  <div className="invalid-feedback">This is a required field, kindly add Builtup Area here.</div>
                                                                </div>
                                                              </div>
                                                              <div className="col-md-3">
                                                                <div style={{
                                                                  padding: "0px",
                                                                  border: "none"
                                                                }}>
                                                                  <label style={{ width: "300px" }}>Select Unit<span style={{ color: "#FF0000" }}>*</span> <sup>(Eg.43 Sq.Meter Etc...)</sup></label>
                                                                  {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                                                    <Select options={this.state.extentUnits} name="builtup_unit"
                                                                      style={{
                                                                        padding: "0px",
                                                                        border: "none",
                                                                        background: "transparent",
                                                                        margin: "-7px"
                                                                      }}
                                                                      className={this.state.required && sched.builtup_unit === '' ? 'form-select is-invalid' : 'form-select'}
                                                                      value={this.state.extentUnits.find((o: any) => o.value === sched.builtup_unit)}
                                                                      onChange={(e) => this.handleChange(e, schIndex, "builtup_unit", 'singleselect')}
                                                                    />
                                                                  }
                                                                  <div className="invalid-feedback">This is a required field</div>
                                                                </div>
                                                              </div>
                                                              <div className="col-md-3">
                                                                <div className="form-group">
                                                                  <label>
                                                                    UDS<span style={{ color: "#FF0000" }}>*</span>
                                                                  </label>
                                                                  <input
                                                                    className={this.state.required && sched.uds === '' ? 'form-control is-invalid' : 'form-control'}
                                                                    name="uds"
                                                                    type="text"
                                                                    value={sched.uds}
                                                                    onChange={e =>
                                                                      this.handleChange(
                                                                        e,
                                                                        schIndex
                                                                      )
                                                                    }
                                                                  />
                                                                  <div className="invalid-feedback">This is a required field</div>
                                                                </div>
                                                              </div>
                                                              <div className="col-md-3">
                                                                <div className="form-group">
                                                                  <label style={{ width: "300px" }}>Select Unit<span style={{ color: "#FF0000" }}>*</span> <sup>(Eg.43 Sq.Meter Etc...)</sup></label>
                                                                  {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                                                    <Select options={this.state.extentUnits} name="uds_unit"
                                                                      className={this.state.required && sched.uds_unit === '' ? 'form-select is-invalid' : 'form-select'}
                                                                      value={this.state.extentUnits.find((o: any) => o.value === sched.uds_unit)}
                                                                      onChange={(e) => this.handleChange(e, schIndex, "uds_unit", 'singleselect')}
                                                                    />
                                                                  }
                                                                  <div className="invalid-feedback">This is a required field</div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )}
                                                        <SurveyItem
                                                          stateToProps={e =>
                                                            this.stateToProps(e, schIndex)
                                                          }
                                                          surveyDetail={
                                                            sched.survey_details
                                                          }
                                                          extentUnits={this.state.extentUnits}
                                                          required={this.state.required}
                                                        />
                                                        <div className="row">
                                                          <div className="col-md-12">
                                                            <div className="form-group">
                                                              <label>
                                                                Address<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">location, village,
                                                                  city, registration, sub -
                                                                  district etc</span>
                                                              </label>
                                                              <textarea
                                                                placeholder="Eg.location, village,city, registration, sub-district etc"
                                                                className={this.state.required && sched.address === '' ? 'form-control is-invalid' : 'form-control'}
                                                                name="address"
                                                                id="address"
                                                                value={sched.address}
                                                                onChange={e =>
                                                                  this.handleChange(
                                                                    e,
                                                                    schIndex
                                                                  )
                                                                }
                                                                aria-required="true"
                                                              />
                                                              <div className="invalid-feedback">This is a required field, kindly add property Address here.</div>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <div className="form-group">
                                                              <label>Remark<span style={{ color: "#FF0000" }}>*</span></label>
                                                              <textarea
                                                                className={this.state.required && sched.remark === '' ? 'form-control is-invalid' : 'form-control'}
                                                                name="remark"
                                                                id="remark"
                                                                placeholder="Remarks display in Scheduled of Property info card"
                                                                value={sched.remark}
                                                                onChange={e => this.handleChange(e, schIndex)}
                                                              />
                                                              <div className="invalid-feedback">This is a required field, kindly add your Remarks here.</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* <MeasurementItem
                                              stateToProps={e =>
                                                this.stateToProps(e, schIndex)
                                              }
                                              measurementDetail={
                                                sched.measure_details
                                              }
                                            /> */}
                                                        {/* <MeasureLabelItem
                                              stateToProps={e =>
                                                this.stateToProps(e, schIndex)
                                              }
                                              measurementDetail={
                                                sched.measurement_list
                                              }
                                            /> */}
                                                      </Card.Body>
                                                    </Accordion.Collapse>
                                                  </Card>
                                                </Accordion>
                                              </div>
                                            );
                                          }
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Resizable>
                          </div>
                        </div>
                        <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                          <div
                            className="btn-group mr-2 sw-btn-group"
                            role="group"
                          >
                            <button
                              className="btn mt-2 btn-warning sw-btn-next"
                              type="button"
                              onClick={() => {
                                isVerifyFlow ? window.close() : this.props.history.push("/work-file/1/" + this.props.match.params.id);
                              }}
                            >
                              Back
                            </button>
                          </div>
                          {!isVerifyFlow ?
                            <div
                              className="btn-group mr-2 sw-btn-group"
                              role="group"
                            >
                              <a href="#previewTable">
                                {this.state.autoAssign && this.state.autoAssign.find(
                                  (data: any) => data.module_name === "Auto Assign" && data.status) ?
                                  <>
                                    {takenfile == currentfile ? (
                                      <>
                                        <button
                                          style={{ marginTop: "8px" }}
                                          className="btn btn-primary sw-btn-next"
                                          type="button"
                                          onClick={() => this.saveGeneralDoc(1)}
                                          disabled={this.state.isLoading}>
                                          {this.state.isLoading && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                          )}
                                          Save
                                        </button>
                                      </>) :
                                      <>
                                        {roletype == 8 ? <>
                                          <button
                                            style={{ marginTop: "8px" }}
                                            className="btn btn-success sw-btn-next"
                                            type="button"
                                            onClick={() => this.saveGeneralDoc(1)}
                                            disabled={this.state.isLoading}>
                                            {this.state.isLoading && (
                                              <i className="fa fa-spinner fa-spin"></i>
                                            )}
                                            Save
                                          </button></> : ""}
                                      </>}
                                  </> :
                                  <>
                                    <button
                                      style={{ marginTop: "8px" }}
                                      className="btn btn-primary sw-btn-next"
                                      type="button"
                                      onClick={() => this.saveGeneralDoc(1)}
                                      disabled={this.state.isLoading}>
                                      {this.state.isLoading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  </>}
                              </a>
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showTable == true && this.state.natureDocN == "Agri" && (
              <><div style={{
                paddingTop: "11px",
                paddingBottom: "15px"
              }} className="card">
                <b><h2 style={{ textAlign: "center", fontSize: "34px !important", fontWeight: "unset" }}>Agri</h2></b>
                {this.state.surveyDetailsTD &&
                  this.state.surveyDetailsTD.length
                  ? this.state.surveyDetailsTD.map(
                    (sched: any, schIndex) => {
                      return (
                        <table id="previewTable" style={{
                          width: "70%",
                          margin: "auto"
                        }} className="table table-striped table-bordered nowrap">
                          <tr className="query-table-row cursor-pointer noBoxShadow">
                            <td className="text-align-center padding4">i</td>
                            <td className="padding4"></td>
                            {sched.survey_type == "survey_no" && (
                              <td className="padding4">Survey No.</td>
                            )}
                            {sched.survey_type == "ts_no" && (
                              <td className="padding4">TS No.</td>
                            )} {sched.survey_type == "natham_survey_no" && (
                              <td className="padding4">Natham Survey No.</td>
                            )}
                            <td className="padding4">As per new Patta</td>
                            <td className="padding4">Extent</td>
                          </tr>
                          <tr className="query-table-row cursor-pointer noBoxShadow">
                            <td className="padding4"></td>
                            <td className="padding4"></td>
                            {sched.survey_type == "survey_no" && sched.old_survey_no != "" && (
                              <td className="padding4">Old S.No- {sched.old_survey_no}, After Sub Division New S.No- {sched.survey_no}</td>
                            )}
                            {sched.survey_type == "survey_no" && sched.old_survey_no == "" && (
                              <td className="padding4">S.No- {sched.survey_no}</td>
                            )}
                            {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no != "" && (
                              <td className="padding4">Old Natham S.No-{sched.old_natham_survey_no}, New Natham S.No- {sched.natham_survey_no}</td>
                            )}
                            {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no == "" && (
                              <td className="padding4">Natham S.No- {sched.natham_survey_no}</td>
                            )}
                            {sched.survey_type == "ts_no" && sched.old_ts_no != "" && (
                              <td className="padding4">Old TS No-{sched.old_ts_no}, New TS No-{sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                            )}
                            {sched.survey_type == "ts_no" && sched.old_ts_no == "" && (
                              <td className="padding4">TS No- {sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                            )}
                            <td className="padding4">{sched.revenue_record}</td>
                            <td className="padding4">{sched.extent} {sched.sur_extent_unit}</td>
                          </tr>
                          <tr className="query-table-row cursor-pointer noBoxShadow">
                            <td className="padding4"></td>
                            <td className="padding4"></td>
                            <td className="padding4"></td>
                            <td className="padding4">Total Extent</td>
                            <td className="padding4">{sched.extent} {sched.sur_extent_unit}</td>
                          </tr>
                          <tr className="query-table-row cursor-pointer noBoxShadow">
                            <td className="text-align-center padding4">ii</td>
                            <td colSpan={2} className="padding4">Location like name of the place,
                              village, city, registration, sub- district
                              etc.</td>
                            <td colSpan={2} className="padding4">{this.state.address}</td>
                          </tr>
                          <tr className="query-table-row cursor-pointer noBoxShadow">
                            <td className="brdrClr">iii</td>
                            <td className="brdrClr" colSpan={4}>
                              {this.state.boundary &&
                                this.state.boundary.length
                                ? this.state.boundary.map(
                                  (schedB: any, schIndex: 0) => {
                                    return (
                                      <><b className="textUnderline">{schIndex + 1}.Boundaries for {schedB.land_aries} {schedB.unit} of land</b><br></br>
                                        <b className="textUnderline">North {schedB.direction}</b> - {schedB.north}<br></br>
                                        <b className="textUnderline">South {schedB.direction}</b> - {schedB.south}<br></br>
                                        <b className="textUnderline">East {schedB.direction}</b> - {schedB.east}<br></br>
                                        <b className="textUnderline">West {schedB.direction}</b> - {schedB.west}<br></br>
                                        {this.state.measureList && this.state.measureList.length
                                          ? this.state.measureList.map(
                                            (dirLabel: any, index) => {
                                              return (
                                                <>
                                                  {dirLabel.meas_dir_list && dirLabel.meas_dir_list.length > 0 && dirLabel.meas_dir_list[0].label_name != null && (
                                                    <><b><h4 style={{
                                                      textAlign: "center",
                                                      fontSize: "34px !important",
                                                      fontWeight: "unset"
                                                    }}>Measurement Details</h4></b><table>
                                                        {this.state.measDirList && this.state.measDirList.length
                                                          ? this.state.measDirList.map(
                                                            (dir: any) => {
                                                              return (
                                                                <>

                                                                  <tr style={{
                                                                    margin: "auto",
                                                                    display: "table",
                                                                    width: "100%"
                                                                  }} className="query-table-row cursor-pointer noBoxShadow">
                                                                    {dir.label != "8" &&
                                                                      <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.label_name}</td>}
                                                                    {dir.label == "8" &&
                                                                      <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.measure_custom}</td>}
                                                                    <td className="padding4" style={{ width: "40%", textAlign: "center" }}>{dir.value} {dirLabel.l_unit}</td>
                                                                  </tr>

                                                                </>
                                                              );
                                                            }
                                                          )
                                                          : ""}
                                                      </table>
                                                      <h6 style={{ paddingTop: "14px" }}>With all easements Rights and Pathway</h6>
                                                    </>
                                                  )}
                                                </>

                                              );
                                            }

                                          )
                                          : ""}
                                      </>
                                    );
                                  }
                                )
                                : ""}
                            </td>
                          </tr>
                        </table>
                      );
                    }
                  )
                  : ""}
                <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                  <div
                    className="btn-group mr-2 sw-btn-group"
                    role="group"
                  >
                    <button
                      className="btn mt-2 btn-warning sw-btn-next"
                      type="button"
                      onClick={() => {
                        isVerifyFlow ? window.close() : this.props.history.push("/work-file/1/" + this.props.match.params.id);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div
                    className="btn-group mr-2 mt-2 sw-btn-group"
                    role="group">
                    {this.state.autoAssign && this.state.autoAssign.find(
                      (data: any) => data.module_name === "Auto Assign" && data.status) ?
                      <>
                        {takenfile == currentfile ? (
                          <>
                            <button
                              className="btn btn-success sw-btn-next"
                              type="button"
                              onClick={() => this.saveGeneralDoc(2)}
                              disabled={this.state.isLoading}>
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save & Submit
                            </button>
                          </>
                        ) : <>
                          {roletype == 8 ? <>
                            <button
                              className="btn btn-success sw-btn-next"
                              type="button"
                              onClick={() => this.saveGeneralDoc(2)}
                              disabled={this.state.isLoading}>
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save & Submit
                            </button></> : ""}
                        </>}
                      </> :
                      <>
                        <button
                          className="btn btn-success sw-btn-next"
                          type="button"
                          onClick={() => this.saveGeneralDoc(2)}
                          disabled={this.state.isLoading}>
                          {this.state.isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Save & Submit
                        </button>
                      </>}
                  </div>
                </div>
              </div>
              </>
            )}
            {this.state.showTable == true && this.state.natureDocN == "Existing building" && (
              <><div style={{
                paddingTop: "11px",
                paddingBottom: "15px"
              }} className="card">
                <b><h2 style={{ textAlign: "center", fontSize: "34px !important", fontWeight: "unset" }}>Existing building</h2></b>
                {this.state.schedules &&
                  this.state.schedules.length ? this.state.schedules.map(
                    (schList: any, schListIndex) => {
                      return (
                        <> {this.state.surveyDetailsTD &&
                          this.state.surveyDetailsTD.length
                          ? this.state.surveyDetailsTD.map(
                            (sched: any, schIndex) => {
                              return (
                                <table id="previewTable" style={{
                                  width: "70%",
                                  margin: "auto"
                                }} className="table table-striped table-bordered nowrap">
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">i</td>
                                    {sched.survey_type == "survey_no" && (
                                      <td className="padding4">Survey No.</td>
                                    )}
                                    {sched.survey_type == "ts_no" && (
                                      <td className="padding4">TS No.</td>
                                    )} {sched.survey_type == "natham_survey_no" && (
                                      <td className="padding4">Natham Survey No.</td>
                                    )}
                                    {sched.survey_type == "survey_no" && sched.old_survey_no != "" && (
                                      <td className="padding4">Old S.No- {sched.old_survey_no}, After Sub Division New S.No- {sched.survey_no}</td>
                                    )}
                                    {sched.survey_type == "survey_no" && sched.old_survey_no == "" && (
                                      <td className="padding4">S.No- {sched.survey_no}</td>
                                    )}
                                    {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no != "" && (
                                      <td className="padding4">Old Natham S.No-{sched.old_natham_survey_no}, New Natham S.No- {sched.natham_survey_no}</td>
                                    )}
                                    {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no == "" && (
                                      <td className="padding4">Natham S.No- {sched.natham_survey_no}</td>
                                    )}
                                    {sched.survey_type == "ts_no" && sched.old_ts_no != "" && (
                                      <td className="padding4">Old TS No-{sched.old_ts_no}, New TS No-{sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                    )}
                                    {sched.survey_type == "ts_no" && sched.old_ts_no == "" && (
                                      <td className="padding4">TS No- {sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                    )}
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">ii</td>
                                    <td className="padding4">As per Revenue Record</td>
                                    <td className="padding4">{sched.revenue_record}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">iii</td>
                                    <td className="padding4">Total Extent</td>
                                    <td className="padding4">{sched.extent} {sched.sur_extent_unit}</td>
                                  </tr>
                                  {this.state.showdoor_no == true &&
                                    <>
                                      <tr className="query-table-row cursor-pointer noBoxShadow">
                                        <td className="text-align-center padding4">iv</td>
                                        <td className="padding4">Door No (As per property tax)</td>
                                        <td className="padding4">{schList.door_no}</td>
                                      </tr>
                                    </>
                                  }
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    {this.state.showdoor_no == true ? <>
                                      <td className="text-align-center padding4">v</td>
                                    </> : <>
                                      <td className="text-align-center padding4">iv</td>
                                    </>}
                                    <td className="padding4">Location like name of the place,
                                      village, city, registration, sub- district
                                      etc.</td>
                                    <td className="padding4">{this.state.address}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    {this.state.showdoor_no == true ? <>
                                      <td className="text-align-center padding4">vi</td>
                                    </> : <>
                                      <td className="brdrClr">v</td>
                                    </>}

                                    <td className="brdrClr" colSpan={2}>
                                      {this.state.boundary &&
                                        this.state.boundary.length
                                        ? this.state.boundary.map(
                                          (schedB: any, schIndex: 0) => {
                                            return (
                                              <><b className="textUnderline">{schIndex + 1}.Boundaries for {schedB.land_aries} {schedB.unit} of land</b><br></br>
                                                <b className="textUnderline">North {schedB.direction}</b> - {schedB.north}<br></br>
                                                <b className="textUnderline">South {schedB.direction}</b> - {schedB.south}<br></br>
                                                <b className="textUnderline">East {schedB.direction}</b> - {schedB.east}<br></br>
                                                <b className="textUnderline">West {schedB.direction}</b> - {schedB.west}<br></br>
                                                {this.state.measureList && this.state.measureList.length
                                                  ? this.state.measureList.map(
                                                    (dirLabel: any, index) => {
                                                      return (
                                                        <>
                                                          {dirLabel.meas_dir_list && dirLabel.meas_dir_list.length > 0 && dirLabel.meas_dir_list[0].label_name != null && (
                                                            <><b><h4 style={{
                                                              textAlign: "center",
                                                              fontSize: "34px !important",
                                                              fontWeight: "unset"
                                                            }}>Measurement Details</h4></b><table>
                                                                {this.state.measDirList && this.state.measDirList.length
                                                                  ? this.state.measDirList.map(
                                                                    (dir: any) => {
                                                                      return (
                                                                        <>

                                                                          <tr style={{
                                                                            margin: "auto",
                                                                            display: "table",
                                                                            width: "100%"
                                                                          }} className="query-table-row cursor-pointer noBoxShadow">
                                                                            {dir.label != "8" &&
                                                                              <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.label_name}</td>}
                                                                            {dir.label == "8" &&
                                                                              <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.measure_custom}</td>}
                                                                            <td className="padding4" style={{ width: "40%", textAlign: "center" }}>{dir.value} {dirLabel.l_unit}</td>
                                                                          </tr>

                                                                        </>
                                                                      );
                                                                    }
                                                                  )
                                                                  : ""}
                                                              </table>
                                                              <h6 style={{ paddingTop: "14px" }}>With all easements Rights and Pathway</h6>
                                                            </>
                                                          )}
                                                        </>

                                                      );
                                                    }

                                                  )
                                                  : ""}
                                              </>
                                            );
                                          }
                                        )
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              );
                            }
                          )
                          : ""}</>
                      );
                    }) : ""}
                <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                  <div
                    className="btn-group mr-2 sw-btn-group"
                    role="group"
                  >
                    <button
                      className="btn mt-2 btn-warning sw-btn-next"
                      type="button"
                      onClick={() => {
                        isVerifyFlow ? window.close() : this.props.history.push("/work-file/1/" + this.props.match.params.id);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div
                    className="btn-group mr-2 mt-2 sw-btn-group"
                    role="group">
                    {this.state.autoAssign && this.state.autoAssign.find(
                      (data: any) => data.module_name === "Auto Assign" && data.status) ?
                      <>
                        {takenfile == currentfile ? (
                          <>
                            <button
                              className="btn btn-success sw-btn-next"
                              type="button"
                              onClick={() => this.saveGeneralDoc(2)}
                              disabled={this.state.isLoading}>
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save & Submit
                            </button>
                          </>) :
                          <>
                            {roletype == 8 ? <>
                              <button
                                className="btn btn-success sw-btn-next"
                                type="button"
                                onClick={() => this.saveGeneralDoc(2)}
                                disabled={this.state.isLoading}>
                                {this.state.isLoading && (
                                  <i className="fa fa-spinner fa-spin"></i>
                                )}
                                Save & Submit
                              </button></> : ""}
                          </>}
                      </> :
                      <>
                        <button
                          className="btn btn-success sw-btn-next"
                          type="button"
                          onClick={() => this.saveGeneralDoc(2)}
                          disabled={this.state.isLoading}>
                          {this.state.isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Save & Submit
                        </button>
                      </>}
                  </div>
                </div>
              </div>
              </>
            )}
            {this.state.showTable == true && this.state.natureDocN == "Under construction" && (
              <div style={{
                paddingTop: "11px",
                paddingBottom: "15px"
              }} className="card">
                <b><h2 style={{ textAlign: "center", fontSize: "34px !important", fontWeight: "unset" }}>Under construction</h2></b>
                {this.state.schedules &&
                  this.state.schedules.length ? this.state.schedules.map(
                    (schList: any, schListIndex) => {
                      return (
                        <>{this.state.surveyDetailsTD &&
                          this.state.surveyDetailsTD.length
                          ? this.state.surveyDetailsTD.map(
                            (sched: any, schIndex) => {
                              return (
                                <table id="previewTable" style={{
                                  width: "70%",
                                  margin: "auto"
                                }} className="table table-striped table-bordered nowrap">
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">i</td>
                                    {sched.survey_type == "survey_no" && (
                                      <td className="padding4">Survey No.</td>
                                    )}
                                    {sched.survey_type == "ts_no" && (
                                      <td className="padding4">TS No.</td>
                                    )} {sched.survey_type == "natham_survey_no" && (
                                      <td className="padding4">Natham Survey No.</td>
                                    )}
                                    {sched.survey_type == "survey_no" && sched.old_survey_no != "" && (
                                      <td className="padding4">Old S.No- {sched.old_survey_no}, After Sub Division New S.No- {sched.survey_no}</td>
                                    )}
                                    {sched.survey_type == "survey_no" && sched.old_survey_no == "" && (
                                      <td className="padding4">S.No- {sched.survey_no}</td>
                                    )}
                                    {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no != "" && (
                                      <td className="padding4">Old Natham S.No-{sched.old_natham_survey_no}, New Natham S.No- {sched.natham_survey_no}</td>
                                    )}
                                    {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no == "" && (
                                      <td className="padding4">Natham S.No- {sched.natham_survey_no}</td>
                                    )}
                                    {sched.survey_type == "ts_no" && sched.old_ts_no != "" && (
                                      <td className="padding4">Old TS No-{sched.old_ts_no}, New TS No-{sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                    )}
                                    {sched.survey_type == "ts_no" && sched.old_ts_no == "" && (
                                      <td className="padding4">TS No- {sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                    )}
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">ii</td>
                                    <td className="padding4">As per Revenue Record</td>
                                    <td className="padding4">{sched.revenue_record}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">iii</td>
                                    <td className="padding4">Total Extent</td>
                                    <td className="padding4">{sched.extent} {sched.sur_extent_unit}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">iv</td>
                                    <td className="padding4">Door No (As per property tax)</td>
                                    <td className="padding4">{schList.door_no}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">v</td>
                                    <td className="padding4">Location like name of the place,
                                      village, city, registration, sub- district
                                      etc.</td>
                                    <td className="padding4">{this.state.address}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="brdrClr">vi</td>
                                    <td className="brdrClr" colSpan={2}>
                                      {this.state.boundary &&
                                        this.state.boundary.length
                                        ? this.state.boundary.map(
                                          (schedB: any, schIndex: 0) => {
                                            return (
                                              <><b className="textUnderline">{schIndex + 1}. Boundaries for {schedB.land_aries} {schedB.unit} of land</b><br></br>
                                                <b className="textUnderline">North {schedB.direction}</b> - {schedB.north}<br></br>
                                                <b className="textUnderline">South {schedB.direction}</b> - {schedB.south}<br></br>
                                                <b className="textUnderline">East {schedB.direction}</b> - {schedB.east}<br></br>
                                                <b className="textUnderline">West {schedB.direction}</b> - {schedB.west}<br></br>
                                                {this.state.measureList && this.state.measureList.length
                                                  ? this.state.measureList.map(
                                                    (dirLabel: any, index) => {
                                                      return (
                                                        <>
                                                          {dirLabel.meas_dir_list && dirLabel.meas_dir_list.length > 0 && dirLabel.meas_dir_list[0].label_name != null && (
                                                            <><b><h4 style={{
                                                              textAlign: "center",
                                                              fontSize: "34px !important",
                                                              fontWeight: "unset"
                                                            }}>Measurement Details</h4></b><table>
                                                                {this.state.measDirList && this.state.measDirList.length
                                                                  ? this.state.measDirList.map(
                                                                    (dir: any) => {
                                                                      return (
                                                                        <>

                                                                          <tr style={{
                                                                            margin: "auto",
                                                                            display: "table",
                                                                            width: "100%"
                                                                          }} className="query-table-row cursor-pointer noBoxShadow">
                                                                            {dir.label != "8" &&
                                                                              <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.label_name}</td>}
                                                                            {dir.label == "8" &&
                                                                              <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.measure_custom}</td>}
                                                                            <td className="padding4" style={{ width: "40%", textAlign: "center" }}>{dir.value} {dirLabel.l_unit}</td>
                                                                          </tr>

                                                                        </>
                                                                      );
                                                                    }
                                                                  )
                                                                  : ""}
                                                              </table>
                                                              <h6 style={{ paddingTop: "14px" }}>With all easements Rights and Pathway</h6>
                                                            </>
                                                          )}
                                                        </>

                                                      );
                                                    }

                                                  )
                                                  : ""}
                                              </>
                                            );
                                          }
                                        )
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              );
                            }
                          )
                          : ""}</>
                      );
                    }) : ""}
                <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                  <div
                    className="btn-group mr-2 sw-btn-group"
                    role="group"
                  >
                    <button
                      className="btn mt-2 btn-warning sw-btn-next"
                      type="button"
                      onClick={() => {
                        isVerifyFlow ? window.close() : this.props.history.push("/work-file/1/" + this.props.match.params.id);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div
                    className="btn-group mr-2 mt-2 sw-btn-group"
                    role="group">
                    {this.state.autoAssign && this.state.autoAssign.find(
                      (data: any) => data.module_name === "Auto Assign" && data.status) ?
                      <>
                        {takenfile == currentfile ? (
                          <>
                            <button
                              className="btn btn-success sw-btn-next"
                              type="button"
                              onClick={() => this.saveGeneralDoc(2)}
                              disabled={this.state.isLoading}>
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save & Submit
                            </button>
                          </>) :
                          <>
                            {roletype == 8 ? <>
                              <button
                                className="btn btn-success sw-btn-next"
                                type="button"
                                onClick={() => this.saveGeneralDoc(2)}
                                disabled={this.state.isLoading}>
                                {this.state.isLoading && (
                                  <i className="fa fa-spinner fa-spin"></i>
                                )}
                                Save & Submit
                              </button></> : ""}
                          </>}
                      </> :
                      <>
                        <button
                          className="btn btn-success sw-btn-next"
                          type="button"
                          onClick={() => this.saveGeneralDoc(2)}
                          disabled={this.state.isLoading}>
                          {this.state.isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Save & Submit
                        </button>
                      </>}
                  </div>
                </div>
              </div>
            )}
            {this.state.showTable == true && this.state.natureDocN == "vacant land" && (
              <div style={{
                paddingTop: "11px",
                paddingBottom: "15px"
              }} className="card">
                <b><h2 style={{ textAlign: "center", fontSize: "34px !important", fontWeight: "unset" }}>Vacant land</h2></b>
                {this.state.schedules &&
                  this.state.schedules.length ? this.state.schedules.map(
                    (schList: any, schListIndex) => {
                      return (
                        <>{this.state.surveyDetailsTD &&
                          this.state.surveyDetailsTD.length
                          ? this.state.surveyDetailsTD.map(
                            (sched: any, schIndex) => {
                              return (
                                <table id="previewTable" style={{
                                  width: "70%",
                                  margin: "auto"
                                }} className="table table-striped table-bordered nowrap">
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">i</td>
                                    {sched.survey_type == "survey_no" && (
                                      <td className="padding4">Survey No.</td>
                                    )}
                                    {sched.survey_type == "ts_no" && (
                                      <td className="padding4">TS No.</td>
                                    )} {sched.survey_type == "natham_survey_no" && (
                                      <td className="padding4">Natham Survey No.</td>
                                    )}
                                    {sched.survey_type == "survey_no" && sched.old_survey_no != "" && (
                                      <td className="padding4">Old S.No- {sched.old_survey_no}, After Sub Division New S.No- {sched.survey_no}</td>
                                    )}
                                    {sched.survey_type == "survey_no" && sched.old_survey_no == "" && (
                                      <td className="padding4">S.No- {sched.survey_no}</td>
                                    )}
                                    {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no != "" && (
                                      <td className="padding4">Old Natham S.No-{sched.old_natham_survey_no}, New Natham S.No- {sched.natham_survey_no}</td>
                                    )}
                                    {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no == "" && (
                                      <td className="padding4">Natham S.No- {sched.natham_survey_no}</td>
                                    )}
                                    {sched.survey_type == "ts_no" && sched.old_ts_no != "" && (
                                      <td className="padding4">Old TS No-{sched.old_ts_no}, New TS No-{sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                    )}
                                    {sched.survey_type == "ts_no" && sched.old_ts_no == "" && (
                                      <td className="padding4">TS No- {sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                    )}
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">ii</td>
                                    <td className="padding4">As per Revenue Record</td>
                                    <td className="padding4">{sched.revenue_record}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">iii</td>
                                    <td className="padding4">Total Extent</td>
                                    <td className="padding4">{sched.extent} {sched.sur_extent_unit}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">iv</td>
                                    <td className="padding4">Door No (As per property tax)</td>
                                    <td className="padding4">{schList.door_no}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="text-align-center padding4">v</td>
                                    <td className="padding4">Location like name of the place,
                                      village, city, registration, sub- district
                                      etc.</td>
                                    <td className="padding4">{this.state.address}</td>
                                  </tr>
                                  <tr className="query-table-row cursor-pointer noBoxShadow">
                                    <td className="brdrClr">vi</td>
                                    <td className="brdrClr" colSpan={2}>
                                      {this.state.boundary &&
                                        this.state.boundary.length
                                        ? this.state.boundary.map(
                                          (schedB: any, schIndex: 0) => {
                                            return (
                                              <><b className="textUnderline">{schIndex + 1}. Boundaries for {schedB.land_aries} {schedB.unit} of land</b><br></br>
                                                <b className="textUnderline">North {schedB.direction}</b> - {schedB.north}<br></br>
                                                <b className="textUnderline">South {schedB.direction}</b> - {schedB.south}<br></br>
                                                <b className="textUnderline">East {schedB.direction}</b> - {schedB.east}<br></br>
                                                <b className="textUnderline">West {schedB.direction}</b> - {schedB.west}<br></br>
                                                {this.state.measureList && this.state.measureList.length
                                                  ? this.state.measureList.map(
                                                    (dirLabel: any, index) => {
                                                      return (
                                                        <>
                                                          {dirLabel.meas_dir_list && dirLabel.meas_dir_list.length > 0 && dirLabel.meas_dir_list[0].label_name != null && (
                                                            <><b><h4 style={{
                                                              textAlign: "center",
                                                              fontSize: "34px !important",
                                                              fontWeight: "unset"
                                                            }}>Measurement Details</h4></b><table>
                                                                {this.state.measDirList && this.state.measDirList.length
                                                                  ? this.state.measDirList.map(
                                                                    (dir: any) => {
                                                                      return (
                                                                        <>

                                                                          <tr style={{
                                                                            margin: "auto",
                                                                            display: "table",
                                                                            width: "100%"
                                                                          }} className="query-table-row cursor-pointer noBoxShadow">
                                                                            {dir.label != "8" &&
                                                                              <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.label_name}</td>}
                                                                            {dir.label == "8" &&
                                                                              <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.measure_custom}</td>}
                                                                            <td className="padding4" style={{ width: "40%", textAlign: "center" }}>{dir.value} {dirLabel.l_unit}</td>
                                                                          </tr>

                                                                        </>
                                                                      );
                                                                    }
                                                                  )
                                                                  : ""}
                                                              </table>
                                                              <h6 style={{ paddingTop: "14px" }}>With all easements Rights and Pathway</h6>
                                                            </>
                                                          )}
                                                        </>

                                                      );
                                                    }

                                                  )
                                                  : ""}
                                              </>
                                            );
                                          }
                                        )
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              );
                            }
                          )
                          : ""}</>
                      );
                    }) : ""}
                <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                  <div
                    className="btn-group mr-2 sw-btn-group"
                    role="group"
                  >
                    <button
                      className="btn mt-2 btn-warning sw-btn-next"
                      type="button"
                      onClick={() => {
                        isVerifyFlow ? window.close() : this.props.history.push("/work-file/1/" + this.props.match.params.id);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div
                    className="btn-group mr-2 mt-2 sw-btn-group"
                    role="group">
                    {this.state.autoAssign && this.state.autoAssign.find(
                      (data: any) => data.module_name === "Auto Assign" && data.status) ?
                      <>
                        {takenfile == currentfile ? (
                          <>
                            <button
                              className="btn btn-success sw-btn-next"
                              type="button"
                              onClick={() => this.saveGeneralDoc(2)}
                              disabled={this.state.isLoading}>
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save & Submit
                            </button>
                          </>) :
                          <>
                            {roletype == 8 ? <>
                              <button
                                className="btn btn-success sw-btn-next"
                                type="button"
                                onClick={() => this.saveGeneralDoc(2)}
                                disabled={this.state.isLoading}>
                                {this.state.isLoading && (
                                  <i className="fa fa-spinner fa-spin"></i>
                                )}
                                Save & Submit
                              </button></> : ""}
                          </>}
                      </> :
                      <>
                        <button
                          className="btn btn-success sw-btn-next"
                          type="button"
                          onClick={() => this.saveGeneralDoc(2)}
                          disabled={this.state.isLoading}>
                          {this.state.isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Save & Submit
                        </button>
                      </>}
                  </div>
                </div>
              </div>
            )}
            {this.state.showTable == true && this.state.natureDocN == "appartment" && (
              <div style={{
                paddingTop: "11px",
                paddingBottom: "15px"
              }} className="card">
                <b><h2 style={{ textAlign: "center", fontSize: "34px !important", fontWeight: "unset" }}>Apartment</h2></b>
                {this.state.schedules &&
                  this.state.schedules.length ? this.state.schedules.map(
                    (schList: any, schListIndex) => {
                      return (
                        <>
                          {this.state.surveyDetailsTD &&
                            this.state.surveyDetailsTD.length
                            ? this.state.surveyDetailsTD.map(
                              (sched: any, schIndex) => {
                                return (
                                  <table id="previewTable" style={{
                                    width: "70%",
                                    margin: "auto"
                                  }} className="table table-striped table-bordered nowrap">
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="text-align-center padding4">i</td>
                                      {sched.survey_type == "survey_no" && (
                                        <td className="padding4">Survey No.</td>
                                      )}
                                      {sched.survey_type == "ts_no" && (
                                        <td className="padding4">TS No.</td>
                                      )} {sched.survey_type == "natham_survey_no" && (
                                        <td className="padding4">Natham Survey No.</td>
                                      )}
                                      {sched.survey_type == "survey_no" && sched.old_survey_no != "" && (
                                        <td className="padding4">Old S.No- {sched.old_survey_no}, After Sub Division New S.No- {sched.survey_no}</td>
                                      )}
                                      {sched.survey_type == "survey_no" && sched.old_survey_no == "" && (
                                        <td className="padding4">S.No- {sched.survey_no}</td>
                                      )}
                                      {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no != "" && (
                                        <td className="padding4">Old Natham S.No-{sched.old_natham_survey_no}, New Natham S.No- {sched.natham_survey_no}</td>
                                      )}
                                      {sched.survey_type == "natham_survey_no" && sched.old_natham_survey_no == "" && (
                                        <td className="padding4">Natham S.No- {sched.natham_survey_no}</td>
                                      )}
                                      {sched.survey_type == "ts_no" && sched.old_ts_no != "" && (
                                        <td className="padding4">Old TS No-{sched.old_ts_no}, New TS No-{sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                      )}
                                      {sched.survey_type == "ts_no" && sched.old_ts_no == "" && (
                                        <td className="padding4">TS No- {sched.ts_no}, Ward- {sched.ts_ward}, Block- {sched.ts_block}</td>
                                      )}
                                    </tr>
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="padding4"></td>
                                      <td className="padding4">As per Revenue Record</td>
                                      <td className="padding4">{sched.revenue_record}</td>
                                    </tr>
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="text-align-center padding4">ii</td>
                                      <td className="padding4">Total Extent</td>
                                      <td className="padding4">{sched.extent} {sched.sur_extent_unit} in this UDS {schList.uds} {schList.uds_unit}</td>
                                    </tr>
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="text-align-center padding4">iii</td>
                                      <td className="padding4">Door No / Flat No as per builders
                                        Agreement / Floor No / Apartment
                                        Name</td>
                                      <td className="padding4">Door No- {schList.door_no}, Flat No- {schList.flat_no}, Floor No-  {schList.floor}, Apartment name-   {schList.appt_name}</td>
                                    </tr>
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="padding4"></td>
                                      <td className="padding4">Extent area including plinth built up
                                        area in case of house property / as
                                        per building Agreement</td>
                                      <td className="padding4">{schList.buildup} {schList.builtup_unit}- Plinth Area Including common area,</td>
                                    </tr>
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="text-align-center padding4">iv</td>
                                      <td className="padding4">Location like name of the place,
                                        village, city, registration, sub- district
                                        etc.</td>
                                      <td className="padding4">{this.state.address}</td>
                                    </tr>
                                    <tr className="query-table-row cursor-pointer noBoxShadow">
                                      <td className="text-align-center padding4">v</td>
                                      <td className="brdrClr" colSpan={2}>
                                        {this.state.boundary &&
                                          this.state.boundary.length
                                          ? this.state.boundary.map(
                                            (schedB: any, schIndex: 0) => {
                                              return (
                                                <><b className="textUnderline">{schIndex + 1}. Boundaries for {schedB.land_aries} {schedB.unit} of land</b><br></br>
                                                  <b className="textUnderline">North {schedB.direction}</b> - {schedB.north}<br></br>
                                                  <b className="textUnderline">South {schedB.direction}</b> - {schedB.south}<br></br>
                                                  <b className="textUnderline">East {schedB.direction}</b> - {schedB.east}<br></br>
                                                  <b className="textUnderline">West {schedB.direction}</b> - {schedB.west}<br></br>


                                                  {this.state.measureList && this.state.measureList.length
                                                    ? this.state.measureList.map(
                                                      (dirLabel: any, index) => {
                                                        return (
                                                          <>
                                                            {dirLabel.meas_dir_list && dirLabel.meas_dir_list.length > 0 && dirLabel.meas_dir_list[0].label_name != null && (
                                                              <><b><h4 style={{
                                                                textAlign: "center",
                                                                fontSize: "34px !important",
                                                                fontWeight: "unset"
                                                              }}>Measurement Details</h4></b><table>
                                                                  {this.state.measDirList && this.state.measDirList.length
                                                                    ? this.state.measDirList.map(
                                                                      (dir: any) => {
                                                                        return (
                                                                          <>

                                                                            <tr style={{
                                                                              margin: "auto",
                                                                              display: "table",
                                                                              width: "100%"
                                                                            }} className="query-table-row cursor-pointer noBoxShadow">
                                                                              {dir.label != "8" &&
                                                                                <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.label_name}</td>}
                                                                              {dir.label == "8" &&
                                                                                <td className="padding4" style={{ width: "60%", textAlign: "center" }}>{dir.measure_custom}</td>}
                                                                              <td className="padding4" style={{ width: "40%", textAlign: "center" }}>{dir.value} {dirLabel.l_unit}</td>
                                                                            </tr>

                                                                          </>
                                                                        );
                                                                      }
                                                                    )
                                                                    : ""}
                                                                </table>
                                                                <h6 style={{ paddingTop: "14px" }}>With all easements Rights and Pathway</h6>
                                                              </>
                                                            )}
                                                          </>

                                                        );
                                                      }

                                                    )
                                                    : ""}


                                                </>
                                              );
                                            }
                                          )
                                          : ""}
                                      </td>
                                    </tr>
                                  </table>
                                );
                              }
                            )
                            : ""}
                        </>
                      );
                    }) : ""}
                <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                  <div
                    className="btn-group mr-2 sw-btn-group"
                    role="group"
                  >
                    <button
                      className="btn mt-2 btn-warning sw-btn-next"
                      type="button"
                      onClick={() => {
                        isVerifyFlow ? window.close() : this.props.history.push("/work-file/1/" + this.props.match.params.id);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div
                    className="btn-group mr-2 mt-2 sw-btn-group"
                    role="group">
                    {this.state.autoAssign && this.state.autoAssign.find(
                      (data: any) => data.module_name === "Auto Assign" && data.status) ?
                      <>
                        {takenfile == currentfile ? (
                          <>
                            <button
                              className="btn btn-success sw-btn-next"
                              type="button"
                              onClick={() => this.saveGeneralDoc(2)}
                              disabled={this.state.isLoading}>
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save & Submit
                            </button>
                          </>) :
                          <>
                            {roletype == 8 ? <>
                              <button
                                className="btn btn-success sw-btn-next"
                                type="button"
                                onClick={() => this.saveGeneralDoc(2)}
                                disabled={this.state.isLoading}>
                                {this.state.isLoading && (
                                  <i className="fa fa-spinner fa-spin"></i>
                                )}
                                Save & Submit
                              </button></> : ""}
                          </>}
                      </> :
                      <>
                        <button
                          className="btn btn-success sw-btn-next"
                          type="button"
                          onClick={() => this.saveGeneralDoc(2)}
                          disabled={this.state.isLoading}>
                          {this.state.isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Save & Submit
                        </button>
                      </>}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="modal fade"
            id="doc-preview"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Document Preview</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.isDocPreviewLoading && (
                    <div className="spin-container" style={{ width: '55em', height: '30em' }}>
                      <span className="imgLoader" />
                    </div>
                  )}
                  <iframe width="100%" height="400" frameBorder="0" src={this.state.docReportForPreview}></iframe>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`modal ${this.state.docToPreview ? 'open' : ''}`}
            id="preview-document"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl" role="document" style={{ maxWidth: '90%' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close cursor-pointer"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ docToPreview: false })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={{ transform: `rotate(${this.state.rotation}deg)` }}>
                  {this.state.base && this.state.isHightlightsLoaded &&
                    <PDFHighlighter url={this.state.base} onHighlightsChange={this.onHighlightsChange} highlights={this.state.highlights} rotation={this.state.rotation} isPopup />
                  }
                </div>
              </div>
            </div>
          </div>

        </div>

      </>
    );
  }
}