import React, { Component } from 'react';
import Select from 'react-select';
import { callApi } from "../../utils/api";
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import moment from "moment";
declare var $: any;


interface AllProps {
    history: any
}
export class QaManage extends Component<AllProps> {
    state = {
        bank_templates: [],
        bank_id: "",
        config: {
            id: "",
            bank_template_id: "",
            question: "",
            answer: ""
        },
        qaList: [],
        isEdited: false,
        isSorting: false,
        outreason: "",
        selectVal: false,
        isLoading: false
    };
    refItems: any = [];

    componentDidMount() {
        this.getTemplates();
        this.getOutTime();
        this.ouTime();
    }
    getTemplates = () => {
        callApi("POST", "bank_temp_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ bank_templates: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    getQAByBankId = (id) => {
        const obj = {
            bank_template_id: id
        }
        callApi("POST", "question_answer_get.php", obj)
            .then(res => res.data)
            .then(response => {
                let resData = response.responseJson.data;
                resData = _.sortBy(resData, (o) => {
                    return Number(o.uiIndex);
                });
                let counter = 0, obj: any = {};
                this.setState({ qaList: resData });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleTempChange = (data) => {
        const { isEdited, qaList } = this.state;
        if (isEdited && qaList.length > 0) {
            ToastsStore.warning("Please save changes before changing bank template");
        } else {
            if (data.id !== undefined && data.id !== null && data.id !== '') {
                this.setState({ bank_id: data.id });
                this.getQAByBankId(data.id);
            }
        }
    }
    handleChange = (e, i) => {
        const list: any[] = this.state.qaList;
        list[i][e.target.name] = e.target.value;
        this.setState({
            isEdited: true,
            qaList: list
        });
    }

    questionHandleChange = (e, i) => {
        const list: any[] = this.state.qaList;
        list[i]['question'] = e;
        this.setState({
            isEdited: true,
            qaList: list
        });
    }
    addQa = () => {
        const data = JSON.parse(JSON.stringify(this.state.config));
        const list: any[] = this.state.qaList;
        data.bank_template_id = this.state.bank_id;
        data.uiIndex = list.length
        list.push(data);
        this.setState({ qaList: list });
    }
    removeqa = (i, data) => {
        console.log(i, data);
        const list: any[] = this.state.qaList;
        if (data.id !== '') {
            const obj = { id: data.id }
            callApi("POST", "question_answer_delete.php", obj)
                .then(res => res.data)
                .then(response => {
                    list.splice(i, 1);
                    this.setState({ qaList: [] });
                    this.setState({ qaList: list });
                    ToastsStore.success("QA Successfully Deleted");
                    this.getQAByBankId(this.state.bank_id);
                })
                .catch(err => {
                    console.log(err);
                    ToastsStore.error("Error in deleting Q & A, Please try again!");
                });
        } else {
            list.splice(i, 1);
            this.setState({ qaList: [] }, () => {
                this.setState({ qaList: list });
            });
        }
    }
    saveQA = () => {
        console.log(this.state.qaList);
        const obj = {
            question_answer: this.state.qaList
        };
        callApi("POST", "question_answer_add.php", obj)
            .then(res => res.data)
            .then(response => {
                this.setState({ isEdited: false, qaList: [] }, () => {
                    this.getQAByBankId(this.state.bank_id);
                });
                ToastsStore.success("Successfully Added");
            })
            .catch(err => {
                console.log(err);
                ToastsStore.error("Something went wrong, Please try again!");
            });
    }

    addSort = () => {
        this.setState({ isSorting: true })
        $(document).ready(function () {
            $(function () {
                $("#drag-main")
                    .sortable({
                        items: "div.drag-section",
                        // update: function (e, ui) {
                        //     console.log(e.target,ui)
                        // }
                    })
                    .dragtable({ dragHandle: "#drag-main" })
                    .tablesorter();
            })
        });
    }

    reOrderQA = () => {
        const table: any = document.getElementById("drag-main");
        const { qaList } = this.state;
        const updatedQAList: any = [];
        let updatedKeyList: any = [];
        let currentId: Number, currentObject: any;
        if (table.children) {
            for (let i = 0; i < table.children.length; i++) {
                updatedKeyList.push(table.children[i].attributes[1].value)
            }
        }
        let currentObj: any = {};
        updatedKeyList.forEach((element, elementIndex) => {
            currentObj = qaList.filter((val: any) => {
                return val.id === element;
            });
            if (currentObj.length > 0) {
                currentObj = currentObj[0];
                delete currentObj.uiIndex;
                currentObj.uiIndex = elementIndex;
                updatedQAList.push(currentObj);
            }
        });
        this.setState({ qaList: updatedQAList }, () => {
            this.saveQA()
        })
        console.log("updatedQAList -> ", updatedQAList)
    }

    removeSort = () => {
        this.setState({ isSorting: false }, () => {
            this.reOrderQA()
        })
        $(document).ready(function () {
            $(function () {
                $("#drag-main")
                    .sortable('destroy')
            });
        });
    }
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    // sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    }else {
                        sessionStorage.removeItem('currentfileId');
                      }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };

    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    render() {
        const { bank_templates, bank_id, qaList, isSorting } = this.state;
        return (
            <section className="policymain-container" id="qa-manage" style={{ paddingTop: "60px !important" }}>
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <button
                                    className="btn btn-primary"
                                    style={{ marginBottom: '10px' }}
                                    onClick={() => this.props.history.goBack()}
                                >
                                    Back
                                </button>
                                <div className="page-wrapper">
                                    <div className="card">
                                        <div className="card-header policy-header">
                                            <h5>Q &amp; A Manage</h5>
                                            <button className="btn btn-primary" onClick={this.saveQA}>
                                                Save and Sumbit
                                            </button>
                                            <button className="btn btn-primary" onClick={() => isSorting ? this.removeSort() : this.addSort()}>
                                                {isSorting ? "Save Order" : "Reorder"}
                                            </button>
                                        </div>
                                        <div className="row" style={{ padding: "2%" }}>
                                            <div className='col-md-6 col-sm-6'>
                                                {bank_id !== '' &&
                                                    <button
                                                        className="btn btn-primary m-b-20"
                                                        onClick={this.addQa}
                                                    >
                                                        + Add Q &amp; A
                                                    </button>
                                                }
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="template-select">
                                                    {bank_templates && bank_templates.length > 0 && bank_templates.map((opt: any) => {
                                                        opt.label = opt.name; opt.value = opt.id
                                                    }) &&
                                                        <Select options={bank_templates} name="bank_id"
                                                            value={bank_templates.find((o: any) => o.value === bank_id)}
                                                            onChange={this.handleTempChange}
                                                        />
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">

                                            <div className="dt-responsive table-responsive">
                                                {/* {bank_id !== '' &&
                                                    <button
                                                        className="btn btn-primary m-b-20"
                                                        onClick={this.addQa}
                                                    >
                                                        + Add Q &amp; A
                                                    </button>
                                                } */}
                                                {/* <div className="template-select">
                                                    {bank_templates && bank_templates.length > 0 && bank_templates.map((opt: any) => {
                                                        opt.label = opt.name; opt.value = opt.id
                                                    }) &&
                                                        <Select options={bank_templates} name="bank_id"
                                                            value={bank_templates.find((o: any) => o.value === bank_id)}
                                                            onChange={this.handleTempChange}
                                                        />
                                                    }
                                                </div> */}
                                                <div className="qa-sec drag-menu" id="drag-main">
                                                    {qaList.map((item: any, index) =>
                                                        //@ts-ignore
                                                        <div className="qa drag-section" refKey={item.id} key={index} style={isSorting ? { cursor: "move" } : {}} ref={(ref: any) => this.refItems[item.uiIndex] = index}>
                                                            <div className="row m-0">
                                                                <div className="col-md-1 sno">
                                                                    <span>{index + 1}. </span>
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <label> Question</label>
                                                                    <SunEditor defaultValue={item.question}
                                                                        height='100'
                                                                        name=" question"
                                                                        setDefaultStyle="font-size: 12px;"
                                                                        disable={isSorting ? true : false}
                                                                        onChange={(e) => this.questionHandleChange(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <label>Answer</label>
                                                                    <textarea className="form-control" disabled={isSorting ? true : false} rows={8} name="answer" value={item.answer} onChange={(e) => this.handleChange(e, index)}></textarea>
                                                                </div>
                                                                <div className="col-md-1 display-flex">
                                                                    <div className="fa-add" onClick={this.addQa}>
                                                                        <i className="fa fa-plus-circle fa-icon" aria-hidden="true" ></i>
                                                                    </div>
                                                                    <div className="fa-delete" onClick={() => this.removeqa(index, item)}>
                                                                        <i className="fa fa-trash fa-icon" aria-hidden="true"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                >{this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default QaManage
