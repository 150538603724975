/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Select as MaterialSelect,
  MenuItem
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { cloneDeep, isEmpty } from 'lodash';
import ReactFileReader from "react-file-reader";
import Select from 'react-select';

import { callApi } from "../../utils/api";

const limitList = [ 20, 40, 60 ];

const ManageVideo = () => {
  const getEmptyData = () => {
    return {
      id: '',
      video_category_id: '',
      title: '',
      description: '',
      video: {},
      photo: {},
      key_word: '',
    }
  }
  const [ videoDetailsList, setVideoDetailsList ] = useState([]);
  const [ videoCategoryList, setVideoCategoryList ] = useState([]);
  const [ rowCount, setRowCount ] = useState(limitList[0]);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ noOfPages, setNoOfPages ] = useState(Math.ceil(totalCount / rowCount));
  const [ showPopup, setShowPopup ] = useState(false);
  const [ categoryId, setCategoryId ] = useState('');
  const [ selectedVideo, setSelectedVideo ] = useState(getEmptyData());
  const [ showVideoPopup, setShowVideoPopup ] = useState(false);

  useEffect(() => {
    getVideoCategoryList();
  }, []);

  useEffect(() => {
    setNoOfPages(Math.ceil(totalCount / rowCount));
  }, [ totalCount, rowCount ]);

  useEffect(() => {
    getVideoDetailsList();
  }, [ categoryId ])

  const getVideoCategoryList = (page_id, row_count) => {
    callApi("POST", "video_category_getdata.php", { page_id, row_count })
      .then(res => res.data)
      .then(response => {
        setVideoCategoryList(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getVideoDetailsList = (page_id, row_count) => {
    callApi("POST", "video_detail_get_one.php", { id: categoryId, page_id, row_count })
      .then(res => res.data)
      .then(response => {
        setVideoDetailsList(response.data);
        setTotalCount(response.count);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const deleteVideo = (id) => {
    callApi("POST", "video_detail_delete.php", { id })
      .then(res => res.data)
      .then(response => {
        if (response.statuscode) {
          getVideoDetailsList(pageNumber, rowCount);
          ToastsStore.success("Video Category Deleted Successfully");
        } else {
          ToastsStore.error("Something went wrong, please try again later");
        }
      })
      .catch(err => {
        ToastsStore.error("Something went wrong, please try again later");
        console.log(err);
      });
  }

  const addVideo = () => {
    const {
      title,
      description,
      video,
      key_word,
      photo
    } = selectedVideo;
    callApi("POST", "video_detail_add.php", { videocategoryid: categoryId, title, description, photo, video, keyword: key_word })
      .then(res => res.data)
      .then(response => {
        handleResetData();
        if (response.statuscode) {
          getVideoDetailsList(pageNumber, rowCount);
          ToastsStore.success("Video Category Created Successfully");
        } else {
          ToastsStore.error("Something went wrong, please try again later");
        }
      })
      .catch(err => {
        handleResetData();
        ToastsStore.error("Something went wrong, please try again later");
        console.log(err);
      });
  }

  const updateVideo = () => {
    const {
      id,
      video_category_id,
      title,
      description,
      video,
      key_word,
      photo
    } = selectedVideo;
    callApi("POST", "video_detail_update.php", { id, videocategoryid: video_category_id, title, description, photo, video, keyword: key_word })
      .then(res => res.data)
      .then(response => {
        handleResetData();
        if (response.statuscode) {
          getVideoDetailsList(pageNumber, rowCount);
          ToastsStore.success("Video Category Updated Successfully");
        } else {
          ToastsStore.error("Something went wrong, please try again later");
        }
      })
      .catch(err => {
        handleResetData();
        ToastsStore.error("Something went wrong, please try again later");
        console.log(err);
      });
  }

  const handleEditVideoDetails = (videoDetails) => {
    setSelectedVideo({ ...videoDetails});
    setShowPopup(true);
  }

  const handlePlayVideo = (videoDetails) => {
    setSelectedVideo({ ...videoDetails});
    setShowVideoPopup(true);
  }

  const handleResetData = () => {
    setSelectedVideo(getEmptyData());
    setShowPopup(false);
    setShowVideoPopup(false);
  }

  const handlePageLimit = (event) => {
    setRowCount(event.target.value);
    setPageNumber(1);
    getVideoCategoryList(pageNumber, rowCount);
  }

  const handlePagination = (event, value) => {
    setPageNumber(value);
    getVideoCategoryList(value, rowCount);
  }

  const handleVideoDetailsChange = (event) => {
    let videoDetails = cloneDeep(selectedVideo);
    videoDetails = {
      ...videoDetails,
      [event.target.name]: event.target.value
    };
    setSelectedVideo(videoDetails);
  }
  
  const uploadFiles = (files, type) => {
    let videoDetails = cloneDeep(selectedVideo);
    videoDetails = {
      ...videoDetails,
      [type]: {
        file: files.base64,
        fileName: files.fileList[0].name,
        fileType: files.fileList[0].type
      }
    };
    setSelectedVideo(videoDetails);
  };

  const deleteFile = (type) => {
    let videoDetails = cloneDeep(selectedVideo);
    videoDetails = {
      ...videoDetails,
      [type]: {}
    };
    setSelectedVideo(videoDetails);
  }

  const renderPreview = (fileDetails, type) => {
    return <div>
      <div className="card card-border-c-green preview-container">
        <span onClick={() => deleteFile(type)}>
          <i className="feather icon-x cursor-pointer" />
        </span>
        <div className="card-body">
          <h5 className="card-title">
            <span className="mb-1 text-c-green">
              <i className="feather icon-file-text" />
            </span>
            <span>{fileDetails.fileName || ""}</span>
          </h5>
        </div>
      </div>
    </div>
  }

  let modifiedCategoryList = []
  modifiedCategoryList = videoCategoryList && videoCategoryList.length > 0 && videoCategoryList.map((option) => {
    return { label: option.category_name, value: option.id }
  })
  
  return <div className="right-container">
    <ToastsContainer
      store={ToastsStore}
      position={ToastsContainerPosition.TOP_CENTER}
    />
    <div className="video-category-conatiner">
      <h5 className="video-category">Manage Video</h5>
      <div className="video-category-wrapper">
        <div className="display-flex align-items-center manage-video-wrapper">
          <div>Category Name : </div>
          <div className="video-select">
            {modifiedCategoryList && <Select
              options={modifiedCategoryList}
              name="video-category"
              value={modifiedCategoryList && modifiedCategoryList.find((option) => option.value === categoryId)}
              onChange={e => setCategoryId(e.value)}
            />}
          </div>
        </div>
        {categoryId && <a
          className="btn btn-primary m-b-20"
          data-toggle="modal"
          data-target="#video-form"
          onClick={() => setShowPopup(true)}
        >
          + Add Video
        </a>}
        <div>
          {videoDetailsList && videoDetailsList.length > 0 ?
            <>
            <TableContainer className="table-container">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Video</TableCell>
                    <TableCell>Keywords</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videoDetailsList && videoDetailsList.length > 0 && videoDetailsList.map((videoDetail, index) => {
                    const { id, title, description, video, key_word, photo } = videoDetail;
                    const serialNumber = rowCount * (pageNumber - 1) + index + 1
                    return (
                      <TableRow hover tabIndex={-1} className="cursor-pointer" key={id}>
                        <TableCell className="index-table-cell">{serialNumber}</TableCell>
                        <TableCell>{title}</TableCell>
                        <TableCell>{description}</TableCell>
                        <TableCell>
                          <div className="video-action-button">
                            <img src={photo} alt={id} />
                            {video && video.file && <button type="button" className="btn btn-primary" onClick={() => handlePlayVideo(videoDetail)}>
                              Play
                            </button>}
                          </div>
                        </TableCell>
                        <TableCell>{key_word}</TableCell>
                        <TableCell>
                          <div className="btn-group video-action-button">
                            <button type="button" className="btn btn-primary">
                              Action
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <div
                              className="dropdown-menu"
                              x-placement="top-start"
                              style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                  "translate3d(88px, -2px, 0px)"
                              }}
                            >
                              <a className="dropdown-item" onClick={() => handleEditVideoDetails(videoDetail)}>Edit</a>
                              <a className="dropdown-item" onClick={() => deleteVideo(id)}>Delete</a>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )})
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination-container">
              <div>
                <span>Show</span>
                <MaterialSelect className="select-container" variant="outlined" value={rowCount} onChange={e => handlePageLimit(e)}>
                  {limitList.map((limitValue, index) => (
                    <MenuItem key={index} value={limitValue}>
                      {limitValue}
                    </MenuItem>
                  ))}
                </MaterialSelect>
                <span>Rows</span>
              </div>
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  defaultPage={pageNumber}
                  onChange={handlePagination}
                  showFirstButton
                  showLastButton
                />
              )}
            </div>
            </>
          : null}
        </div>
      </div>
    </div>
    {showPopup && <div
      className="dialog-modal"
      role="dialog"
      aria-hidden="true"
    >
      <div className="dialog-modal-body add-video-modal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Manage Video</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleResetData}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <form> */}
              <div className="form-group">
                <label className="col-form-label">Title:</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  placeholder="Title"
                  onChange={e => handleVideoDetailsChange(e)}
                  value={selectedVideo.title}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Description:</label>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  placeholder="Description"
                  onChange={e => handleVideoDetailsChange(e)}
                  value={selectedVideo.description}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Keyword:</label>
                <input
                  type="text"
                  name="key_word"
                  className="form-control"
                  placeholder="Keyword"
                  onChange={e => handleVideoDetailsChange(e)}
                  value={selectedVideo.key_word}
                />
              </div>
              <div className={`form-group ${isEmpty(selectedVideo.video) ? 'file-not-available': 'file-available'}`}>
                <label className="col-form-label">Video:</label>
                <ReactFileReader
                  fileTypes={[]}
                  base64={true}
                  multipleFiles={false}
                  handleFiles={e => uploadFiles(e, 'video')}
                >
                  <button className="ml2 btn btn-primary btnupload float-right">Upload Video</button>
                </ReactFileReader>
                {!isEmpty(selectedVideo.video) && renderPreview(selectedVideo.video, 'video')}
              </div>
              <div className={`form-group ${isEmpty(selectedVideo.photo) ? 'file-not-available': 'file-available'}`}>
                <label className="col-form-label">Thumbnail Photo:</label>
                <ReactFileReader
                  fileTypes={[]}
                  base64={true}
                  multipleFiles={false}
                  handleFiles={e => uploadFiles(e, 'photo')}
                >
                  <button className="ml2 btn btn-primary btnupload float-right">Upload Photo</button>
                </ReactFileReader>
                {!isEmpty(selectedVideo.photo) && renderPreview(selectedVideo.photo, 'photo')}
              </div>
            {/* </form> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleResetData}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={!isEmpty(selectedVideo.id) ? updateVideo: addVideo }
            >
              {!isEmpty(selectedVideo.id) ? 'Save' : 'Add Video' }
            </button>
          </div>
        </div>
      </div>
    </div>}
    {showVideoPopup && <div
      className="dialog-modal"
      role="dialog"
      aria-hidden="true"
    >
      <div className="dialog-modal-body add-video-modal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{selectedVideo.title}</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleResetData}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <iframe
              src={selectedVideo.video.file}
              frameborder="0"
              allow="autoplay; encrypted-media"
              height={300}
              allowfullscreen
              title="video"
            />
          </div>
        </div>
      </div>
    </div>}
  </div>;
}

export default ManageVideo