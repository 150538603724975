import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import "react-flexy-table/dist/index.css";

import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
  } from "react-toasts";
import { ComplaintTable } from './complaintTable';

export class ComplaintList extends Component {
    state = {
        complaints: [],
        user: {
            role_id: ""
        },
        statuses: [
            "pending", "working", "completed"
        ],
        filteredComplaints: [],
        selectedStatus: "pending",
        searchText: ""
    }
    componentDidMount() {
        this.getComplaint();
        const user: any = sessionStorage.getItem("user");
        this.setState({user : user ? JSON.parse(user)["userId"] : ""})
    }
    getComplaint = () => {
        callApi("POST", "complaint_list_all.php")
            .then(res => res.data)
            .then(response => {
                this.setState({
                    complaints: response.data,
                    filteredComplaints: response.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleStatusChange = (data, ticket) => {
        // this.setState({selectedStatus: data.value});
        const statusId = data.value === "pending" ? "0" : data.value === "working" ? "1" : "2";
        callApi("POST", "get_status_change.php", {ticketId: ticket.ticketId, Status: statusId})
            .then(res => res.data)
            .then(response => {
                ToastsStore.success("Complaint status changed successfully");
                this.getComplaint();
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleSearchChange = (e) => {
        const { complaints } = this.state;

        const list = complaints.filter((item: any) => {
            const id = item.ticketId.toLowerCase();
            return id.includes(e.target.value.toLowerCase());
        });
        console.log(e.target.value, list);
        this.setState({
            searchText:  e.target.value,
            filteredComplaints: list
        });
    }
    render() {
        const { complaints, user, statuses, filteredComplaints, searchText } = this.state;
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>Complaint List</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row justify-content-end">
                                                        <div className="col-sm-3">
                                                            <input type="text" className="form-control mb-10"
                                                                name="search" placeholder="search"
                                                                value={searchText}
                                                                onChange={this.handleSearchChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="dt-responsive table-responsive">
                                                            {filteredComplaints && filteredComplaints.length &&
                                                                <ComplaintTable 
                                                                    complaints={filteredComplaints} 
                                                                    user={user}
                                                                    handleStatusChange={this.handleStatusChange}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ComplaintList
