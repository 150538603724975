import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { GeneralVerify } from './sopDataTemplate';
import Select from 'react-select';

interface AllProps {
    data: GeneralVerify;
    handleGeneralVerifyChange: any;
    isOnlyView?: boolean;
}
export class GeneralVerification extends Component<AllProps> {
    state = {
        ownershipOptions: [
            { value: 'Individual', label: 'Individual' },
            { value: 'Joint', label: 'Joint' },
            { value: 'Company', label: 'Company' },
            { value: 'Partnership', label: 'Partnership' },
        ],
        selectedOwnershipOption: null,
        isOptionOpen: false,
        bgColor: '#ffff8d'

    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleChange = (name, val) => {
        const { data } = this.props;
        data[name] = val;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleGeneralVerifyChange(data);
    }
    handleStateChange = (stateName, value) => {
        console.log("value", value)
        this.setState({
            [stateName]: value
        }, () => {
            this.changeBgColor()
        })
    }
    handleFileChange = (name, val) => {
        const { data } = this.props;
        data.file[name] = val;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleGeneralVerifyChange(data);
    }

    getOtherProps = (key) => {
        // const { data, isOnlyView } = this.props;
        // if (isOnlyView) return { [key]: true };
        return null
    }
    render() {
        const { data, isOnlyView } = this.props;
        const { selectedOwnershipOption, ownershipOptions, isOptionOpen, bgColor } = this.state;
        return (
            <div className="collapse-section general-verification">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="0" onClick={() => { this.setState({ isOptionOpen: !isOptionOpen }) }}>
                            <h5>
                                <b>General Verification</b>
                            </h5>
                            <i className={`icon feather icon-${isOptionOpen ? 'plus' : 'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', float: 'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="row mb-10">
                                    <div className="col-md-9 no-right-padding">
                                        <label className="label" style={{ padding: 0 }}>Is The Spelling for Mr./Smt/Minor/Late Correct?</label>
                                    </div>
                                    <div className="col-md-3 no-left-padding">
                                        <div className="row">
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="isSpellingCorrect"
                                                type="radio"
                                                id="spellTrue"
                                                checked={data.isSpellingCorrect}
                                                onChange={(e) => this.handleChange("isSpellingCorrect", true)}
                                                {...this.getOtherProps("disabled")}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="isSpellingCorrect"
                                                type="radio"
                                                id="spellFalse"
                                                checked={!data.isSpellingCorrect}
                                                onChange={(e) => this.handleChange("isSpellingCorrect", false)}
                                                {...this.getOtherProps("disabled")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-md-12">
                                        <label className="label" style={{ padding: 0 }}>Ownership</label>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row ml-10">
                                            <Select
                                                value={data && { value: data.ownerShip, label: data.ownerShip }}
                                                onChange={(value) => this.handleChange("ownerShip", value.value)}
                                                options={ownershipOptions}
                                                className="select-dropdown"
                                                {...this.getOtherProps("isDisabled")}
                                            />
                                            {/* <Form.Check
                                                inline
                                                label="Individual"
                                                name="ownership"
                                                type="radio"
                                                checked={data.ownerShip === "Individual"}
                                                onChange={(e) => this.handleChange("ownerShip", "Individual")}
                                            />
                                            <Form.Check
                                                inline
                                                label="Joint"
                                                name="ownership"
                                                type="radio"
                                                checked={data.ownerShip === "Joint"}
                                                onChange={(e) => this.handleChange("ownerShip", "Joint")}
                                            />
                                            <Form.Check
                                                inline
                                                label="Company"
                                                name="ownership"
                                                type="radio"
                                                checked={data.ownerShip === "Company"}
                                                onChange={(e) => this.handleChange("ownerShip", "Company")}
                                            />
                                            <Form.Check
                                                inline
                                                label="Partnership"
                                                name="ownership"
                                                type="radio"
                                                checked={data.ownerShip === "Partnership"}
                                                onChange={(e) => this.handleChange("ownerShip", "Partnership")}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>File</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-3 mb-10">
                                            <label className="label mb-2" style={{ padding: 0 }}>BT Transfer</label>
                                            {data.file.btTransfer}
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="btTransfer"
                                                    type="radio"
                                                    checked={data.file.btTransfer}
                                                    onChange={(e) => this.handleFileChange("btTransfer", true)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="btTransfer"
                                                    type="radio"
                                                    checked={!data.file.btTransfer}
                                                    onChange={(e) => this.handleFileChange("btTransfer", false)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-10">
                                            <label className="label" style={{ padding: 0 }}>MOD Available</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="modAvailable"
                                                    type="radio"
                                                    checked={data.file.modAvailable}
                                                    onChange={(e) => this.handleFileChange("modAvailable", true)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="modAvailable"
                                                    type="radio"
                                                    checked={!data.file.modAvailable}
                                                    onChange={(e) => this.handleFileChange("modAvailable", false)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-10">
                                            <label className="label" style={{ padding: 0 }}>Purchase</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="purchase"
                                                    type="radio"
                                                    checked={data.file.purchase}
                                                    onChange={(e) => this.handleFileChange("purchase", true)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="purchase"
                                                    type="radio"
                                                    checked={!data.file.purchase}
                                                    onChange={(e) => this.handleFileChange("purchase", false)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="label" style={{ padding: 0 }}>Sale Agreement</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="saleAgreement"
                                                    type="radio"
                                                    checked={data.file.saleAgreement}
                                                    onChange={(e) => this.handleFileChange("saleAgreement", true)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="saleAgreement"
                                                    type="radio"
                                                    checked={!data.file.saleAgreement}
                                                    onChange={(e) => this.handleFileChange("saleAgreement", false)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="label" style={{ padding: 0 }}>Self Construction</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="selfConstruction"
                                                    type="radio"
                                                    checked={data.file.selfConstruction}
                                                    onChange={(e) => this.handleFileChange("selfConstruction", true)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="selfConstruction"
                                                    type="radio"
                                                    checked={!data.file.selfConstruction}
                                                    onChange={(e) => this.handleFileChange("selfConstruction", false)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-7 no-left-padding">
                                            <label className="label" style={{ padding: 0 }}>Proceeding of Building Approval &amp; Approved Plan</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="proceedingApproval"
                                                    type="radio"
                                                    checked={data.file.proceedingApproval}
                                                    onChange={(e) => this.handleFileChange("proceedingApproval", true)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="proceedingApproval"
                                                    type="radio"
                                                    checked={!data.file.proceedingApproval}
                                                    onChange={(e) => this.handleFileChange("proceedingApproval", false)}
                                                    {...this.getOtherProps("disabled")}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <label className="label" style={{ padding: 0 }}>Fresh/ Normal</label>
                                        <div className="row" style={{ marginLeft: 0 }}>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="new"
                                                type="radio"
                                                checked={data.file.new}
                                                onChange={(e) => this.handleFileChange("new", true)}
                                                {...this.getOtherProps("disabled")}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="new"
                                                type="radio"
                                                checked={!data.file.new}
                                                onChange={(e) => this.handleFileChange("new", false)}
                                                {...this.getOtherProps("disabled")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default GeneralVerification
