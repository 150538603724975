import React, { Component } from "react";
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { isEmpty, isNaN, isNumber } from 'lodash';
// import moment from 'moment';
// import moment from 'moment';
import Select from 'react-select';

interface AllProps {
  id: any;
  doclist: any;
}
export interface DocList {
  doc_name: string;
  doc_no: string;
  remark: string;
  file_id: string;
  type: string;
  date: string;
  status: string;
  fod_fk: string;
  submit: number;
  inactive: number;
  document_no: string;
}
const particularList = [
  {
    label: 'Original',
    value: 'original'
  },
  {
    label: 'Xerox',
    value: 'xerox'
  }
]
export default class DocumentList extends Component<AllProps> {
  // documentBackground = ['#cad8e4', '#d8cae4', '#cde4ca', '#e4d7ca', '#e4caca', '#e0e4ca']
  documentBackground = ['#e2f2ff', '#f9e2ff', '#f4edb2', '#fffbe2', '#ffeee2', '#e8ffe2']
  fromChanged = false;
  toChanged = false;
  timeout: any = null;
  state = {
    overVieWorking: false,
    documentTotal: 0,
    docList: [],
    docIdToBeDeleted: 0,
    reason: "",
    total_extent: "",
    extent_unit: "",
    pageFrom: '',
    pageTo: '',
    selectedDocumentId: '',
    showCopyConfirm: false,
    inputValidtionError: false,
    errorMessage: '',
    validating: false,
    docNoYear: "",
    categoriesList: [],
    particular: "",
    type: "",
  };
  componentDidMount() {
    this.getDocuments();
    this.getTotalExtent();
    this.setState({ docList: this.props.doclist });
    this.getCategories();
  }
  getCategories = () => {
    callApi("POST", "category.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ categoriesList: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleChangeCategory = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;

    if (fieldName === "category") {
      // const employee_id = fieldValue;
      this.setState({ type: fieldValue });
    }
    if (fieldName === "particular") {
      const bank_name = fieldValue;
      console.log("267", bank_name);
      this.setState({ [fieldName]: fieldValue });
    }
  }
  saveDocNo = (e: any) => {
    console.log("e---->", e);
    this.setState({ docNoYear: e.target.value });
  }
  getDocuments = () => {
    callApi("POST", "doc_list_get.php", { id: this.props.id })
      .then(res => res.data)
      .then(response => {
        let docList = this.state.docList;
        if (response && response.data && response.data.length) {
          docList = response.data;
        }
        this.setState({ docList });

      })
      .catch(err => {
        console.log(err);
      });
  };
  getTotalExtent = () => {
    callApi("POST", "get_total_extent.php", { id: this.props.id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.setState({ total_extent: response.data[0].total_extend, extent_unit: response.data[0].total_unit });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getFormUrl = modal => {
    if (modal.type === "ec_doc") {
      return "/ec-form/" + this.props.id + "/" + modal.file_id + '/2';
    } else {
      return "/scrutiny/" + this.props.id + "/" + modal.file_id + '/2';
    }
  };
  confirmDeleteDoc = modal => {
    this.setState({ docIdToBeDeleted: modal.file_id });
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  deleteDoc = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "scrutiny_delete.php", {
      id: this.state.docIdToBeDeleted,
      userId
    })
      .then(res => res.data)
      .then(response => {
        this.getDocuments();
        this.submitReason();
      })
      .catch(err => {
        console.log(err);
      });
  };
  submitReason = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "reason_create.php", {
      userId,
      action: "delete-document",
      reason: this.state.reason,
      fod_fk: this.state.docIdToBeDeleted
    })
      .then(res => res.data)
      .then(response => {
        this.setState({ docIdToBeDeleted: 0 });
      })
      .catch(err => {
        console.log(err);
      });
  };
  onModifyTotalExtent = () => {
    callApi("POST", "total_extent.php", {
      file_id: this.props.id,
      total_extent: this.state.total_extent,
      extent_unit: this.state.extent_unit
    })
      .then(res => res.data)
      .then(response => {
        // console.log(response);
      })
      .catch(err => {
        console.log(err);
      });
  }
  handleFromPageChange = (event) => {
    const value = event.target.value;
    if (value.match(/^[0-9]+$/) || isEmpty(value)) {
      this.setState({ validating: true });
      if (!this.fromChanged) {
        this.fromChanged = true;
      }
      clearInterval(this.timeout);
      this.timeout = setTimeout(() => {
        this.onInputFocusOut(value, this.state.pageTo);
      }, 500);
      this.setState({ pageFrom: value });
    }
  }
  handleToPageChange = (event) => {
    const value = event.target.value;
    if (value.match(/^[0-9]+$/) || isEmpty(value)) {
      this.setState({ validating: true });
      if (!this.toChanged) {
        this.toChanged = true;
      }
      clearInterval(this.timeout);
      this.timeout = setTimeout(() => {
        this.onInputFocusOut(this.state.pageFrom, value);
      }, 500);
      this.setState({ pageTo: value });
    }
  }
  onInputFocusOut = (pageFrom, pageTo) => {
    if (this.fromChanged && this.toChanged && pageFrom && pageTo) {
      const from = parseInt(pageFrom, 10) || 0;
      const to = parseInt(pageTo, 10) || 0;
      const error = from > to;
      const errorMessage = error ? "From Page should not be greater than To Page" : '';
      this.setState({ inputValidtionError: error, errorMessage }, () => this.setState({ validating: false }));
    } else {
      this.setState({ inputValidtionError: false, errorMessage: '' }, () => this.setState({ validating: false }));
    }
  }
  handleCopyDocumentSubmit = () => {
    callApi("POST", "copyPdf.php", {
      file_id: this.state.selectedDocumentId,
      pageFrom: this.state.pageFrom,
      pageTo: this.state.pageTo,
      docNoYear: this.state.docNoYear,
      category: this.state.type,
      particular: this.state.particular,
    })
      .then(res => res.data)
      .then(response => {
        const { result, message } = response
        result ? ToastsStore.success(message) : ToastsStore.error(message);
        this.getDocuments();
        this.handleClosePopup();
      })
      .catch(err => {
        console.log(err);
        this.handleClosePopup();
        ToastsStore.error(err.message || "Something went wrong, please try again later");
      });
  }
  handleClosePopup = () => {
    this.fromChanged = false;
    this.toChanged = false;
    this.setState({ pageFrom: '', pageTo: '', docNoYear: '', type: '', particular: '', selectedDocumentId: '', showCopyConfirm: false, inputValidtionError: false, errorMessage: '' })
  }
  handleCopyDocument = (fileDetails) => {
    this.setState({ selectedDocumentId: fileDetails.file_id, showCopyConfirm: true })
  }
  render() {
    return (
      <div id="step-2">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <h5>File Document</h5>
        <hr />
        <div className="card-body">
          {this.state.docList &&
            this.state.docList.length > 0 &&
            this.state.docList.map((doc: any, ind) => {
              return (
                doc.documents &&
                doc.documents.length && (
                  <section key={ind} className="all-document-section" style={{ backgroundColor: this.documentBackground[ind] }}>
                    <h5>{doc.type}</h5>
                    {doc.type === 'Title Deed' ?
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>Total Extent<span className="inputEgMsg">There Such Extent Eg.1330 ¾ Sq.Ft</span></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Total Extent"
                              name="total_extent"
                              value={this.state.total_extent}
                              onChange={e => this.handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>Select Unit</label>
                            <select
                              className="custom-select"
                              name="extent_unit"
                              value={this.state.extent_unit}
                              onChange={e => this.handleChange(e)}
                              required
                            >
                              <option value="">Select.</option>
                              <option value="Sq.Ft">Sq.Ft</option>
                              <option value="Sq.Yards">Sq. Yards</option>
                              <option value="Sq.Meter">Sq. Meter</option>
                              <option value="Acres">Acres</option>
                              <option value="Cents">Cents</option>
                              <option value="Hectares">Hectares</option>
                              <option value="Ground">Ground</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className="col-md-2"
                          style={{ marginTop: "3%" }}
                        >
                          <button
                            className="btn btn-primary sw-btn-next"
                            type="button"
                            onClick={() => this.onModifyTotalExtent()}
                          >
                            Modify
                          </button>
                        </div>
                      </div>
                      : null}
                    <div className="row">
                      {doc.documents && doc.documents.length
                        ? doc.documents.map((itm: DocList, index) => {
                          return (
                            <a
                              data-toggle="tooltip"
                              title=""
                              className="col-md-3"
                              data-original-title="Sale deed is missing"
                              key={index}
                            >
                              <div
                                className={
                                  itm.inactive === 1 ? "card card-border-c-yellow" :
                                    itm.submit === 1
                                      ? "card card-border-c-green"
                                      : "card card-border-c-red"
                                }
                              >
                                <i className="complete" />
                                <div className="card-body document-content">
                                  <Link to={this.getFormUrl(itm)} key={index}>
                                    <h5 className="card-title">
                                      {/* {itm.date ? moment(itm.date).format('MM/DD/YYYY') : ''} -{" "} */}
                                      {itm.date ? itm.date + "-" + " " : ""}
                                      <span
                                        className={
                                          itm.submit === 1
                                            ? "mb-1 text-c-green"
                                            : "mb-1 text-c-red"
                                        }
                                      >
                                        {itm.doc_no}
                                      </span>
                                    </h5>
                                    {itm.inactive === 1 && <span className="mb-1 text-c-yellow" style={{ fontSize: '22px', fontWeight: 900 }}>NOT RELATED</span>}
                                    <label>{itm.type}</label><br />
                                    <label>{itm.document_no}</label>
                                    <label>{itm.remark}</label>
                                  </Link>
                                  <div className="document-content">
                                    <button
                                      type="button"
                                      className="btn btn-icon btn-rounded btn-danger"
                                      data-toggle="modal"
                                      style={{ width: "30px", height: "30px", padding: "5px 8px" }}
                                      onClick={() => this.confirmDeleteDoc(itm)}
                                    >
                                      <i className="feather icon-minus" />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-icon document-copy-icon btn-rounded btn-secondary"
                                      data-toggle="modal"
                                      style={{ width: "30px", height: "30px", padding: "5px 8px" }}
                                      onClick={() => this.handleCopyDocument(itm)}
                                    >
                                      <i className="feather icon-copy" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })
                        : ""}
                    </div>
                  </section>
                )
              );
            })}
        </div>
        <div
          className="modal fade"
          id="delete-document"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Disable Document</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to disable this document?</h6>
                <div className="form-group">
                  {/* <label>Reason</label> */}
                  <textarea
                    name="reason"
                    className="form-control"
                    onChange={e => this.handleChange(e)}
                    value={this.state.reason}
                    placeholder="Reason"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.deleteDoc}
                >
                  Disable
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showCopyConfirm && <div
          className={`modal${this.state.showCopyConfirm ? ' open' : ''}`}
          id="preview-document"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Copy Document</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClosePopup}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group document-content">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        name="reason"
                        className="form-control"
                        onChange={e => this.handleFromPageChange(e)}
                        value={this.state.pageFrom}
                        placeholder="From Page"
                      />
                      {!this.state.pageFrom && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                    </div>
                    <div className="col-md-6">
                      <input
                        name="reason"
                        className="form-control"
                        onChange={e => this.handleToPageChange(e)}
                        value={this.state.pageTo}
                        placeholder="To Page"
                      />
                      {!this.state.pageTo && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                    </div>
                  </div>
                </div>
                {this.state.inputValidtionError && <div className="text-danger">{this.state.errorMessage}</div>}
                <label>Doc.No/Year</label>
                <input
                  className="form-control"
                  name="docNoYear"
                  placeholder="example: 1501/1996"
                  type="text"
                  value={this.state.docNoYear}
                  onChange={e => this.saveDocNo(e)}
                />
                {!this.state.docNoYear && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                <label style={{ marginTop: "2%" }}>Category</label>
                {this.state.categoriesList && this.state.categoriesList.length > 0 && this.state.categoriesList.map((opt: any) => {
                  opt.label = opt.name; opt.value = opt.id
                }) &&
                  <>
                    <Select options={this.state.categoriesList} name="category"
                      value={this.state.categoriesList.find((o: any) => o.value === this.state.type)}
                      placeholder="example: Title Deed"
                      onChange={(e) => this.handleChangeCategory(e, 'category', 'singleselect')}
                    />
                  </>
                }
                {!this.state.type && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                <label style={{ marginTop: "2%" }}>Particular</label>
                <>
                  <Select options={particularList} name="particular"
                    value={particularList.find((o) => o.value === this.state.particular)}
                    onChange={(e) => this.handleChangeCategory(e, 'particular', 'singleselect')}
                    placeholder="example: Xerox"
                  />
                  {!this.state.particular && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                </>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.handleCopyDocumentSubmit}
                  disabled={(isEmpty(this.state.pageFrom) || isEmpty(this.state.pageTo) || this.state.inputValidtionError || this.state.validating || isEmpty(this.state.docNoYear) || isEmpty(this.state.type) || isEmpty(this.state.particular))}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}
