import React, { Component } from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import Select from 'react-select';
import DocumentNumber from "./DocumentNumber";
import ReactFileReader from "react-file-reader";
import { FRONTOFFICE_ROLE, EXECITUVE_ROLE, MANAGER_ROLE, ADMIN_ROLE } from "../../utils/role";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { FaRegEdit } from "react-icons/fa";
import { getFileStatus } from "../../utils/fileStatus";
import QueryTree from "./QueryTree";
import { cloneDeep } from 'lodash';
import moment from "moment";
import { saveAs } from "file-saver";
import { notifyToUser } from "../../utils/firebase";
import { pushnotificationApi } from "../../utils/pushNotify";
import UploadDocument from "./uploadDocument";
import DashUploadDocument from "./DashUploadDocument";
import CountdownTimer from './CountdownTimer';
import App from "../../App";
declare var $: any;
const particularList = [
  {
    label: 'Original',
    value: 'original'
  },
  {
    label: 'Xerox',
    value: 'xerox'
  }
]
export interface DocumentDetail {
  total: number;
  yettoopen: number;
  submitted: number;
  inprogress: number;
}
interface AllProps {
  match: any;
  history: any;
}
class FileDashboard extends Component<AllProps> {
  textareaRef: React.RefObject<any>;
  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
  }
  query: any = React.createRef();
  startTime: any;
  documentFormat = {
    base: "",
    base_url: "",
    docNoYear: "",
    particular: "xerox",
    type: "",
    isValid: true,
    doc_type: ""
  };
  state = {
    showModal: false,
    base: [],
    docToPreview: "",
    type: "",
    workfileEnable: true,
    // intime:"",
    inreasonRE: "",
    selectVal: false,
    queryRecived: "",
    setResponse: true,
    showThepopup: true,
    other: {},
    ec_doc: {},
    rev_rec_doc: {},
    queries: [],
    file_status: "0",
    info: {
      verify_officer: "",
      process_officer: "",
      app_id: "",
      doc_request: "",
      file_name: "",
      file_status: "",
      verify: "",
      report_status: "",
      pending: "",
      closed: "",
      total: "",
      bank_name: "",
      product_name: "",
      login_time: "",
      opinion_type: ""
    },
    reason: "",
    step: 0,
    bank_templates: [],
    bank_id: 0,
    isbtnload: false,
    office_users: [],
    remote_users: [],
    remote_cc_users: [],
    multiSelectedQuery: [],
    repliedQueries: [],
    queryBase: [],
    isNewFile: false,
    queryPayload: {
      requery: [],
      resend: []
    },
    previewDoc: '',
    queryLoading: false,
    queryQues: [],
    oldQueryQues: [],
    quesSubmitted: false,
    isFlowChartUploading: false,
    flowChartPreview: "",
    updateDownloadData: {
      reasonForDownload: [],
      updatedDetails: []
    },
    action: "",
    uploadFile: {
      base64: "",
      fileList: [{ name: "" }]
    },
    isReasonForDownloadShown: false,
    isUpdateDownloadShown: false,
    date: '',
    hours: 0,
    minutes: 0,
    seconds: 0,
    intime: 0,
    curentDate: 0,
    in_reason: "",
    outreason: "",
    docNoYear: "",
    categoriesList: [],
    categoriesType: "",
    particular: "",
    newquery: [],
    uploadbtn: false,
    initialTimeInMinutes: 0,
    updatedRemainingTime: 0,
    doc_count: 0,
    blockdownload: "",
    showTitle: true,
    iscomplete: false,
    isprocesscomplete: false,
    reasons: [],
    query_tree_length: 0,
    isLoading: false,
    deeds: [],
  }

  componentDidMount() {
    this.getQueryTree();
    this.getFileDashboard();
    this.listreason();
    // this.getQueries();
    this.getStepStatus();
    this.getDeeds();
    if (localStorage.getItem("BankTemplate")) {
      var bankTemp: any = localStorage.getItem("BankTemplate");
      this.setState({ bank_templates: JSON.parse(bankTemp) })
    } else {
      this.getTemplates();
    }
    this.getInTime();
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
    sessionStorage.setItem("currentfileId", JSON.stringify(this.props.match.params.id));
    sessionStorage.setItem("dashboard_status", "1");
    var inreasonRE: any = localStorage.getItem("in_reason");
    this.setState({ inreasonRE: JSON.parse(inreasonRE) })
    console.log("113", this.state.inreasonRE);
    // $("#balance-time").modal("show");
  }
  countdownTimerRef: CountdownTimer | null = null;
  appRef: App | null = null;

  componentWillUnmount() {
    if (this.countdownTimerRef) {
      this.countdownTimerRef.clearInterval();
      console.log("file out");
    }
    if (this.appRef) {
      this.appRef.getTimerZero();
      console.log("app out");
    }
    var inreasonRe = localStorage.getItem("in_reason");
    if (inreasonRe) {
      window.removeEventListener("beforeunload", this._confirm);
      window.onpopstate = this._confirm;
    }
    const process_office_id: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    if (process_office_id == userid1) {
      this.timeUpdate(this.props.match.params.id, "file_dash_out");
    } else {
      console.log("process officer id not match");
    }
  }
  getDeeds = () => {
    callApi("POST", "deed_master_get.php", { category: this.state.type })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const deeds = response.data;
          this.setState({ deeds });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getQueryTree = () => {
    let req = { fod_fk: this.props.match.params.id };
    callApi("POST", "query_tree.php", req)
      .then(res => res.data)
      .then(response => {
        if (response) {
          this.setState({ query_tree_length: response.length });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getFileAssignStatus() {
    callApi("POST", "get_file_assign_status.php", {
      file_id: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ fileAssignStatus: files });
      })
      .catch(err => {
        console.log(err);
      });
  }
  listreason = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "query_reason_get.php")
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ reasons: files });
      })
      .catch(err => {
        console.log(err);
      });
  };
  OPenToaster = () => {
    ToastsStore.success("Please Select The Bank Template");
  }
  _backConfirm = async () => {
    // let event: any = window.confirm("Do You Want Leave This Page");

    // if (event) {
    //   window.history.pushState(null, "", window.location.href);
    // }
  }
  getCategories = () => {
    callApi("POST", "category.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ categoriesList: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  _confirm = (e) => {
    console.log("its working");
    var confirmationMessage = "\o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }
  previousPop = () => {
    $("#in_time").modal("show");
    $("#another-reason").modal("hide");
  }
  previousPage = () => {
    const fileurl: any = localStorage.getItem("url");
    $("#in_time").modal("hide");
    sessionStorage.removeItem("currentfileId")
    localStorage.removeItem("thisPopup");
    this.props.history.push(`${fileurl}`);
    localStorage.removeItem("url");
  }
  getInTime = () => {
    if (localStorage.getItem("thisPopup")) {
      $("#in_time").modal("show")
      let date = new Date();
      let curentDate = date.toLocaleDateString();
      localStorage.setItem("Intimedate", JSON.stringify(curentDate));
      var Railway = date.getMinutes();
      if (Railway < 10) {
        var TotalMinutes = "0" + Railway;
        let intime = date.getHours() + ":" + TotalMinutes;
        localStorage.setItem("IntimeGet", JSON.stringify(intime));
      }
      else {
        let intime: any = date.getHours() + ":" + date.getMinutes();
        this.setState({ intime: intime })
        localStorage.setItem("IntimeGet", JSON.stringify(intime));
      }
      let intime = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("IntimeGet", JSON.stringify(intime));
      console.log("date", date);
    } else {
      $("#in_time").modal("hide")
    }


  }
  SubmitREsponse = () => {
    if (this.state.in_reason == "") {
      this.setState({ selectVal: true });
    }
    else {
      localStorage.setItem("filenumber", this.state.info.file_name);
      localStorage.setItem("inreasonlive", this.state.in_reason)
      localStorage.removeItem("thisPopup");
      this.liveinsert();
      localStorage.removeItem("thisPopup");

      let in_reason = this.state.in_reason;
      localStorage.setItem("in_reason", JSON.stringify(in_reason));

    }

  }
  liveinsert = () => {
    this.setState({ isLoading: true });
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["team_type"] : "";
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    console.log("214", role);
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const bankName: any = localStorage.getItem("bankName");
    localStorage.setItem("fileNamCode", this.state.info.file_name);
    // const in_time : any = localStorage.getItem("IntimeGet");
    // const in_reason : any = localStorage.getItem("in_reason");
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    console.log("221", curentTime);
    const textRay = `Currently working on File No : ${this.props.match.params.id}`;
    let obj = {
      file_no: this.state.info.file_name,
      in_time: curentTime,
      reason: this.state.in_reason,
      user_id: userId,
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: textRay,
      bank_name: bankName,
    }
    console.log("231", obj);
    callApi("POST", "live_track_insert.php", obj)
      .then(res => res.data)
      .then(response => {
        $("#in_time").modal("hide");
        $("#another-reason").modal("hide");
        this.setState({ isLoading: false });
      }).catch((err) => {
        console.log(err);
        ToastsStore.error("Your Reason Not Stored");
        this.setState({ isLoading: false });
      })
  }
  onchangeReason = (e: any) => {
    if (e.target.value == "other") {
      // this.textareaRef.current.focus();
      this.setState({
        showModal: true
      }, () => {
        this.textareaRef.current.focus();
      });
      this.setState({ in_reason: "", selectVal: false })
      $("#in_time").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }


  };
  getFlowChart = () => {
    callApi("POST", "get_flow_chart.php", {
      fileId: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data[0]) {
          this.setState({ flowChartPreview: response.data[0].fileContent || "" })
        } else {
          this.setState({ flowChartPreview: "" })
        }
      });
  }
  getQueries = () => {
    callApi("POST", "query_get.php", { fod_fk: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        this.setState({ queries: response.data });
        $(document).ready(function () {
          $("#simpletable").DataTable();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getUsers = () => {
    const remote_users: any = [];
    callApi("POST", "office_details.php", {
      id: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        response.data.map(user => {
          remote_users.push({ email: user.email, name: user.name });
        });
        this.setState({
          office_users: response.data,
          remote_users,
          remote_cc_users: remote_users
        });
      });
  };
  getQueryQuestion = () => {
    callApi("POST", "query_question_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({
          queryQues: response.data,
          oldQueryQues: JSON.parse(JSON.stringify(response.data))
        });
      });
  }
  getTemplates = () => {
    callApi("POST", "bank_temp_get.php")
      .then(res => res.data)
      .then(response => {
        let banks = response.data;
        const vetting = banks.filter(item => item.name.split(" ").pop() != "Vetting");
        this.setState({ bank_templates: vetting });
        var bankTempName = [];
        bankTempName = vetting;
        localStorage.setItem("BankTemplate", JSON.stringify(bankTempName))
        // console.log(localStorage.getItem("BankTemplate"));
      })
      .catch(err => {
        console.log(err);
      });
  };
  initDocumentDetail = () => {
    return {
      total: 0,
      yettoopen: 0,
      submitted: 0,
      inprogress: 0
    };
  };
  getFileDashboard = () => {
    const userid1: any = sessionStorage.getItem("user1");
    const user_id = JSON.parse(userid1);
    callApi("POST", "file_dash.php", {
      branch_idfk: this.props.match.params.id,
      user_id: user_id
    })
      .then(res => res.data)
      .then(response => {
        const state = response.data;
        const process_officer: any = sessionStorage.getItem("process_office_id");
        const userid1: any = sessionStorage.getItem("user1");
        const take_file: any = sessionStorage.getItem("takenfile_id");
        const user: any = sessionStorage.getItem("user");
        // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
        const getfileId: any = sessionStorage.getItem("currentfileId");
        if (response.info && response.info.length) {
          if (process_officer == userid1 && take_file == getfileId) {
            if (response.info[0].target_min == 0) {
              $("#balance-time").modal("show");
            } else {
              let initialTimeInMinutes: any = sessionStorage.getItem('updatedRemainingTime');
              if (initialTimeInMinutes > 0) {
                this.setState(
                  (prevState: any) => ({
                    initialTimeInMinutes: initialTimeInMinutes
                  }), () => { }
                );
              } else if (response.info) {
                this.setState(
                  {
                    initialTimeInMinutes: response.info[0].target_min
                  }, () => { }
                );
              } else {
                this.setState(
                  {
                    initialTimeInMinutes: 0
                  }, () => { }
                );
              }
            }
          }
          state.info = response.info[0];
          state.bank_id = state.info.bank_id;
          this.setState({ doc_count: response.info[0].doc_count })
          if (state.bank_id == 0) {
            this.setState({ workfileEnable: true })
          } else {
            this.setState({ workfileEnable: false })
          }
        }
        this.setState({ ...state });
        $(document).ready(function () {
          $("#simpletable").DataTable();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getStepStatus = () => {
    callApi("POST", "steps.php", { id: this.props.match.params.id })
      .catch(res => res.data)
      .then(response => {
        if (response.data.step) {
          this.setState({ step: response.data.step });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  confirmUpdateFileStatus = file_status => {
    if (this.state.info.file_status === "0") {
      this.setState({ reason: "" });
      $("#reason-modal").modal("show");
      const info = this.state.info;
      info.file_status = file_status;
      this.setState({ info });
      return;
    } else {
      file_status = this.state.info.file_status === "0" ? file_status : "0";
      this.updateFileStatus(file_status);
    }
  };
  submitReason = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "reason_create.php", {
      userId,
      action: this.state.info.file_status === "3" ? "hold" : "reject",
      reason: this.state.reason,
      fod_fk: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        $("#reason-modal").modal("hide");
        this.updateFileStatus(this.state.info.file_status);
      })
      .catch(err => {
        console.log(err);
      });
  };
  updateFileStatus = file_status => {
    callApi("POST", "file_status.php", {
      id: this.props.match.params.id,
      file_status
    })
      .then(res => res.data)
      .then(response => {
        this.getFileDashboard();
      })
      .catch(err => {
        console.log(err);
      });
  };
  modifyQueryStatus = modal => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "query_close.php", {
      id: modal.query_id,
      status: modal.query_status === "Pending" ? 3 : 0,
      userId
    })
      .then(res => res.data)
      .then(response => {
        // this.getQueries();
        this.getFileDashboard();
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    this.setState({ [fieldName]: fieldValue });
    if (fieldName !== "reason") {
      this.saveTemplate(fieldValue);
    }
  };
  handleChange1 = (e: any) => {
    const info: any = this.state.info;

    info[e.target.name] = e.target.value;
    this.setState({});
  };
  handleChange2 = (e: any) => {
    const info: any = this.state.info;

    info[e.target.name] = e.target.value;
    this.setState({});
  }
  saveDocNo = (e: any) => {
    console.log("e---->", e);
    this.setState({ docNoYear: e.target.value });
  }
  saveTemplate = val => {
    callApi("POST", "bank_temp_save.php", {
      id: this.props.match.params.id,
      bank_id: val
    })
      .then(res => res.data)
      .then(response => {
        this.setState({ workfileEnable: false })
        localStorage.removeItem("BankTemplate");
        sessionStorage.removeItem('overviewDocument');
        this.getQueries();
        this.getFileDashboard();
      })
      .catch(err => {
        console.log(err);
      });
  };
  uploadFlowchart = (files: any, type: string) => {
    this.setState({ isFlowChartUploading: true });
    const base: any = [];
    files.base64.map((file: any, index: number) => {
      base.push({ base: file, details: files.fileList[index], type });
    });
    if (files.base64.length) {
      callApi("POST", "flow_chart_add.php", {
        fileId: this.props.match.params.id,
        fileName: files.fileList[0].name,
        fileContent: base[0].base.split(",")[1]
      })
        .then(res => {
          this.setState({ isFlowChartUploading: false }, () => {
            this.getFlowChart()
          });
          ToastsStore.success("File(s) Uploaded Successully");
        })
        .catch(err => {
          this.setState({ isFlowChartUploading: false });
        });
    }
  };
  deleteFlowChart = () => {
    const ParamId = this.props.match.params.id;
    this.setState({ isFlowChartUploading: true });
    callApi("POST", "delete_flow_chart.php", {
      fileId: ParamId,
    })
      .then(res => {
        this.setState({ isFlowChartUploading: false }, () => {
          this.getFlowChart()
        })
        ToastsStore.success("File(s) Deleted Successully");
      })
      .catch(err => {
        this.setState({ isFlowChartUploading: false });
      });
  }
  uploadFiles = () => {
    const isValid = this.validateDocuments();
    let updatedRemainingTime: any = sessionStorage.getItem('updatedRemainingTime');
    let updatedtime = Math.round(updatedRemainingTime);
    if (!isValid) {
      // Show error message or handle validation error
      ToastsStore.error("Please fill in all fields for this document");
      return;
    } else {
      this.setState({ isbtnload: true });
      this.setState({ uploadbtn: true });
      let flag_status;
      if (this.state.info.file_status == "7") {
        flag_status = 1;
      } else {
        flag_status = 0;
      }
      callApi("POST", "doc_file_upload.php", {
        id: this.props.match.params.id,
        uploadfile: this.state.base,
        flag_status: flag_status
      })
        .then(res => res)
        .then(response => {
          const responseData = response.data;
          let doc_count = Number(this.state.base.length) + Number(this.state.doc_count);
          if (responseData.success === true) {
            let request = {
              file_id: this.props.match.params.id,
              doc_count: doc_count,
              balance_min: updatedtime
            }
            callApi("POST", "file_time.php", request)
              .then(res => res.data)
              .then(response => {
                if (response.status == "200") {
                  if (this.countdownTimerRef) {
                    this.countdownTimerRef.clearInterval();
                  }
                  sessionStorage.removeItem("updatedRemainingTime");
                  window.location.reload();
                }
              });
            if (this.state.info.file_status == "7") {
              this.timeUpdate(this.props.match.params.id, "query receive");
            } else {
              console.log("not query raised");
              this.timeUpdate(this.props.match.params.id, "before query raise");
            }
            this.setState({ isbtnload: false });
            this.setState({ uploadbtn: false });
            this.setState({ base: [] });
            $("#upload-modal").modal("hide");
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            const userName = user ? JSON.parse(user)["firstName"] : "";
            const notifyData = {
              fileId: this.props.match.params.id, title: `${this.props.match.params.id}Query Upload`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            };
            let user_id = userId;
            pushnotificationApi(notifyData, user_id);
            const branchManger: any = sessionStorage.getItem("branch_manager");
            const branch_Manager = JSON.parse(branchManger);
            const queryOfficer: any = sessionStorage.getItem("Query_officer");
            const query_ooficer = JSON.parse(queryOfficer);
            const Scan_officer: any = sessionStorage.getItem("scan_officer");
            const sacanofficer = JSON.parse(Scan_officer);
            const process_mennager: any = sessionStorage.getItem("process_manager");
            const processManager = JSON.parse(process_mennager);
            if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
              notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
              const branchManager = branch_Manager.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: branchManager,
                ...notifyData
              }
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
              notifyToUser("user", { ...notifyData, notifyType: "message" }, query_ooficer);
              const Queryofficer = query_ooficer.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: Queryofficer,
                ...notifyData
              }
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
              notifyToUser("user", { ...notifyData, notifyType: "message" }, sacanofficer);
              const ScanOfficer = sacanofficer.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: ScanOfficer,
                ...notifyData
              }
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (Array.isArray(processManager) && processManager.length > 0) {
              notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
              const ProcessManager = processManager.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: ProcessManager,
                ...notifyData
              }
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            ToastsStore.success("File(s) Uploaded Successully");
          } else {
            this.setState({ uploadbtn: false });
            this.setState({ isbtnload: false });
            ToastsStore.success("File(s) Not Uploaded");
          }
        })
        .catch(err => {
          this.setState({ isbtnload: false });
          this.setState({ base: [] });
        });
    }
  };
  validateDocuments = () => {
    let base: any = this.state.base;
    let isValid = true;
    // Check for empty fields in the base array
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (document.type == "deed" || document.type == "mother_doc" || document.type == "title_deed" || document.type == "ec_doc") {
        if (!document.docNoYear || !document.doc_type || !document.type || !document.particular || !document.base) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else if (document.type == "rev_rec_doc" || document.type == "sale_agreement") {
        if (!document.doc_type || !document.type || !document.particular || !document.base) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else {
        isValid = false;
        base[i].isValid = false;
      }
    }

    return isValid;
  };
  timeUpdate = (fileid, type) => {
    const loginuser: any = sessionStorage.getItem("user1");
    let req;
    if (type == "query receive") {
      req = {
        query_received_by: JSON.parse(loginuser),
        id: fileid,
        reason: "Query Received",
        created_by: JSON.parse(loginuser),
        color: "#edada0"
      }
    } else if (type == "query raise") {
      req = {
        query_raised_by: JSON.parse(loginuser),
        id: fileid,
        reason: "Query Raised",
        created_by: JSON.parse(loginuser),
        color: "#afa5bd"
      }
    } else if (type == "before query raise") {
      req = {
        id: fileid,
        reason: "Scan File Upload",
        created_by: JSON.parse(loginuser),
        reason_status: 1
      }
    }
    else {
      req = {
        // id: fileid,
        // reason: "File Out",
        // created_by: JSON.parse(loginuser)
      }
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }
  handleChangeCategory = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;

    if (fieldName === "category") {
      // const employee_id = fieldValue;
      this.setState({ type: fieldValue });
    }
    if (fieldName === "particular") {
      const bank_name = fieldValue;
      console.log("267", bank_name);
      this.setState({ [fieldName]: fieldValue });
    }
  }
  selectUser = (e: any, user: any) => {
    let remote_users: any = this.state.remote_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users = remote_users.filter(itm => itm.email !== user.email);
    }
    this.setState({ remote_users });
  };
  selectCcUser = (e: any, user: any) => {
    let remote_users: any = this.state.remote_cc_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users = remote_users.filter(itm => itm.email !== user.email);
    }
    this.setState({ remote_cc_users: remote_users });
  };
  addItem = (): void => {
    // const querydetail: Query[] = this.state.querydetail
    //   ? this.state.querydetail
    //   : [];
    // querydetail.push({ query: "", review: "" });
    // this.setState({ querydetail });
  };
  handleFiles = (files: any) => {
    let baseFile: any = this.state.queryBase || [];
    const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
    if (Number(fileSize) > 26214400) {
      ToastsStore.error("File(s) size shouldn't exceed 25 MB");
    } else {
      files.base64.map((file: any, index: number) => {
        baseFile.push({ file, fileType: files.fileList[index].type, fileName: files.fileList[index].name });
      });
      this.setState({ queryBase: [...baseFile] });
    }
  };
  deleteDoc = (modal, index) => {
    const baseFile: never[] = cloneDeep(this.state.queryBase);
    baseFile.splice(index, 1);
    this.setState({ queryBase: [...baseFile] });
  };
  previewDoc = (modal) => {
    this.setState({ previewDoc: modal.file });
  };
  setSelectedItem = (item: any) => {
    let itemList = Array.isArray(item) ? [...item] : [item];
    let { multiSelectedQuery } = this.state;
    let tempArray: any[] = multiSelectedQuery;
    for (let i = 0; i < itemList.length; i++) {
      if (tempArray.find((val: any) => val.query_id === itemList[i].query_id)) {
        tempArray.splice(multiSelectedQuery.findIndex((val: any) => val.query_id === itemList[i].query_id), 1);
      } else tempArray.push(itemList[i]);
    }
    this.setState({ multiSelectedQuery: tempArray });
  }
  repliedQueries = (queries: any) => {
    this.setState({
      repliedQueries: queries,
      queryPayload: {
        requery: [],
        resend: []
      }
    }, () => this.formatData(queries));
  }
  getNewQuery = (newquerys: any) => {
    this.setState({ newquery: newquerys });
    console.log("newQueryList  1", newquerys);
  }
  formatData = (queries) => {
    const queryData: any = this.state.queryPayload;
    queries.forEach((query) => {
      if (query.hasOwnProperty('replies') && query.replies && query.replies.length > 0) {
        queryData.requery.push(query);
        this.setState({ queryPayload: queryData }, () => {
          if (query.children.length > 0) {
            this.formatData(query.children);
          }
        });
      } else if (query.children.length > 0) {
        this.formatData(query.children);
      }
    });
  }
  saveQuery = (): void => {
    const { multiSelectedQuery, remote_users, queryBase, remote_cc_users } = this.state;
    const queryData: any = this.state.queryPayload;
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const process_office_id: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    const payload: any = {
      querydetail: [...multiSelectedQuery, ...this.state.newquery],
      remote_users: remote_users,
      remote_cc_users,
      user_idfk: userId,
      fod_fk: this.props.match.params.id,
      base: queryBase
    }
    queryData.requery.forEach((query) => {
      query.replies.forEach((reply) => {
        const Obj = {
          query: reply.query,
          review: reply.review,
          other_review: reply.other_review,
          location: reply.location,
          query_id: query.id,
          created_date: moment().format("DD-MM-YYYY HH:mm:ss")
        }
        payload.querydetail.push(Obj);
      });
    });
    this.setState({ queryLoading: true });
    callApi("POST", "new_query_create.php", payload)
      .then(res => res.data)
      .then(response => {
        localStorage.setItem("query_raised_status", JSON.stringify(true));
        if (process_office_id == userid1) {
          this.timeUpdate(this.props.match.params.id, "query raise");
        }
        this.setState({ queryLoading: false, quesSubmitted: false });
        $('#send-mail').modal('hide');
        ToastsStore.success("Queries added Successully");
        this.setState({
          queryPayload: {
            requery: [],
            resend: []
          },
          multiSelectedQuery: [],
          newquery: []
        }, () => {
          this.query.current.updateQuery();
        })
        // sessionStorage.removeItem("currentfileId")
        setTimeout(() => {
          this.props.history.push("/inprogress-file");
        }, 2000);
      })
      .catch(err => {
        this.setState({ queryLoading: false, quesSubmitted: true });
        // $('#send-mail').modal('hide');
        ToastsStore.error("Something went Wrong, Please try again!");
      });
  }
  handleAnswerChange = (index, val) => {
    const queryQues: any = this.state.queryQues;
    queryQues[index].answer = val;
    this.setState({ queryQues });
  }
  saveQueryQuestion = () => {
    const { queryQues, oldQueryQues } = this.state;
    const user: any = sessionStorage.getItem("user");
    const obj = {
      fileNo: this.props.match.params.fileid,
      currentData: queryQues,
      oldData: oldQueryQues,
      loginId: user ? JSON.parse(user)["userId"] : ""
    }

    callApi("POST", "queryverification_checklist.php", obj)
      .then(res => res.data)
      .then(response => {
        this.setState({ quesSubmitted: true });
      })
      .catch(err => {
      });
  }

  getUpdateReasonData = () => {
    const reasonData: any = this.state.updateDownloadData;
    callApi('POST', 'dashboard_log.php', { id: this.props.match.params.id })
      .then(res => res.data)
      .then(result => {
        reasonData.reasonForDownload = result.data.reasonForDownload;
        // Object.keys(result.data[0][1]).map(key => result.data[0][1][key]);
        reasonData.updatedDetails = result.data.updatedDetails
        // result.data[0][0];
        this.setState({ updateDownloadData: reasonData });
      })
      .catch(err => {
        // ToastsStore.error("Failed to get ");
      });
  }

  renderDetailsCard = (type, listOfData) => {
    return (
      listOfData && listOfData.map((listVal, listIndex) => {
        return (
          <div className="row" key={`${listIndex}-${listVal.title}`} style={{ background: listIndex === 0 && type === "UPDATE" ? '#cdffe0' : '#eff3f6', boxShadow: '0 0 0 1px #e2e5e8', borderRadius: '0.25rem', padding: '10px', paddingLeft: '12px', paddingRight: '12px', marginBottom: '15px' }}>
            <div style={{ width: '100%' }}>
              <div><p style={{ fontWeight: 'bold', fontSize: '14px', color: 'black', textTransform: 'capitalize' }}>{listVal.title || ""}</p></div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ margin: '0px', color: 'grey', fontSize: '13px' }}>
                    {moment(listVal.date).format("DD-MM-YYYY hh:mm a")} <br />
                  </p>
                  {listVal.officerType && <p style={{ margin: '0px', color: 'black', fontSize: '13px', fontWeight: 'bold', marginLeft: '10px', marginRight: '10px', textTransform: 'capitalize' }}>{listVal.officerType}</p>}
                  {listVal.downloadedBy && <p style={{ margin: '0px', fontSize: '13px', color: 'grey' }}>{type === "DOWNLOAD" ? "Downloaded By " : "Updated By "} <span style={{ color: 'black', fontWeight: 'bold' }}>{listVal.downloadedBy || ""}</span></p>}
                </div>
                {type === "UPDATE" ?
                  <div onClick={() => {
                    callApi("POST", "get_branch_code.php", {
                      id: this.props.match.params.id
                    }).then(res => res.data)
                      .then(response => {
                        const name = `${response.data[0].lawhands_branch}-${this.props.match.params.id}.docx`;
                        Array.isArray(listVal.url) && listVal.url[0].docx &&
                          saveAs(listVal.url[0].docx, name)
                      })
                  }}>
                    <i className="icon feather icon-download" style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', cursor: 'pointer' }} />
                  </div>
                  : null}
              </div>
            </div>
          </div>
        )
      })
    )
  }

  uploadFile = (file: any) => {
    this.setState({ uploadFile: file });
  }
  actionHandle = (action) => {
    this.setState({ action });
  }
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  validateReason = () => {
    if (this.state.reason === "") {
      ToastsStore.error("Please Enter Reason")
    } else {
      if (this.state.action === "upload") {
        $("#reason-modal1").modal("hide");
        this.uploadDoc();
      }
    }
  }

  uploadDoc = () => {
    $("#reason-modal1").modal("hide");
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const obj = {
      fileId: this.props.match.params.id,
      reason: this.state.reason,
      createdBy: userId,
      file: this.state.uploadFile.base64.split(",")[1],
      errorTypes: ["1", "2"],
      errorSection: ["1", "2"],
    }
    callApi("POST", "template/tests/upload_log.php", obj)
      .then(res => res)
      .then(response => {
        console.log(response);
        sessionStorage.removeItem('overviewDocument');
        if (response.status === 200) {
          // ToastsStore.success("work file Q & A are saved succesfully")
          ToastsStore.success("Reason saved successfully");
          this.clearModal();
          this.getUpdateReasonData();
        } else {
          ToastsStore.error("Error in storing, Please try again!")
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  clearModal = () => {
    this.setState({
      reason: "",
    });
  }

  editOption = () => {
    $('.editicon').hide();
    $('.appidShow').hide();
    $('.appidEdit').show();
    $(document).ready(function () {
      $('#appid').focus();
    });
  };
  deleteDocumentDetails = (index) => {
    const baseArray = cloneDeep(this.state.base);
    baseArray.splice(index, 1);
    this.setState({ base: baseArray });
  };
  // handleDocumentDetailsChange = (value, index, key) => {
  //   let baseArray = cloneDeep(this.state.base);
  //   baseArray[index] = {
  //     ...baseArray[index],
  //     [key]: value,
  //   };
  //   this.setState({ base: baseArray });
  // };
  handleDocumentDetailsChange = (value, index, key) => {
    console.log(`${index}---${value}---${key}`);

    if (key == "docNoYear") {

      const isValidInput = /^[0-9/-]*$/.test(value);

      if (!isValidInput) {
        // Display an error message or handle the invalid input as needed
        console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
        return;
      }
      let baseArray = cloneDeep(this.state.base);
      baseArray[index] = {
        ...baseArray[index],
        [key]: value,
      };
      this.setState({ base: baseArray });
    } else {

      let baseArray = cloneDeep(this.state.base);
      baseArray[index] = {
        ...baseArray[index],
        [key]: value,
      };
      this.setState({ base: baseArray });
      console.log("upload_doc_base", baseArray);
    }
  };
  handleDocumentFilesChange1 = (value, index, key) => {
    let baseArray = cloneDeep(this.state.base);
    baseArray[index] = {
      ...baseArray[index],
      base: value.fileList[0].name,
      base_url: value.base64,
    };
    this.setState({ base: baseArray });
    console.log("this.state", value);

  }
  addUploadDocument = () => {
    let baseArray = cloneDeep(this.state.base);
    baseArray.push(this.documentFormat);
    this.setState({ base: baseArray });
  };
  validateRequiredFileds = (e) => {
    if (this.state.base && this.state.base.length > 0) {
      console.log("state", this.state.base);
      this.uploadFiles();
    } else {
    }
  };
  closeuploadfile = () => {
    this.setState({ base: [] });
  }
  deleteFile = (index) => {
    const baseArray = cloneDeep(this.state.base);
    baseArray[index] = {
      ...baseArray[index],
      details: {},
      base: "",
    };
    this.setState({ base: baseArray });
  };
  validateQuery = (payload) => {
    console.log("querydetail---", payload);

    // console.log("document", req);
    let querydetail: any = payload;
    let base = querydetail.querydetail;
    console.log("validateQuery", base);

    let isValid;
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (document && document.review == "other") {
        if (!document.query || !document.review || !document.other_review) {
          isValid = false;
          base[i].isValid = false;
        } else {
          isValid = true;
          base[i].isValid = true;
        }
      } else if (document && document.review != "other") {
        if (!document.query || !document.review) {
          console.log("not other false");
          isValid = false;
          base[i].isValid = false;
        } else {
          console.log("not other true");
          isValid = true;
          base[i].isValid = true;
        }
      } else { }
      console.log("isValid", isValid);
    }
    if (isValid) {
      $("#send-mail").modal("show");
    } else {
      ToastsStore.error("Please fill all the required fields");
    }
  };
  updateNewQueryList = () => {
    const { multiSelectedQuery, remote_users, queryBase, remote_cc_users } = this.state;
    const queryData: any = this.state.queryPayload;
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const payload: any = {
      querydetail: [...multiSelectedQuery, ...this.state.newquery],
      remote_users: remote_users,
      remote_cc_users,
      user_idfk: userId,
      fod_fk: this.props.match.params.id,
      base: queryBase
    }
    queryData.requery.forEach((query) => {
      query.replies.forEach((reply) => {
        const Obj = {
          query: reply.query,
          review: reply.review,
          other_review: reply.other_review,
          location: reply.location,
          query_id: query.id,
          created_date: moment().format("DD-MM-YYYY HH:mm:ss")
        }
        payload.querydetail.push(Obj);
      });
    });
    console.log("payload", payload);
    if (payload && payload.querydetail.length > 0) {
      this.validateQuery(payload);
    } else {
      $("#send-mail").modal("show");
    }

    // if (isValid) {
    //   $("#send-mail").modal("show");
    // } else {
    //   ToastsStore.error("Please fill all the required fields");
    // }
    this.getQueryQuestion();
    this.getUsers();
  }
  AppidSave = () => {
    const obj = {
      fileid: this.props.match.params.id,
      appid: this.state.info.app_id
    }
    callApi("POST", "appid_ubdate.php", obj)
      .then(res => res)
      .then(response => {
        if (response.status === 200) {
          sessionStorage.removeItem('overviewDocument');
          // ToastsStore.success("work file Q & A are saved succesfully")
          ToastsStore.success("App Id Ubdated successfully");
          $('.appidShow').show();
          $('.appidEdit').hide();
          $('.editicon').show();
          this.clearModal();
          this.getUpdateReasonData();
        } else {
          ToastsStore.error("Error in storing, Please try again!")
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  handleReasonChange = (e) => {
    this.setState({ reason: e.target.value });
  }
  updatedRemainingTime(time) {
    this.setState(
      (prevState: any) => ({
        updatedRemainingTime: time
      }),
      () => {
      }
    );
  }

  overviewtime(type) {
    const loginuser: any = sessionStorage.getItem("user1");
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    let obj;
    if (type == "Typing Completed") {
      obj = {
        id: this.props.match.params.id,
        typingcompleted_time: currenttime,
        typingcompleted_date: currentdate,
        typingcompleted_by: JSON.parse(loginuser),
        reason: "Typing Completed",
        created_by: JSON.parse(loginuser),
        color: "#edada0"
      };
    } else {
      obj = {
        id: this.props.match.params.id,
        reason: "Verification",
        created_by: JSON.parse(loginuser),
        color: "#faad4c"
      }
    }
    callApi("POST", "time_update.php", obj)
      .then(res => res.data)
      .then(response => {
      });
  }
  completeFlowHandler = (status, type) => {
    this.setState({ showTitle: true })
    if (type == "typing") {
      this.overviewtime("Typing Completed");
    } else {
      this.overviewtime("Verification");
    }
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const report_to = user ? JSON.parse(user)["report_to"] : "";
    // file status 6 => moved to "files to be verfied"	
    callApi("POST", "file_status.php", {
      id: this.props.match.params.id,
      file_status: status,
    })
      .then((res) => res)
      .then((response) => {
        console.log(response);
        this.setState({ showTitle: false })
        if (response.status === 200) {
          $("#confirm-complete").modal("hide");
          $("#processComplete").modal('show');
          const user: any = sessionStorage.getItem("user");
          const userId = user ? JSON.parse(user)["userId"] : "";
          const userName = user ? JSON.parse(user)["firstName"] : "";
          const notifyData = {
            title: `${this.props.match.params.id}Typing Completed By ${userName}`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
          };
          pushnotificationApi(notifyData, userId);
          const branchManger: any = sessionStorage.getItem("branch_manager");
          const branch_Manager = JSON.parse(branchManger);
          const queryOfficer: any = sessionStorage.getItem("Query_officer");
          const query_ooficer = JSON.parse(queryOfficer);
          const Scan_officer: any = sessionStorage.getItem("scan_officer");
          const sacanofficer = JSON.parse(Scan_officer);
          const process_mennager: any = sessionStorage.getItem("process_manager");
          const processManager = JSON.parse(process_mennager);
          if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
            const branchManager = branch_Manager.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: branchManager,
              ...notifyData,
              reason: `${this.props.match.params.id}Typing Completed`
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "notify" }, query_ooficer);
            const OfficerQuery = query_ooficer.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: OfficerQuery,
              ...notifyData,
              reason: `${this.props.match.params.id}Typing Completed`
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(processManager) && processManager.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
            const ProcessManager = processManager.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: ProcessManager,
              ...notifyData,
              reason: `${this.props.match.params.id}Typing Completed`
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          ToastsStore.error("Error in completing the file , Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  close = () => {
    $("#processComplete").modal('hide');
    sessionStorage.removeItem("currentfileId")
    setTimeout(() => {
      this.props.history.push("/inprogress-file");
    }, 2000);
  }
  confirmComplete(status, type) {
    this.setState({ iscomplete: true }, () => {
      if (this.state.iscomplete == true) {
        this.completeFlowHandler(status, type);
      }
    });
  }
  processComplete(val) {
    if (val == "process") {
      this.setState({ isprocesscomplete: true });
    } else {
      this.setState({ isprocesscomplete: false });
    }
  }
  render() {
    const editCss = {
      marginTop: '-10px',
      // boxShadow: '0 0 4px #101010',
      width: 'fit-content',
      padding: '7px',
      borderRadius: '13px',
    }
    const fileurl: any = localStorage.getItem("url");
    // console.log("807", fileurl);
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    // const role_id = "4";
    const roleType = user ? JSON.parse(user)["roleType"] : "";
    const { showTitle, selectVal, multiSelectedQuery, queryLoading, queryQues, quesSubmitted, updateDownloadData, isReasonForDownloadShown, isUpdateDownloadShown, uploadFile, reason, setResponse, workfileEnable, initialTimeInMinutes } = this.state;
    const banktemplatesoption = [
      { value: "", label: "All" },
      ...this.state.bank_templates
    ];
    const process_officer: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    let RemainingTime: any = sessionStorage.getItem('updatedRemainingTime');
    const take_file: any = sessionStorage.getItem("takenfile_id");
    // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
    const getfileId: any = sessionStorage.getItem("currentfileId");
    let initialtime;
    if (initialTimeInMinutes && initialTimeInMinutes > 0 && take_file == getfileId) {
      initialtime = initialTimeInMinutes;
    } else {
      initialtime = RemainingTime;
    }
    // console.log("file initial time", initialtime);

    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header" style={{ padding: '12px ' }}>
                          <div className="row">
                            <div className="col-md-12">
                              {process_officer == userid1 && initialtime && initialtime > 0 && take_file == getfileId && (
                                <CountdownTimer
                                  initialTime={initialtime}
                                  updatedTime={(time) => this.updatedRemainingTime(time)}
                                  ref={(ref) => (this.countdownTimerRef = ref)}
                                />
                              )}
                            </div><br />
                          </div>
                          <div className="row">
                            <div className="col-md-9">
                              <div className="d-flex">
                                <h5>File No - {this.state.info.file_name}</h5><br />
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>Login Date - {this.state.info.login_time}</h5>
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>Bank Name - {this.state.info.bank_name} {this.state.info.product_name ? <>({this.state.info.product_name})</> : ""}</h5>
                              </div>
                              <div className="d-flex" style={{ marginTop: "2%" }}>
                                <h5 >PO - {this.state.info.process_officer}</h5>
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>VO - {this.state.info.verify_officer}</h5>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div style={{ display: 'flex' }}>
                                <h5>App Id:</h5>
                                <div style={editCss}>
                                  <b className="appidShow" style={{ fontSize: '16px', color: '#111', fontWeight: 500 }}>{this.state.info.app_id}
                                  </b><input
                                    type="text"
                                    className="form-control appidEdit"
                                    placeholder="AppId"
                                    id="appid"
                                    name="app_id"
                                    style={{ width: 'fit-content', display: 'none', border: 'none', height: '18px' }}
                                    onChange={e => this.handleChange1(e)}
                                    onBlur={() => this.AppidSave()}
                                    value={this.state.info.app_id}
                                  /> <span> <FaRegEdit onClick={() => this.editOption()} style={{ marginBottom: '6px' }} className="editicon" /></span>
                                </div>
                              </div>
                              <span className="badge badge-primary f-14" style={{ fontSize: "15px", marginTop: "3%" }}>
                                {getFileStatus(this.state.info.file_status)}
                              </span>
                            </div>
                          </div>

                          <div style={{ marginTop: "2%" }}>
                            {(roleType !== "7" && roleType !== "8") && (
                              <button
                                style={{ margin: "auto" }}
                                className="ml2 btn btn-primary btnupload float-right"
                                data-toggle="modal"
                                data-target="#upload-modal"
                                onClick={() => this.getCategories()}
                              >
                                Upload
                              </button>
                            )}
                            <Link

                              to={`${fileurl}`}
                              className="btn btn-square float-right"
                              style={{ color: 'black', border: '1px solid black', borderRadius: '5px' }}
                            >
                              Back
                            </Link>
                            {workfileEnable ? (<>{(role_id !== FRONTOFFICE_ROLE) ? (
                              <>
                                {this.state.info.opinion_type != "1" && this.state.info.opinion_type != "0" && this.state.info.opinion_type != null ? (
                                  this.state.info.file_status === "6" ? (
                                    <button
                                      className="btn btn-success"
                                      data-toggle="modal"
                                      data-target="#confirm-complete"
                                      onClick={() => this.processComplete("verify")}
                                      style={{ marginTop: "0px", float: "right" }}
                                    >
                                      Verification Completed
                                    </button>
                                  ) : (this.state.info.file_status != "5" ?
                                    (
                                      <>
                                        <button
                                          onClick={() => this.processComplete("process")}
                                          data-toggle="modal"
                                          data-target="#confirm-complete"
                                          type="button"
                                          className="btn btn-primary"
                                          style={{ marginTop: "0px", float: "right" }}
                                        >
                                          Typing Completed
                                        </button>
                                      </>
                                    ) : "")
                                ) : ""
                                }
                                <button
                                  className="ml2 btn btn-success btnupload float-right"
                                  onClick={() => this.OPenToaster()}
                                >
                                  Work File
                                </button>
                                <button
                                  className="ml2 btn btn-success btnupload float-right"
                                  disabled={this.state.isFlowChartUploading}
                                  onClick={() => {
                                    this.props.history.push("/sale-deed/" + this.props.match.params.id)
                                  }}
                                >
                                  Flowchart
                                </button>
                                {this.state.query_tree_length < 1 &&
                                  <>
                                    <Link
                                      to={"/query/add/0/" + this.props.match.params.id}
                                      className="btn btn-square btn-info float-right"
                                    >
                                      Raise Query
                                    </Link>
                                  </>
                                }
                                <Link
                                  to={"/vetting-overview/" + this.props.match.params.id}
                                  className="btn btn-square btn-info float-right"
                                >
                                  Vetting
                                </Link>
                              </>
                            ) :
                              <>
                                <Link
                                  to={"/vetting-overview/" + this.props.match.params.id}
                                  className="btn btn-square btn-info float-right"
                                >
                                  Vetting
                                </Link>
                                {role_id == FRONTOFFICE_ROLE &&
                                  <>
                                    <Link
                                      to={"/work-file/7/" + this.props.match.params.id}
                                      className="btn btn-square btn-success float-right"
                                    >
                                      Work File
                                    </Link>
                                    <button
                                      className="ml2 btn btn-success btnupload float-right"
                                      onClick={() => {
                                        this.props.history.push("/sale-deed/" + this.props.match.params.id)
                                      }}
                                    >
                                      Flowchart
                                    </button>
                                  </>
                                }
                              </>
                            }
                            </>
                            ) : (
                              <>{(role_id !== FRONTOFFICE_ROLE) ? (
                                <>
                                  {this.state.info.opinion_type != "1" && this.state.info.opinion_type != "0" ? (
                                    this.state.info.file_status === "6" ? (
                                      <button
                                        className="btn btn-success"
                                        data-toggle="modal"
                                        data-target="#confirm-complete"
                                        onClick={() => this.processComplete("verify")}
                                        style={{ marginTop: "0px", float: "right" }}
                                      >
                                        Verification Completed
                                      </button>
                                    ) : (this.state.info.file_status != "5" ?
                                      (
                                        <>
                                          <button
                                            onClick={() => this.processComplete("process")}
                                            data-toggle="modal"
                                            data-target="#confirm-complete"
                                            type="button"
                                            className="btn btn-primary"
                                            style={{ marginTop: "0px", float: "right" }}
                                          >
                                            Typing Completed
                                          </button>
                                        </>
                                      ) : "")
                                  ) : ""
                                  }
                                  <Link
                                    to={"/work-file/7/" + this.props.match.params.id}
                                    className="btn btn-square btn-success float-right"
                                  >
                                    Work File
                                  </Link>
                                  <button
                                    className="ml2 btn btn-success btnupload float-right"
                                    onClick={() => {
                                      this.props.history.push("/sale-deed/" + this.props.match.params.id)
                                    }}
                                  >
                                    Flowchart
                                  </button>
                                  {this.state.query_tree_length < 1 &&
                                    <>
                                      <Link
                                        to={"/query/add/0/" + this.props.match.params.id}
                                        className="btn btn-square btn-info float-right"
                                      >
                                        Raise Query
                                      </Link>
                                    </>
                                  }
                                  <Link
                                    to={"/vetting-overview/" + this.props.match.params.id}
                                    className="btn btn-square btn-info float-right"
                                  >
                                    Vetting
                                  </Link>
                                </>
                              ) :
                                <>
                                  {role_id != FRONTOFFICE_ROLE &&
                                    <Link
                                      to={"/vetting-overview/" + this.props.match.params.id}
                                      className="btn btn-square btn-info float-right"
                                    >
                                      Vetting
                                    </Link>}
                                  {roleType === "10" || role_id == FRONTOFFICE_ROLE &&
                                    <Link
                                      to={"/work-file/7/" + this.props.match.params.id}
                                      className="btn btn-square btn-success float-right"
                                    >
                                      Work File
                                    </Link>
                                  }
                                  {roleType === "9" || role_id == FRONTOFFICE_ROLE &&
                                    <Link
                                      to={"/work-file/7/" + this.props.match.params.id}
                                      className="btn btn-square btn-success float-right"
                                    >
                                      Work File
                                    </Link>
                                  }
                                  {role_id == FRONTOFFICE_ROLE &&
                                    <>
                                      <button
                                        className="ml2 btn btn-success btnupload float-right"
                                        onClick={() => {
                                          this.props.history.push("/sale-deed/" + this.props.match.params.id)
                                        }}
                                      >
                                        Flowchart
                                      </button>
                                    </>
                                  }

                                </>
                              }</>)}
                            {role_id !== FRONTOFFICE_ROLE && (
                              <div className="template-select">
                                {this.state.bank_templates && this.state.bank_templates.length > 0 && this.state.bank_templates.map((opt: any) => {
                                  opt.label = opt.name; opt.value = opt.id
                                }) &&
                                  <Select options={banktemplatesoption} name="bank_id"
                                    value={this.state.bank_templates.find((o: any) => o.value === this.state.bank_id)}
                                    onChange={(e) => this.handleChange(e, 'bank_id', 'singleselect')}
                                  />
                                }
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="sw-main sw-theme-circles">
                            <ul className="nav nav-tabs step-anchor">
                              <li
                                className={
                                  this.state.step >= 1
                                    ? "nav-item active st_active"
                                    : "nav-item st_active"
                                }
                              >
                                <a className="nav-link" title="Pending">
                                  1
                                </a>
                                <span className="stepLabel">Pending</span>
                                <br />
                              </li>
                              <li
                                className={
                                  this.state.step >= 2
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="Assigned"
                                  data-original-title="General Detail"
                                >
                                  2
                                </a>
                                <span className="stepLabel">Assigned</span>
                              </li>
                              <li
                                className={
                                  this.state.step >= 3
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="General Details (Schedule Of Property)"
                                  data-original-title="Property Detail"
                                >
                                  3
                                </a>
                                <span className="stepLabel">
                                  General Details (Schedule Of Property)
                                </span>
                              </li>
                              <li
                                className={
                                  this.state.step >= 4
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="Document Scrutiny"
                                  data-original-title="Document Scruteny"
                                >
                                  4<br />
                                </a>
                                <span className="stepLabel">
                                  Document Scrutiny
                                </span>
                              </li>
                              <li
                                className={
                                  this.state.step >= 5
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="Original Verification"
                                  data-original-title="File Approval"
                                >
                                  5<br />
                                </a>
                                <span className="stepLabel">
                                  Original Verification
                                </span>
                              </li>
                              {/* <li
                                className={
                                  this.state.step >= 6
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="Legal Checklist"
                                  data-original-title="Legal Checklist"
                                >
                                  6<br />
                                </a>
                                <span className="stepLabel">Legal Checklist</span>
                              </li> */}
                              <li
                                className={
                                  this.state.step >= 6
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="Report Generation"
                                  data-original-title="Legal Checklist"
                                >
                                  6<br />
                                </a>
                                <span className="stepLabel">
                                  Report Generation
                                </span>
                              </li>
                              <li
                                className={
                                  this.state.step >= 7
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <a
                                  className="nav-link"
                                  data-toggle="tooltip"
                                  title="Report Completion"
                                  data-original-title="Legal Checklist"
                                >
                                  7<br />
                                </a>
                                <span className="stepLabel">
                                  Report Completion
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="row">
                            <DocumentNumber
                              documentdetail={this.state.other}
                              categoryname={"Document"}
                            />
                            <DocumentNumber
                              documentdetail={this.state.ec_doc}
                              categoryname={"EC"}
                            />
                            <DocumentNumber
                              documentdetail={this.state.rev_rec_doc}
                              categoryname={"Revenue Record"}
                            />
                          </div>

                          {updateDownloadData ?
                            <div className="row" style={{ marginBottom: "20px" }}>

                              <div className="col-md-6">
                                <div className="accordion" id="download-reason">
                                  <div className="card addon-card">
                                    <a className="accordion-toggle addon-accordion" style={{ backgroundColor: 'white' }} data-toggle="collapse" data-parent="#download-reason" href="#downloadReason">
                                      <div className="card-header" style={{ borderBottomWidth: '0px', padding: '0px', justifyContent: 'space-between', display: 'flex' }} onClick={() => { this.setState({ isReasonForDownloadShown: !isReasonForDownloadShown }) }}>
                                        <h5 style={{ paddingLeft: '5px', paddingRight: '5px', fontWeight: 'bold' }}>Reason for download</h5>
                                        <i className={`icon feather icon-${isReasonForDownloadShown ? 'minus' : 'plus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold' }} />
                                      </div>
                                    </a>
                                    <div id="downloadReason" className="panel-body collapse" style={{ maxHeight: '475px', height: 'auto', overflowX: 'hidden' }}>
                                      <div className="card-body">
                                        {this.renderDetailsCard("DOWNLOAD", updateDownloadData.reasonForDownload)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {updateDownloadData ?
                                <div className="col-md-6">
                                  <div className="accordion" id="update-details">
                                    <div className="card addon-card">
                                      <a className="accordion-toggle addon-accordion" style={{ backgroundColor: 'white' }} onClick={() => this.getUpdateReasonData()} data-toggle="collapse" data-parent="#update-details" href="#updateDetails">
                                        <div className="card-header" style={{ borderBottomWidth: '0px', padding: '0px', justifyContent: 'space-between', display: 'flex' }}>
                                          <div className="">
                                            <h5 style={{ paddingLeft: '5px', paddingRight: '5px', fontWeight: 'bold' }} onClick={() => {
                                              this.setState({
                                                isUpdateDownloadShown: !isUpdateDownloadShown
                                              })
                                            }}>Updated Details</h5>
                                          </div>
                                          <div>
                                            <button className="btn btn-outline-dark btn-sm"
                                              onClick={() => this.actionHandle("upload")}
                                              data-toggle="modal" data-target="#reason-modal1">
                                              Upload Report <i className="fa fa-upload" />
                                            </button>
                                            <i className={`icon feather icon-${isUpdateDownloadShown ? 'minus' : 'plus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold' }} />
                                          </div>
                                        </div>
                                      </a>
                                      <div id="updateDetails" className="panel-body collapse" style={{ maxHeight: '475px', height: 'auto', overflowX: 'hidden' }}>
                                        <div className="card-body">
                                          {this.renderDetailsCard("UPDATE", updateDownloadData.updatedDetails)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> : null}
                            </div> : null
                          }

                          <div className="row">
                            <div className="col-md-12">
                              <div className="card table-card">
                                <div className="row-table">
                                  <div className="col-sm-4 card-body-big br">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <i className="icon feather icon-eye text-c-green mb-1 d-block" />
                                      </div>
                                      <div className="col-sm-8 text-md-center">
                                        <h5>Original verification</h5>
                                        <span>{this.state.info.verify}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4 card-body-big br">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <i className="icon feather  icon-file-text text-c-red mb-1 d-block" />
                                      </div>
                                      <div className="col-sm-8 text-md-center">
                                        <h5>Report Status</h5>
                                        <span>{this.state.info.report_status}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4 card-body-big">
                                    <div className="row">
                                      <div className="col-sm-2">
                                        <i className="icon feather icon-help-circle text-c-yellow mb-1 d-block" />
                                      </div>
                                      <div className="col-sm-10 text-md-center">
                                        <h5>Query Request</h5>

                                        <span className="text-c-red">Pending -  {this.state.info.pending}</span>
                                        <span className="text-c-green"> / Closed -  {this.state.info.closed}</span>
                                        <span className="text-c-info"> / Total -  {this.state.info.total}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* FLOW CHART PREVIEW */}
                          {this.state.flowChartPreview ?
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="card query-card">
                                  <div className="card-header d-flex align-items-center justify-content-between">
                                    <h5>Flowchart</h5>
                                    {/* <button
                                      className="ml2 btn btn-danger btnupload float-right"
                                      disabled={this.state.isFlowChartUploading}
                                    >
                                      {this.state.isFlowChartUploading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Download
                                    </button> */}
                                    <button
                                      className="ml2 btn btn-danger btnupload float-right"
                                      disabled={this.state.isFlowChartUploading}
                                      onClick={this.deleteFlowChart}
                                    >
                                      {this.state.isFlowChartUploading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Delete
                                    </button>
                                  </div>
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                      src={this.state.flowChartPreview}
                                      className="img-fluid"
                                      alt="User-Profile-Image"
                                      style={{ padding: '5%' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            : null}

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card query-card">
                                <div className="card-header d-flex justify-content-between">
                                  <h5>Query Manage</h5>
                                  <div className="d-flex">
                                    <button type="button" className="btn btn-info btn-sm" onClick={() => {
                                      this.query.current.updateRaiseQuery()
                                    }}>
                                      Raise Query
                                    </button>
                                    <ReactFileReader
                                      fileTypes={[".pdf"]}
                                      base64={true}
                                      multipleFiles={true}
                                      handleFiles={e => this.handleFiles(e)}
                                    >
                                      <button type="button" className="btn btn-info btn-sm" >
                                        Upload Document
                                      </button>
                                    </ReactFileReader>
                                    <button type="button" className="btn btn-info btn-sm" onClick={() => this.updateNewQueryList()}>
                                      Submit All
                                    </button>
                                  </div>
                                  {/* {multiSelectedQuery.length > 0 ?
                                  <div className="d-flex">
                                    <button type="button" className="btn btn-info btn-sm" onClick={()=>{
                                      this.props.history.push(`/query/add/${1}/${2}`,{
                                        selectedQueryIds:this.state.multiSelectedQuery
                                      })
                                    }}>Reply All</button>
                                    <button type="button" className="btn btn-info btn-sm">Close All</button>
                                  </div>
                                   :null} */}
                                </div>
                                <div className="card-body">
                                  <div className="query-document">
                                    {this.state.queryBase && this.state.queryBase.length
                                      ? this.state.queryBase.map(
                                        (itm: any, index: number) => {
                                          return (
                                            <div key={index}>
                                              <div className="card card-border-c-green">
                                                <span
                                                  onClick={() =>
                                                    this.deleteDoc(itm, index)
                                                  }
                                                >
                                                  <i className="feather icon-x cursor-pointer" />
                                                </span>
                                                <div className="card-body">
                                                  <h5 className="card-title">
                                                    <span className="mb-1 text-c-green">
                                                      <i className="feather icon-file-text" />
                                                    </span>
                                                    <span style={{ fontSize: "14px" }}>
                                                      {itm.fileName || ""}
                                                    </span>
                                                    <span style={{ float: 'right' }}
                                                      className="cursor-pointer"
                                                      data-toggle="modal"
                                                      data-target="#preview-document"
                                                      onClick={() =>
                                                        this.previewDoc(itm)
                                                      }
                                                    >
                                                      <i className="fa fa-eye" />
                                                    </span>
                                                  </h5>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                      : ""}
                                  </div>
                                  <QueryTree fod_fk={this.props.match.params.id}
                                    history={this.props.history} ref={this.query}
                                    setSelectedItem={item => this.setSelectedItem(item)}
                                    getRepliedQueries={this.repliedQueries}
                                    getnewquery={this.getNewQuery}
                                    queryreason={this.state.reasons}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal hide fade in" id="processComplete" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body">
                <h5 style={{ textAlign: 'center' }}> Thanks For Complete Your File:{this.props.match.params.id}</h5>
              </div>
              <div className="modal-footer">
                {showTitle ? null : (<button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.close()}
                  // onClick={this.disableModalHandler}	
                  style={{ width: '50%', fontSize: '17px', margin: 'auto' }}
                > Ok</button>)}
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="complete-process">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-body">
                <h3>Without upload document you can't complete the file</h3>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                // onClick={this.disableModalHandler}	
                >
                  {" "}
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal"
          id="confirm-complete"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body d-flex" style={{ justifyContent: "center" }}>
                <h5 className="modal-title">Are You Sure You Want to Complete this File?</h5>
              </div>
              <div className="modal-footer">
                {this.state.isprocesscomplete ? <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.confirmComplete("6", "typing")}
                  >
                    Yes
                  </button>
                </> : <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.confirmComplete("5", "verification")}
                  >
                    Yes
                  </button>
                </>
                }
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="upload-modal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" style={{ maxWidth: "1400px" }} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload File</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm upload-document-button"
                    onClick={() => this.addUploadDocument()}
                  >
                    Add Document +
                  </button>
                </div>
                {this.state.base.length > 0 && this.state.base.map((document, index) => (
                  <DashUploadDocument
                    baseDetails={document}
                    handleDocumentDetailsChange={
                      this.handleDocumentDetailsChange
                    }
                    handleDocumentFilesChange={
                      this.handleDocumentFilesChange1
                    }
                    deleteDocumentDetails={
                      this.deleteDocumentDetails
                    }
                    deleteFile={this.deleteFile}
                    deedList={this.state.deeds}
                    previewDoc={this.previewDoc}
                    categoriesList={this.state.categoriesList}
                    index={index}
                    newfile={this.state.isNewFile}
                    path="filedashboard"
                  />
                ))}
                <div className="modal-footer" style={{ border: "none" }}>
                  <div style={{ margin: "auto" }}>
                    {this.state.base.length > 0 &&
                      <>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.closeuploadfile}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ margin: "auto" }}
                          // data-dismiss="modal"
                          disabled={this.state.uploadbtn == true}
                          onClick={(e) =>
                            this.validateRequiredFileds(e)
                          }
                        >
                          {this.state.isbtnload && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Confirm
                        </button>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="reason-modal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Status</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <h6>Are you sure you want to delete this document?</h6> */}
                <div className="form-group">
                  {/* <label>Reason</label> */}
                  <textarea
                    name="reason"
                    className="form-control"
                    onChange={e => this.handleChange(e)}
                    value={this.state.reason}
                    placeholder="Reason"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.submitReason}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="reason-modal1">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Reason For Upload</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <React.Fragment>
                    <label>Enter Updated Informations*</label>
                    <ReactFileReader
                      fileTypes={[".doc", ".docx"]}
                      base64={true}
                      // multipleFiles={true}
                      handleFiles={e => this.uploadFile(e)}
                    >
                      <button
                        className="ml2 btn btn-success btnupload"
                      >
                        Select File
                      </button>
                    </ReactFileReader>
                    <span>{uploadFile.fileList[0].name}</span>
                  </React.Fragment>
                  <textarea className="form-control mt-10" name="reason"
                    value={reason}
                    onChange={this.handleReasonChange}
                  >
                  </textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.validateReason}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="send-mail">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Send Mail</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {!quesSubmitted &&
                  <div className="query-question">
                    <div className="row">
                      {queryQues.map((ques: any, index) =>
                        <div className="col-md-6" key={index}>
                          <div className="question">
                            <p>{ques.query_question}</p>
                            <div className="row">
                              <div className="col-md-3">
                                <div
                                  className="custom-control custom-checkbox"
                                >
                                  <input
                                    type="radio"
                                    className=""
                                    name={`answer-${index}`}
                                    checked={ques.answer}
                                    onChange={() => this.handleAnswerChange(index, true)}
                                  />
                                  <label className="">
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div
                                  className="custom-control custom-checkbox"
                                >
                                  <input
                                    type="radio"
                                    className=""
                                    name={`answer-${index}`}
                                    checked={!ques.answer}
                                    onChange={() => this.handleAnswerChange(index, false)}
                                  />
                                  <label className="">
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.saveQueryQuestion}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                }
                {quesSubmitted &&
                  <div className="row">
                    <div className="col-md-6">
                      <label>To:</label>
                      <div className="form-group">
                        {this.state.office_users &&
                          this.state.office_users.length ? (
                          this.state.office_users.map(
                            (user: any, index) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox"
                                  key={index}
                                >
                                  <input
                                    type="checkbox"
                                    className=""
                                    onChange={e =>
                                      this.selectUser(e, user)
                                    }
                                    defaultChecked
                                  />
                                  <label className="">
                                    {user.name + " - " + user.email}
                                  </label>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <button
                            onClick={this.addItem}
                            className="btn btn-primary m-b-20"
                          >
                            + Add User
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Cc:</label>
                      <div className="form-group">
                        {this.state.office_users &&
                          this.state.office_users.length ? (
                          this.state.office_users.map(
                            (user: any, index) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox"
                                  key={index}
                                >
                                  <input
                                    type="checkbox"
                                    className=""
                                    onChange={e =>
                                      this.selectCcUser(e, user)
                                    }
                                    defaultChecked
                                  />
                                  <label className="">
                                    {user.name + " - " + user.email}
                                  </label>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <button
                            onClick={this.addItem}
                            className="btn btn-primary m-b-20"
                          >
                            + Add User
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </div>
              {quesSubmitted &&
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ quesSubmitted: false })
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    // data-dismiss="modal"
                    disabled={queryLoading}
                    onClick={this.saveQuery}
                  >
                    {queryLoading ?
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      :
                      <span>Send</span>
                    }
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        <div
          className={`modal ${this.state.previewDoc ? 'open' : ''}`}
          id="preview-document"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Document Preview</h5>
                <button
                  type="button"
                  className="close cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ previewDoc: '' })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe width="100%" height="400px" src={this.state.previewDoc}></iframe>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setState({ previewDoc: '' })}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>



        <div className="modal hide fade in" id="in_time" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h5>Please Select a Reason To Open File.No: {this.props.match.params.id}</h5>
                <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                  <select
                    className="custom-select"
                    name="in_reason"
                    value={this.state.in_reason.trimStart()}
                    onChange={(e) => this.onchangeReason(e)}
                    required
                    style={{ marginTop: '24px' }}
                  >
                    <option value="select">Select.</option>
                    <option value="New Login">New Login</option>
                    <option value="Manual">Manual</option>
                    <option value="Query Received">Query  Received</option>
                    <option value="Previous Login Pending">Previous Login Pending</option>
                    <option value="Previous Query Pending">Previous Query Pending</option>
                    <option value="Re-Assign">Re-Assign</option>
                    <option value="Doubts&Clarification">Doubts/Clarification</option>
                    <option value="Verification">Verification</option>
                    <option value="Data Entry">Data Entry</option>
                    <option value="Vetting">Vetting</option>
                    <option value="other">Others</option>
                  </select>
                  {
                    selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null
                  }

                </div>

              </div>
              <div className="modal-footer">

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.SubmitREsponse()}
                  disabled={this.state.isLoading}
                  style={{ width: '76px', fontSize: '17px' }}  >  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPage()}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Open File.No: {this.props.match.params.id} </h5>

              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                {/* <div className="col-md-5"> */}
                {this.state.showModal && (
                  <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                    <textarea
                      typeof="text"
                      className="form-control"
                      placeholder="Type Your Reason"
                      name="in_reason" onChange={this.onchange} ref={this.textareaRef} autoFocus={true} />
                    {
                      selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Type Your Reason </p> : null
                    }
                  </div>
                )}
                {/* </div> */}

              </div>
              <div className="modal-footer">

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.SubmitREsponse()}
                  disabled={this.state.isLoading}
                  style={{ width: '76px', fontSize: '17px' }}
                >  {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default FileDashboard;
