import React, { Component } from 'react';
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import moment from 'moment';

interface AllProps {
    complaints: any;
    user: any;
    handleStatusChange: any;
}

export class ComplaintTable extends Component<AllProps> {
    state = {
        statuses: [
            "pending", "working", "completed"
        ],
    }
    
    
    render() {
        const {complaints, user} = this.props;
        complaints.forEach((complaint, index) => { complaint.serial = index + 1; });
        const { statuses } = this.state;
        const columns = [
            {
                header: 'S.no',
                key: 'serial',
                td: (item) => <span>{item.serial}</span>
            },
            {
                header: 'Complaint No',
                key: 'ticketId',
                td: (item) => <span>{item.ticketId}</span>
            },
            {
                header: 'File No',
                key: 'fileNo',
                td: (item) => <span >{item.fileNo}</span>
            },
            {
                header: 'Raised By',
                key: 'userName',
                td: (item) => <span>{item.userName}</span>
            },
            {
                header: 'Raised At',
                key: 'createdDate',
                td: (item) => <span>{moment(item.createdDate).format("DD/MM/YYYY")}</span>
            },
            {
                header: 'Completed At',
                key: 'updatedDate',
                td: (item) => <span>{item.updatedDate === '' ? "-" : moment(item.updateDate).format("DD/MM/YYYY")}</span>
            },
            {
                header: 'Status',
                key: 'status',
                td: (item) => <span>
                    {(user.role_id === "1") ?
                        <select value={item.status} onChange={(e) => this.props.handleStatusChange(e.target.value, item)} >
                            {statuses.map((o: any) => <option value={o}>{o}</option>)}
                        </select>
                    :
                        (item.status === 'working' ?
                            <span className="status working">Working</span>
                            :
                            item.status === 'completed' ?
                            <span className="status completed">Completed</span>
                            :
                            <span className="status pending">Pending</span>
                        )
                    }</span>
            },
            {
                header: '',
                key: 'ticketId',
                td: (item) => 
                <span>
                    <Link to={`/view-complaint/${item.ticketId}`}>
                        View Details
                    </Link>
                </span>
            }
        ]
        return (
            <div className="">
                <ReactFlexyTable className="assigned-table" data={complaints} columns={columns} sortable pageSize={10} />
            </div>
        )
    }
}