import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import Select from 'react-select';
import ReactFileReader from "react-file-reader";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
  } from "react-toasts";
import moment from 'moment';
import { ComplaintTable } from './complaintTable';
interface Complaint {
    fileNo: "";
    bankFormat: "";
    location: "";
    alreadyExist: "";
    toBeChange: "";
    remark: "";

}
export class RegisterComplaint extends Component {
    state = {
        fileNo: "",
        bankFormat: "",
        location: "",
        alreadyExist: "",
        toBeChange: "",
        remark: "",
        userId: "",
        bank_templates: [],
        complaints: [],
        filteredComplaints: [],
        user: {
            role_id: ""
        },
        statuses: [
            { value: 'pending', label: 'Pending' },
            { value: 'working', label: 'Working' },
            { value: 'completed', label: 'Completed' }
        ],
        selectedStatus: "pending",
        files: [],
        searchText: ""
    }
    componentDidMount() {
        this.getTemplates();
        this.getComplaint();
        const user: any = sessionStorage.getItem("user");
        this.setState({user : user ? JSON.parse(user) : ""});
    }
    getTemplates = () => {
        callApi("POST", "bank_temp_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ bank_templates: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    getComplaint = () => {
        const user: any = sessionStorage.getItem("user");
        callApi("POST", "complaint_list.php", {userId: user ? JSON.parse(user)["userId"] : ""})
            .then(res => res.data)
            .then(response => {
                this.setState({
                    complaints: response.data,
                    filteredComplaints: response.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value});
    }
    handleTempChange = (data) => {
        this.setState({bankFormat: data.id});
    }
    handleStatusChange = (data, ticket) => {
        // this.setState({selectedStatus: data.value});
        const statusId = data === "pending" ? "0" : data === "working" ? "1" : "2";
        callApi("POST", "get_status_change.php", {ticketId: ticket.ticketId, Status: statusId})
            .then(res => res.data)
            .then(response => {
                ToastsStore.success("Complaint status changed successfully");
                this.resetForm();
                this.getComplaint();
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleFiles = (files: any) => {
        console.log(files);

        let baseFile: any = this.state.files || [];
        const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
        if (Number(fileSize) > 26214400) {
            ToastsStore.error("File(s) size shouldn't exceed 25 MB");
        } else {
            files.base64.map((file: any, index: number) => {
                baseFile.push({fileContent: file, fileName: files.fileList[index].name });
            });
            this.setState({ files: [ ...baseFile ] });
        }
    }
    handleSearchChange = (e) => {
        const { complaints } = this.state;

        const list = complaints.filter((item: any) => {
            const id = item.ticketId.toLowerCase();
            return id.includes(e.target.value.toLowerCase());
        });
        console.log(e.target.value, list);
        this.setState({
            searchText:  e.target.value,
            filteredComplaints: list
        });
    }
    deleteDoc = (i) => {
        let {files} = this.state;
        files = files.filter((file, index) => index !== i);
        this.setState({files});
    };
    submitComplaint = () => {
        const user: any = sessionStorage.getItem("user");
        const { fileNo, bankFormat, location, alreadyExist, toBeChange, remark, files} = this.state;
        const complaint = {
            fileNo: fileNo,
            bankFormat: bankFormat,
            location: location,
            alreadyExist: alreadyExist,
            toBeChange: toBeChange,
            remark: remark,
            createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            userId: user ? JSON.parse(user)["userId"] : "",
            files
        }
        callApi("POST", "complaint_register.php", complaint)
            .then(res => res.data)
            .then(response => {
                ToastsStore.success("Complaint Added successfully");
                this.resetForm();
                this.getComplaint();
            })
            .catch(err => {
                console.log(err);
            });
    }
    uploadFiles = () => {
        console.log(this.state.files);

    }
    resetForm = () => {
        this.setState({
            fileNo: "",
            bankFormat: "",
            location: "",
            alreadyExist: "",
            toBeChange: "",
            remark: "",
            userId: "",
            files: []
        });
    }
    render() {
        const {bank_templates, bankFormat, fileNo, location, alreadyExist,
                toBeChange, remark, complaints, user, statuses, files, filteredComplaints, searchText} = this.state;
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>Register Complaint</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="form-group row">
                                                            <div className="col-sm-2">
                                                                <label> File No</label>
                                                                <input type="text" className="form-control"
                                                                    name="fileNo" placeholder=""
                                                                    value={fileNo}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <label> Bank Formats</label>
                                                                {bank_templates && bank_templates.length > 0 && bank_templates.map((opt: any) => {
                                                                    opt.label = opt.name; opt.value = opt.id
                                                                }) &&
                                                                    <Select options={bank_templates} name="bank_id"
                                                                        value={bank_templates.find((o: any) => o.value === bankFormat)}
                                                                        onChange={this.handleTempChange}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <label> Location</label>
                                                                <input type="text" className="form-control"
                                                                    name="location" placeholder=""
                                                                    value={location}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-6">
                                                                <label> Already Exist</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="alreadyExist"
                                                                    value={alreadyExist}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label> To be Change</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="toBeChange"
                                                                    value={toBeChange}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-10">
                                                                <label> Remark</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="remark"
                                                                    value={remark}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <label></label>
                                                                <ReactFileReader
                                                                    fileTypes={[".png", ".jpeg", ".jpg", ".svg"]}
                                                                    base64={true}
                                                                    multipleFiles={true}
                                                                    handleFiles={this.handleFiles}
                                                                >
                                                                    <button type="button" className="ml2 btn btn-primary btnupload float-right query-upload">
                                                                        Select Files
                                                                    </button>
                                                                </ReactFileReader>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            {files && files.length > 0 && files.map((itm:any, index) =>
                                                                <div  className="col-sm-4" key={index}>
                                                                    <div className="card card-border-c-green">
                                                                        <span
                                                                            onClick={() => this.deleteDoc(index)}
                                                                        >
                                                                            <i className="feather icon-x cursor-pointer" />
                                                                        </span>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <span className="mb-1 text-c-green">
                                                                                    <i className="feather icon-file-text" />
                                                                                </span>
                                                                                <span>
                                                                                    {itm.fileName || ""}
                                                                                </span>
                                                                                {/* <span style={{ float: 'right' }}
                                                                                    className="cursor-pointer"
                                                                                    data-toggle="modal"
                                                                                    data-target="#preview-document"
                                                                                    onClick={() =>
                                                                                    this.previewDoc(itm)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-eye" />
                                                                                </span> */}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group row justify-content-end">
                                                            <div className="col-sm-2">
                                                                <button type="reset" className="btn btn-primary"
                                                                 onClick={this.resetForm}>Clear</button>
                                                                <button type="button" className="btn btn-primary"
                                                                    onClick={this.submitComplaint}
                                                                >Submit</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>
                                                        <b>Complaint List</b>
                                                    </h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row justify-content-end">
                                                        <div className="col-sm-3">
                                                            <input type="text" className="form-control mb-10"
                                                                name="search" placeholder="search"
                                                                value={searchText}
                                                                onChange={this.handleSearchChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="dt-responsive table-responsive">
                                                            
                                                            {filteredComplaints && filteredComplaints.length &&
                                                                <ComplaintTable 
                                                                    complaints={filteredComplaints} 
                                                                    user={user}
                                                                    handleStatusChange={this.handleStatusChange}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default RegisterComplaint
