import * as React from "react";
import { callApi } from "../../utils/api";
import "react-flexy-table/dist/index.css";
import moment from "moment";
declare var $: any;

interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
}
class FileHistory extends React.Component<AllProps> {
    state = {
        file_log: {
            file_id: 0,
            bank_name: "",
            bank_branch: "",
            lh_branch: "",

            total_time: "",
            front_office: "",
            work_in_progress: "",
            query_waiting_time: "",
            verification_wip: "",
            waiting_time: "",
            extended_hours: "",
            file_logs: [],
        }
    };

    componentDidMount() {
        this.getFileHistory();
    }

    getFileHistory = () => {
        callApi("POST", "file_history_log.php", { file_id: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                if (response.result == true) {
                    this.setState({ file_log: response.response });
                    console.log(response.response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        const { file_log }: any = this.state;
        return (
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row pr-3 pl-3">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="mt-1 mb-1">File History</h5>
                                                </div>
                                                <div className="latest-update-card">
                                                    <div className="card-body pt-3">
                                                        <div className="row mb-3">
                                                            <div className="col-xl-8 col-sm-8 col-md-8">
                                                                <div className="file-details">
                                                                    <h6 className="m-1">File No : <span>{file_log.file_id}</span></h6>
                                                                    <h6 className="m-1">Bank Name : <span>{file_log.bank_name}</span></h6>
                                                                    <h6 className="m-1">Bank Branch : <span>{file_log.bank_branch}</span></h6>
                                                                    <h6 className="m-1">LH Branch : <span>{file_log.lh_branch}</span></h6>
                                                                </div>
                                                                <div className="latest-update-box latest-update-box-file-log mt-3 file-main-card">
                                                                    {file_log && file_log.file_logs && file_log.file_logs.length > 0 && file_log.file_logs.map((file: any, index: number) => (
                                                                        <>
                                                                            {
                                                                                file.date_time && file.date_time != "" ?
                                                                                    <>
                                                                                        <div className={index === 0 ? "row p-t-20 p-b-20" : "row p-b-20"}>

                                                                                            <div className="col-auto text-right update-meta">
                                                                                                <i className="bi bi-star-fill"></i>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill svg-icon" viewBox="0 0 16 16">
                                                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <div className="col p-l-5">
                                                                                                <div className="row file-status-card mb-3" style={
                                                                                                    file.date_time &&
                                                                                                        file.date_time.substring(11) > "19:00:00" || file.date_time.substring(11) < "09:00:00"
                                                                                                        ? { backgroundColor: "yellow" }
                                                                                                        : {}
                                                                                                }>
                                                                                                    <div className="col-md-4">
                                                                                                        <div className="d-flex mt-3">
                                                                                                            <span className="pcoded-micon">
                                                                                                                <i className="feather icon-file-text" style={{ fontSize: "20px" }} />
                                                                                                            </span>
                                                                                                            <p className="ml-1" style={{ color: "black" }}><b>{file.title}</b></p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-5 item-center">
                                                                                                        <p style={{ marginBottom: "5px", color: "black" }}><b>{file.file_by}</b></p>
                                                                                                        <span style={{ fontSize: "14px" }}>{file.date_time}</span>
                                                                                                    </div>
                                                                                                    <div className="col-md-3 item-center">
                                                                                                        {file.estimated_time ? <p style={{ color: "green" }}>Estimated Time: {file.estimated_time}</p> : " "}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {file.time_differ && file.time_differ != "" ?
                                                                                                    (
                                                                                                        <div className="file-duration mb-2" style={file.color ? { backgroundColor: file.color } : { backgroundColor: "#b3b0b0" }}>
                                                                                                            {file.time_differ}
                                                                                                        </div>
                                                                                                    )
                                                                                                    : ""
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : ""
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-sm-4 col-md-4">
                                                                <div className="file-time-history">
                                                                    <div className="file-time-item" style={{ background: "#2c9bf0" }}>
                                                                        <p className="color-white"><b>Total</b></p>
                                                                        <span className="color-white">{file_log.total_time ? file_log.total_time : "00:00:00"}</span>
                                                                    </div>
                                                                    <div className="file-time-item" style={{ background: "#6d302b" }}>
                                                                        <p className="color-white"><b>Front Office</b></p>
                                                                        <span className="color-white">{file_log.front_office ? file_log.front_office : "00:00:00"}</span>
                                                                    </div>
                                                                    <div className="file-time-item" style={{ background: "#046808" }}>
                                                                        <p className="color-white"><b>Work in Progress</b></p>
                                                                        <span className="color-white">{file_log.work_in_progress ? file_log.work_in_progress : "00:00:00"}</span>
                                                                    </div>
                                                                    <div className="file-time-item" style={{ background: "#652cb0" }}>
                                                                        <p className="color-white"><b>Query Waiting Time</b></p>
                                                                        <span className="color-white">{file_log.query_waiting_time ? file_log.query_waiting_time : "00:00:00"}</span>
                                                                    </div>
                                                                    <div className="file-time-item" style={{ background: "#dc7b02" }}>
                                                                        <p className="color-white"><b>verification WIP</b></p>
                                                                        <span className="color-white">{file_log.verification_wip ? file_log.verification_wip : "00:00:00"}</span>
                                                                    </div>
                                                                    <div className="file-time-item" style={{ background: "#f14724" }}>
                                                                        <p className="color-white"><b>Waiting Time</b></p>
                                                                        <span className="color-white">{file_log.waiting_time ? file_log.waiting_time : "00:00:00"}</span>
                                                                    </div>
                                                                    <div className="file-time-item" style={{ background: "#f9c710" }}>
                                                                        <p className="color-white"><b>Extended Hour</b></p>
                                                                        <span className="color-white">{file_log.extended_hours ? file_log.extended_hours : "00:00:00"}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="file-time-history">
                                                                    {file_log && file_log.again_query && file_log.again_query.length > 0 && file_log.again_query.map((query: any, index: number) => (
                                                                        <>
                                                                            {index == 0 && (
                                                                                <h5 className="mt-3 mb-1">Unexpected Upload</h5>
                                                                            )}
                                                                            <div className="row file-status-card mt-1" style={
                                                                                query.date_time &&
                                                                                    query.date_time.substring(11) > "19:00:00" || query.date_time.substring(11) < "09:00:00"
                                                                                    ? { backgroundColor: "yellow", marginLeft: "1%" }
                                                                                    : { marginLeft: "1%" }
                                                                            }>
                                                                                <div className="col-md-4">
                                                                                    <div className="d-flex mt-4">
                                                                                        <span className="pcoded-micon">
                                                                                            <i className="feather icon-file-text" style={{ fontSize: "20px" }} />
                                                                                        </span>
                                                                                        <p className="ml-1" style={{ color: "black" }}><b>{query.title}</b></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">

                                                                                </div>
                                                                                <div className="col-md-5 item-center">
                                                                                    <p style={{ marginBottom: "5px", color: "black" }}><b>{query.file_by}</b></p>
                                                                                    <span style={{ fontSize: "14px" }}>{query.date_time}</span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileHistory;
