import * as React from "react";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import { callApi } from "../../utils/api";
import { Branch } from "../branch/ManageBranch";
import ReactFileReader from "react-file-reader";
import { cloneDeep } from "lodash";
interface AllProps {
    history: any;
    match: any;
}
class AddFiles extends React.Component<AllProps> {
    state = {
        errorteamType: false,
        isLoading: false,
        isDocPreviewLoading: false,
        docReportForPreview: "",
        errorFind: false,
        file: "",
        model: "",
        level_status: "",
        process_type: "",
        modelName: "",
        fileno: "",
        id: "",
        branches: [],
        reporters: [],
        files: [],
        dataLevel1: [],
        dataLevel2: [],
    };
    componentDidMount() {
        if (this.props.match.params.id != 0) {
            this.getModelDetails();
        }
        this.listUser();
    }
    listUser = () => {
        callApi("GET", "law_select.php")
            .then(res => res.data)
            .then(response => {
                if (response["statusCode"] === 200) {
                    // console.log("fileno",response.responseJson);
                    var res = response.responseJson;
                    res.data.map(itm => {
                        itm.usertypelabel = this.getUserType(itm.user_type);
                    });
                    const dataLevel1: any = [];
                    const dataLevel2: any = [];

                    const Level1 = response.responseJson.data.filter((file: any) => {
                        if (file.level_status == "1") {
                            dataLevel1.push(file);
                        } else if (file.level_status == "2") {
                            dataLevel2.push(file);
                        }
                    });

                    if (dataLevel1 && dataLevel1.length > 0) {
                        dataLevel1.forEach((file, index) => {
                            Object.keys(file).forEach(fileKey => {
                                file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                            })
                            file.index = index + 1;
                        })
                    }
                    if (dataLevel2 && dataLevel2.length > 0) {
                        dataLevel2.forEach((file, index) => {
                            Object.keys(file).forEach(fileKey => {
                                file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                            })
                            file.index = index + 1;
                        })
                    }

                    this.setState({ dataLevel1, dataLevel2 });
                } else { }

            })
            .catch(err => {
                console.log(err);
            });
    };
    getUserType = (type: string) => {
        switch (type) {
            case "1":
                return "Admin";
            case "2":
                return "Manager";
            case "3":
                return "Executive";
            case "4":
                return "Front Office";
            default:
                return "";
        }
    };
    getModelDetails = () => {
        callApi("POST", "model_sel.php", { id: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                if (response.status == "success") {
                    const modelDetails = response.responseJson.data[0];
                    this.setState({
                        fileno: modelDetails.fileno,
                        model: modelDetails.model,
                        file: modelDetails.filepath,
                        modelName: modelDetails.model_name,
                        id: modelDetails.id,
                        level_status: modelDetails.level_status,
                        process_type: modelDetails.process_type,
                    });
                }
            });
    };
    deleteDoc = () => {
        this.setState({ file: "" });
    };
    previewDoc = (modal) => {
        this.setState({ docToPreview: modal.file });
    };
    handleFiles = (files: any) => {
        console.log(files);

        let baseFile: any = this.state.files || [];
        const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
        if (Number(fileSize) > 26214400) {
            ToastsStore.error("File(s) size shouldn't exceed 25 MB");
        } else {
            files.base64.map((file: any, index: number) => {
                baseFile.push({ fileContent: file, fileName: files.fileList[index].name });
            });
            this.setState({ files: [...baseFile] });
        }

    }
    handleChange = (e: any) => {
        this.state[e.target.name] = e.target.value;
        this.setState({});
    };
    saveFiles = (e) => {
        console.log("e-------->>>>", e);
        e.preventDefault();
        this.props.match.params.id = Number(this.props.match.params.id);

        if (this.state.level_status == "" || this.state.model == "" || this.state.modelName == "" || this.state.fileno == "" || this.state.files.length == 0) {
            ToastsStore.error("Please enter all the fields");
        } else {
            var fileDetails = {
                model: this.state.model,
                modelName: this.state.modelName,
                fileno: this.state.fileno,
                file: this.state.files[0]['fileContent'],
                level_status: this.state.level_status,
                process_type: this.state.process_type
            }
            callApi("POST", "law_insert.php", fileDetails)
                .then(res => res.data)
                .then(response => {
                    this.setState({ isLoading: true });
                    console.log("response", response);
                    if (response.statusCode == 200) {
                        ToastsStore.success("Model added Successfully");
                        this.props.history.push("/manageFiles");
                    } else {
                        ToastsStore.error("Model Not Successfully");
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    updateFiles = (e) => {
        console.log("this.state.files[0]", this.state.files);
        e.preventDefault();
        var fileDetails = {
            model: this.state.model,
            fileno: this.state.fileno,
            id: this.state.id,
            modelName: this.state.modelName,
            file: this.state.file ? this.state.file : this.state.files[0]['fileContent'],
            level_status: this.state.level_status,
            process_type: this.state.process_type
        }
        callApi("POST", "law_update.php", fileDetails)
            .then(res => res.data)
            .then(response => {
                console.log("response", response);
                if (response.statusCode == 200) {
                    ToastsStore.success("Updated Successfully");
                    this.props.history.push("/manageFiles");
                } else {
                    ToastsStore.error("File Number / Model Number already Exist");
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    render() {
        const levelOneData: any = this.state.dataLevel1;
        const levelTwoData: any = this.state.dataLevel2;
        const levelOneModalNo: any = [];
        const levelTwoModalNo: any = [];
        const levelOneModelSet = new Set();
        const levelTwoModelSet = new Set();

        levelOneData.forEach((modal: any) => {
            if (modal.level_status == "1" && !levelOneModelSet.has(modal.model)) {
                levelOneModalNo.push(modal.model);
                levelOneModelSet.add(modal.model);
            }
        });

        levelTwoData.forEach((modal: any) => {
            if (modal.level_status == "2" && !levelTwoModelSet.has(modal.model)) {
                levelTwoModalNo.push(modal.model);
                levelTwoModelSet.add(modal.model);
            }
        });

        // Generate model options
        // const allModels = Array.from({ length: 100 }, (_, i) => i + 1);
        // const filteredModels = allModels.filter(model => {
        //     if (this.state.level_status === "1") {
        //         return !levelOneModalNo.includes(model.toString());
        //     } else if (this.state.level_status === "2") {
        //         return !levelTwoModalNo.includes(model.toString());
        //     }
        //     return true;
        // });

        // Generate model options
        const allModels = Array.from({ length: 100 }, (_, i) => i + 1);
        const filteredModels = allModels.filter(model => {
            if (this.state.level_status === "1") {
                return !levelOneModalNo.includes(model.toString()) || model.toString() === this.state.model;
            } else if (this.state.level_status === "2") {
                return !levelTwoModalNo.includes(model.toString()) || model.toString() === this.state.model;
            }
            return true;
        });

        console.log("filteredModels", filteredModels);

        return (
            <><div className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT} />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 style={{
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        display: "block",
                                                        fontSize: "17px"
                                                    }}>Add File for Training</h5>
                                                </div>
                                                <div className="card-body">
                                                    <form className="row">
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Level Type</label>
                                                                <select
                                                                    className="custom-select"
                                                                    name="level_status"
                                                                    value={this.state.level_status}
                                                                    onChange={e => this.handleChange(e)}
                                                                >
                                                                    <option value="">Select Level Type</option>
                                                                    <option value="1">Level 1</option>
                                                                    <option value="2">Level 2</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Process Type</label>
                                                                <select
                                                                    className="custom-select"
                                                                    name="process_type"
                                                                    value={this.state.process_type}
                                                                    onChange={e => this.handleChange(e)}
                                                                >
                                                                    <option value="">Select Level Type</option>
                                                                    <option value="1">Process</option>
                                                                    <option value="2">Verification</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Model No</label>
                                                                <select
                                                                    className="custom-select"
                                                                    name="model"
                                                                    value={this.state.model}
                                                                    onChange={e => this.handleChange(e)}
                                                                >
                                                                    <option value=""> Select One</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="11">11</option>
                                                                    <option value="12">12</option>
                                                                    <option value="13">13</option>
                                                                    <option value="14">14</option>
                                                                    <option value="15">15</option>
                                                                    <option value="16">16</option>
                                                                    <option value="17">17</option>
                                                                    <option value="18">18</option>
                                                                    <option value="19">19</option>
                                                                    <option value="20">20</option>
                                                                    <option value="21">21</option>
                                                                    <option value="22">22</option>
                                                                    <option value="23">23</option>
                                                                    <option value="24">24</option>
                                                                    <option value="25">25</option>
                                                                    <option value="26">26</option>
                                                                    <option value="27">27</option>
                                                                    <option value="28">28</option>
                                                                    <option value="29">29</option>
                                                                    <option value="30">30</option>
                                                                    <option value="31">31</option>
                                                                    <option value="32">32</option>
                                                                    <option value="33">33</option>
                                                                    <option value="34">34</option>
                                                                    <option value="35">35</option>
                                                                    <option value="36">36</option>
                                                                    <option value="37">37</option>
                                                                    <option value="38">38</option>
                                                                    <option value="39">39</option>
                                                                    <option value="40">40</option>
                                                                    <option value="41">41</option>
                                                                    <option value="42">42</option>
                                                                    <option value="43">43</option>
                                                                    <option value="44">44</option>
                                                                    <option value="45">45</option>
                                                                    <option value="46">46</option>
                                                                    <option value="47">47</option>
                                                                    <option value="48">48</option>
                                                                    <option value="49">49</option>
                                                                    <option value="50">50</option>
                                                                    <option value="51">51</option>
                                                                    <option value="52">52</option>
                                                                    <option value="53">53</option>
                                                                    <option value="54">54</option>
                                                                    <option value="55">55</option>
                                                                    <option value="56">56</option>
                                                                    <option value="57">57</option>
                                                                    <option value="58">58</option>
                                                                    <option value="59">59</option>
                                                                    <option value="60">60</option>
                                                                    <option value="61">61</option>
                                                                    <option value="62">62</option>
                                                                    <option value="63">63</option>
                                                                    <option value="64">64</option>
                                                                    <option value="65">65</option>
                                                                    <option value="66">66</option>
                                                                    <option value="67">67</option>
                                                                    <option value="68">68</option>
                                                                    <option value="69">69</option>
                                                                    <option value="70">70</option>
                                                                    <option value="71">71</option>
                                                                    <option value="72">72</option>
                                                                    <option value="73">73</option>
                                                                    <option value="74">74</option>
                                                                    <option value="75">75</option>
                                                                    <option value="76">76</option>
                                                                    <option value="77">77</option>
                                                                    <option value="78">78</option>
                                                                    <option value="79">79</option>
                                                                    <option value="80">80</option>
                                                                    <option value="81">81</option>
                                                                    <option value="82">82</option>
                                                                    <option value="83">83</option>
                                                                    <option value="84">84</option>
                                                                    <option value="85">85</option>
                                                                    <option value="86">86</option>
                                                                    <option value="87">87</option>
                                                                    <option value="88">88</option>
                                                                    <option value="89">89</option>
                                                                    <option value="90">90</option>
                                                                    <option value="91">91</option>
                                                                    <option value="92">92</option>
                                                                    <option value="93">93</option>
                                                                    <option value="94">94</option>
                                                                    <option value="95">95</option>
                                                                    <option value="96">96</option>
                                                                    <option value="97">97</option>
                                                                    <option value="98">98</option>
                                                                    <option value="99">99</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        {this.state.process_type != '' && <>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label>Model No</label>
                                                                    <select
                                                                        className="custom-select"
                                                                        name="model"
                                                                        value={this.state.model}
                                                                        onChange={e => this.handleChange(e)}
                                                                    >
                                                                        <option value="">Select One</option>
                                                                        {filteredModels.map(model => (
                                                                            <option key={model} value={model}>{model}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </>}
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Model Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Maximum 100 Characters..."
                                                                    onChange={e => this.handleChange(e)}
                                                                    name="modelName"
                                                                    value={this.state.modelName} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>File No</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter File No"
                                                                    onChange={e => this.handleChange(e)}
                                                                    name="fileno"
                                                                    value={this.state.fileno} />
                                                            </div>
                                                        </div>
                                                        {!this.state.file &&
                                                            <div className="col-md-2">
                                                                <ReactFileReader
                                                                    fileTypes={[".pdf"]}
                                                                    base64={true}
                                                                    multipleFiles={true}
                                                                    handleFiles={this.handleFiles}
                                                                >
                                                                    <button type="button" style={{ margin: "27px" }} className="ml2 btn btn-primary btnupload float-right query-upload">
                                                                        Select Files
                                                                    </button>
                                                                </ReactFileReader>
                                                            </div>}
                                                        <div className="col-md-3">
                                                            {this.state.file &&
                                                                <div className="col-md-4" key={this.state.file}>
                                                                    <div className="card card-border-c-red" style={{ minWidth: "280px" }}>
                                                                        <span
                                                                            onClick={() => this.deleteDoc()}
                                                                        >
                                                                            <i className="feather icon-x" />
                                                                        </span>
                                                                        <div className="card-body" style={{ padding: "3px 21px 2px 37px" }}>
                                                                            <h5 className="card-title">
                                                                                <span className="mb-1 text-c-green">
                                                                                    <i className="feather icon-file-text" />
                                                                                </span>
                                                                                <span className="ml-4">
                                                                                    Model {this.state.model} .pdf
                                                                                </span>
                                                                                <span style={{ float: 'right' }}
                                                                                    data-toggle="modal"
                                                                                    data-target="#preview-document"
                                                                                    onClick={() => this.previewDoc(this.state.file)}
                                                                                >
                                                                                    <i className="fa fa-eye" />
                                                                                </span>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                        <div className="col-md-12">
                                                            {this.props.match.params.id == 0 &&
                                                                <button
                                                                    type="submit"

                                                                    className="btn btn-primary float-right"
                                                                    onClick={(e) => this.saveFiles(e)}
                                                                    style={{ margin: "29px" }}
                                                                    disabled={this.state.isLoading}
                                                                >
                                                                    {this.state.isLoading && (
                                                                        <i className="fa fa-spinner fa-spin"></i>
                                                                    )}
                                                                    Save
                                                                </button>
                                                            }
                                                            {this.props.match.params.id != 0 &&
                                                                <button
                                                                    type="submit"

                                                                    className="btn btn-primary float-right"
                                                                    onClick={(e) => this.updateFiles(e)}
                                                                    style={{ margin: "29px" }}
                                                                    disabled={this.state.isLoading}
                                                                >
                                                                    {this.state.isLoading && (
                                                                        <i className="fa fa-spinner fa-spin"></i>
                                                                    )}
                                                                    Update
                                                                </button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div
                className="modal fade"
                id="preview-document"
                role="dialog"
                aria-hidden="true"
            >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Document Preview</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.isDocPreviewLoading && (
                                    <div className="spin-container" style={{ width: '55em', height: '30em' }}>
                                        <span className="imgLoader" />
                                    </div>
                                )}
                                <iframe width="100%" height="400" frameBorder="0" src={this.state.file}></iframe>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div></>
        );
    }
}
export default AddFiles;
function isEmpty(process_type: string) {
    throw new Error("Function not implemented.");
}

