import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import FileSearchModal from "./FileSearchModal";
import VerifyFileModal from "./VerifyFileModal";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import moment from "moment";
import Pagination from '@material-ui/lab/Pagination';
import SearchBox from "../layout/SearchBox";
interface AllProps {
  history: any;
}
declare var $: any;
class ManageToBeVerifiedFile extends React.Component<AllProps> {
  limitList = [20, 40, 60]
  state = {
    selectVal: false,
    outreason: "",
    files: [],
    fileToBeVerified: 0,
    isFileRefresh: false,
    pageNumber: 1,
    rowCount: this.limitList[0],
    totalCount: 0,
    file_id: "",
    isLoading: false,
    autoAssign: [],
  };
  componentDidMount() {
    this.getAutoAssignStatus();
    // this.getFiles();
    this.getFiles(this.state.pageNumber, this.state.rowCount);
    this.getOutTime();
    this.ouTime();
  }

  getAutoAssignStatus = () => {
    const user: any = sessionStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId))
    console.log("32", getfileId);
    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent
      }

      console.log("72", obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          console.log("76", response);
          // sessionStorage.removeItem("currentfileId");
          this.setState({ isLoading: false });
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          const query_status: any = localStorage.getItem("query_raised_status");
          const file_completed = localStorage.getItem("file_completed_status");
          if (process_officer_id == user_id && take_file != "" && take_file === fileid1 && query_status == "false" && file_completed == "false") {
            this.fileout(fileid1);
          } else {
            sessionStorage.removeItem('currentfileId');
            localStorage.removeItem("query_raised_status");
            localStorage.removeItem("file_completed_status");
          }
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('currentfileId');
        localStorage.removeItem("query_raised_status");
        localStorage.removeItem("file_completed_status");
        // sessionStorage.removeItem("takenfile_id");
      });
  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getFiles = (page_id, row_count) => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "get_by_status.php", { status: 6, userId, page_id, row_count })
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        const totalCount = response.count;
        if (files && files.length > 0) {
          files.forEach((file, index) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
          })
        }
        this.setState({ files, totalCount, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  assignFile = (modal: any) => {
    this.setState({ fileToBeVerified: modal.id });
  };
  goToFileDashboard = id => {
    const url = "/to-be-verified-file";
    localStorage.setItem("url", url);
    let obj = { "file_id": id }
    localStorage.setItem("thisPopup", JSON.stringify(obj))
    sessionStorage.setItem("currentfileId", JSON.stringify(id));
    this.props.history.push("/file-dashboard/" + id);
  };
  assignPickFile = (modal: any) => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";

    const obj = {
      id: modal.id,
      assigned_to: userId,
    };
    callApi("POST", "self_assign.php", obj)
      .then((response) => response.data)
      .then((res) => {
        this.tobeverifiedtime(modal);
        // const notifyData = {
        //   fileId: this.props.fileToBeAssigned,
        //   bank: "test_bank",
        //   branch: "test_branch",
        //   assignedTo: this.state.user,
        //   assignedBy: userId,
        //   notifyType: "message",
        // };
        // notifyToUser("user", {...notifyData,notifyType:'message'}, ["147"]);
        console.log(res);
        ToastsStore.success("File Assigned Successfully");
        // setTimeout(() => {
        //   this.props.history.push("/file-dashboard/" + modal.id);
        // }, 2000);
      });
  };
  tobeverifiedtime(modal) {
    const loginuser: any = sessionStorage.getItem("user1");
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const obj = {
      id: modal.id,
      verification_picked_time: currenttime,
      verification_picked_date: currentdate,
      verification_picked_by: JSON.parse(loginuser),
      reason: "Verification Picked",
      created_by: JSON.parse(loginuser),
      color: "#faad4c"
    };
    callApi("POST", "time_update.php", obj)
      .then(res => res.data)
      .then(response => {

      });
  }
  fileSearch = modal => {
    this.setState({ isFileRefresh: false })
    callApi("POST", "file_search.php", modal)
      .then(res => res.data)
      .then(response => {
        const files: any = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach((file: any, index: number) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
            files.push(file)
          })
        }
        this.setState({ files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      })
  }
  handlePageLimit = (event) => {
    const rowCount = event.target.value;
    const pageNumber = 1;
    this.setState({ pageNumber, rowCount });
    this.getFiles(pageNumber, rowCount);
  }
  handlePagination = (event, value) => {
    this.setState({ pageNumber: value });
    this.getFiles(value, this.state.rowCount);
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
    if (value == "") {
      this.getFiles(this.state.pageNumber, this.state.rowCount);
    }
  };
  searchFile = () => {
    console.log("file id", this.state.file_id);
    if (this.state.file_id == "") {
      this.getFiles(this.state.pageNumber, this.state.rowCount);
    } else {
      let obj = {
        "id": this.state.file_id
      }
      callApi("POST", "file_search.php", obj)
        .then(res => res.data)
        .then(response => {
          console.log("file search response", response);
          const files = response.data;
          const totalCount = response.count;
          if (files && files.length > 0) {
            files.forEach((file, index) => {
              Object.keys(file).forEach(fileKey => {
                file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
              })
              file.index = index + 1;
            })
          }
          this.setState({ files, totalCount, isFileRefresh: true });
        }).catch((err) => {
          console.log(err);
        })
    }
  }
  vEmergentFile = (modal: any) => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";

    const emergent_quota: any = sessionStorage.getItem("emergent_quota");
    const quota: any = emergent_quota - 1;

    sessionStorage.setItem("emergent_quota", quota);
    const obj = {
      fileid: modal.id,
      emergent_status: 1,
      userid: userId,
      current_count: quota
    };
    callApi("POST", "emergent_update.php", obj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode == 200) {
          ToastsStore.success(res.message);
          this.getFiles(this.state.pageNumber, this.state.rowCount);
        } else {
          ToastsStore.success(res.message);
        }
      });
  };
  render() {
    const user: any = sessionStorage.getItem("user");
    const roleType = user ? JSON.parse(user)["roleType"] : "";

    const emergent_quota: any = sessionStorage.getItem("emergent_quota");
    const columns = [
      {
        header: 'S.No',
        key: 'id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.index}</td>
      },
      {
        header: "Actions",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">
            {this.state.autoAssign && this.state.autoAssign.find(
              (data: any) => data.module_name === "Auto Assign" && data.status) ?
              <>
                {file.emergent_status == 1 && emergent_quota > 0 ? (<>
                  {roleType == 1 || roleType == 2 &&
                    <button
                      type="button"
                      className="btn"
                      style={{ marginRight: 10, background: "rgb(227 172 36)", padding: "0px", borderRadius: "4px" }}
                      // onClick={() => this.emergentFile(file)}
                      disabled
                    >
                      Emergent File
                    </button>
                  }
                </>) : (<>
                  {roleType == 1 || roleType == 2 && emergent_quota > 0 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginRight: 10, padding: "4px", borderRadius: "4px" }}
                      onClick={() => this.vEmergentFile(file)}

                    >
                      Emergent File
                    </button>
                  )}
                </>)}

              </> : ""
            }
            <button
              style={{ borderRadius: "4px" }}
              type="button"
              onClick={() => this.assignPickFile(file)}
              className="btn ml-2 btn-primary"
            >
              Pick File
            </button>
          </div>
        }
      },
      {
        header: 'File No',
        key: 'file_id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.file_id}</td>
      },
      {
        header: 'Bank Name',
        key: 'bank_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.bank_name} {file.product_name ? <>({file.product_name})</> : ""}</td>
      },
      {
        header: 'Bank Branch Name',
        key: 'branch_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.branch_name}</td>
      },
      {
        header: 'Property Owner Name',
        key: 'property_ownername',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.property_ownername}</td>
      },
      {
        header: 'Process Officer Name',
        key: 'assigned_to',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.assigned_to}</td>
      },
      {
        header: 'Typing Completed Date',
        key: 'type_complete_date',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.type_complete_date}{file.type_complete_time ? "/" : ""}{file.type_complete_time}</td>
      }
    ];
    const data = this.state.files;
    const { selectVal } = this.state;
    const noOfPages = Math.ceil(this.state.totalCount / this.state.rowCount);
    const COLOR = emergent_quota > 5 ? 'green' : 'red';
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          {/* <h5 className="file-status-header">Process Completed Files</h5> */}
                          <div style={{ display: "flex" }}>
                            <h5 className="file-status-header" >
                              {this.state.autoAssign && this.state.autoAssign.find(
                                (data: any) => data.module_name === "Auto Assign" && data.status) ?
                                <>Verification Queue Files Manage
                                </> : <>Unassigned Files Manage</>}
                            </h5>
                            {roleType == 1 || roleType == 2 && (<div style={{ display: "flex", marginLeft: "2%", marginTop: "2% " }}><p>Balance Emergent Quota - </p><span style={{ background: COLOR, color: "white", borderRadius: "50%", padding: "3px 7px", height: "23px" }}>{emergent_quota}</span></div>)}
                          </div>
                          {/* <div className="file-search-button"><button
                            type="button"
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#file-search-modal"
                          >
                            File Search
                          </button>
                          </div> */}
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-3 d-flex">
                                  <input
                                    className="form-control"
                                    placeholder="File Search . . ."
                                    name="file_id"
                                    type="text"
                                    value={this.state.file_id}
                                    onChange={e => this.handleChange(e)}
                                  />
                                  <button
                                    type="submit"
                                    className=""
                                    onClick={this.searchFile}
                                    style={{ border: "none", background: "#007bff", borderRadius: "5px" }}
                                  >
                                    <i className="feather icon-search input-group-text" />
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>
                          {this.state.isFileRefresh &&
                            <>
                              <ReactFlexyTable data={data} columns={columns} pageSize={20} sortable className='Flexy-table' />
                              <div className="pagination-container">
                                {/* <div>
                                  <span>Show</span>
                                  <Select className="select-container" variant="outlined" value={this.state.rowCount} onChange={e => this.handlePageLimit(e)}>
                                    {this.limitList.map((limitValue, index) => (
                                      <MenuItem key={index} value={limitValue}>
                                        {limitValue}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <span>Rows</span>
                                </div> */}
                                {noOfPages > 1 && (
                                  <Pagination
                                    count={noOfPages}
                                    defaultPage={this.state.pageNumber}
                                    onChange={this.handlePagination}
                                    showFirstButton
                                    showLastButton
                                  />
                                )}
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                {/* </div> */}

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: '76px', fontSize: '17px' }}
                  disabled={this.state.isLoading}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
        <VerifyFileModal
          fileToBeVerified={this.state.fileToBeVerified}
          getFiles={this.getFiles}
          assignedFrom={"unassign"}
        />
        <FileSearchModal fileStatus={6} searchFileCallback={(data) => this.fileSearch(data)} />
      </section>
    );
  }
}
export default ManageToBeVerifiedFile;
