import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { callApi, API_ENDPOINT } from "../../utils/api";
import Select from 'react-select';
import MaskedInput from "react-text-mask";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import _ from 'lodash';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { CircularProgress } from "@material-ui/core";
import edit from '../../assets/images/edit.svg';
import VerificationEOD from './verificationEod';
import ReactFileReader from "react-file-reader";
import CountdownTimer from "../file/CountdownTimer";
import { log } from 'console';
declare var $: any;
interface AllProps {
    history: any,
    match: any
}
export class WorkFileDetails extends Component<AllProps> {
    state = {
        report: "",
        rep_id: "",
        remote_users: [],
        isapproved: 0,
        office_users: [],
        documents: [],
        selectedDoc: "",
        qaList: [],
        currentIndex: -1,
        bank: [],
        bankBranch: [],
        lawHandBranch: [],
        selectedBank: "",
        selectedBranch: "",
        selectedLawBranch: "",
        inEcFinalNotes: "",
        date: "",
        docList: [],
        scrutinyList: [],
        notes: "",
        document: "",
        isDocShow: false,
        currentFileId: null,
        fileFrameId: 1,
        mainFrameId: 1,
        isMainFrameLoading: false,
        isSideFrameLoading: false,
        action: "",
        reason: "",
        verification: {
            highlight: false,
            dataEntry: false,
            flowChart: false,
            hintFile: false,
            errorIdentified: false,
            noOflod: "",
            errorSection: [],
            otherSection: "",
            errorTypes: [],
            errorDetails: "",
            totalTime: "",
        },
        uploadFile: {
            base64: "",
            fileList: [{ name: "" }]
        },
        branches: []
    };
    document = {
        base: "",
        numPages: 0,
        pageNumber: 1
    };
    countdownTimerRef: CountdownTimer | null = null;
    componentDidMount() {
        this.getBank();
        this.getLawHandsBranches();
        var docOverview = sessionStorage.getItem("overviewDocument");
        console.log("docOverview-------->>>", docOverview);
        if (docOverview != null) {
            this.setState({ report: docOverview, enableRefresh: true });
            $("#root").unbind();
        } else {
            this.getFileOverview();
        }
        this.getDocList();
        this.getFileQA();
        this.getScrutinizedDocs();
        this.getBankBranches();
    }
    getBank = () => {
        callApi("POST", "bank_get.php")
            .then((response) => {
                console.log(response);
                this.setState({
                    bank: response.data.data,
                    selectedBank: response.data.data[0].id
                }, () => {
                    this.getBankBranch();
                });
            });
    }
    getBankBranches() {
        callApi("GET", "bank_branch_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ branches: result.data, filteredBranches: [] });
            })
            .catch(err => {
                console.log(err);
            });
    }
    getBankBranch = () => {
        callApi("POST", "verify_branch_get.php", {
            id: this.state.selectedBank
        })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 200) {
                    const data = response.data.responseJson.dataList;

                    this.setState({
                        bankBranch: data,
                    });
                }
            });
    }

    getValueFromList = (array, filterValue) => {
        let result = null, currentValue: any = [];
        if (filterValue) {
            currentValue = array.filter((val: any) => val.id === filterValue);
            if (currentValue.length > 0) {
                result = currentValue[0].value
            }
        }
        return result;
    }
    getFormFieldValues = () => {
        callApi("POST", "verify_get.php", {
            file_id: this.props.match.params.id
        })
            .then((response) => {
                if (response.data && response.data.result) {
                    let { selectedBank, selectedBranch, selectedLawBranch, branches, bankBranch, bank, lawHandBranch, date, notes, inEcFinalNotes } = this.state;
                    const responseData = response.data.data[0];
                    selectedBank = this.getValueFromList(bank, responseData.bank_id) || "";
                    // selectedBranch = this.getValueFromList(bankBranch, responseData.branch_id) || "";
                    selectedBranch = this.getValueFromList(branches, responseData.branch_id) || "";
                    selectedLawBranch = this.getValueFromList(lawHandBranch, responseData.law_hand_branch_id) || "";
                    inEcFinalNotes = responseData.in_ec_final || "";
                    date = responseData.date || "";
                    notes = responseData.cretificate_notes || "";
                    this.setState({
                        selectedBank, selectedBranch, selectedLawBranch, date, notes, inEcFinalNotes
                    })
                    this.filterBranches(responseData.bank_id);
                    console.log("this.state---", this.state);
                }
            })
    }

    filterBranches = bankid => {
        const filteredBranches = this.state.branches.filter(
            (branch: any) => branch.bank_id === bankid
        );
        const state = this.state;
        state.branches = filteredBranches;
        this.setState({ ...state });
    };
    getLawHandsBranches = () => {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawHandBranch: response.data }, () => {
                    this.getFormFieldValues();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    getDocList = () => {
        const props: any = this.props;
        callApi("POST", "doc_list_get.php", { id: props.match.params.id })
            .then(res => res.data)
            .then(response => {
                console.log(response);
                if (response.data && response.data.length) {
                    const titleDeed = _.filter(response.data, ['type', 'Title Deed'])[0];
                    console.log(titleDeed);
                    this.setState({ docList: titleDeed.documents });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    getScrutinizedDocs = () => {
        callApi("POST", "doc_scrutinize_list.php", { id: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                console.log(response);
                const scr = response.data.filter(itm => itm.type === "scrutiny");
                const ec = response.data.filter(itm => itm.type === "ec-form");
                if (scr && scr.length) {
                    scr.forEach((doc, index) => {
                        doc.index = index + 1;
                    });
                }
                if (ec && ec.length) {
                    ec.forEach((doc, index) => {
                        doc.index = index + 1;
                    });
                }
                const scrutinies = scr.concat(ec);
                this.setState({ scrutinyList: scrutinies });
            })
            .catch(err => {
                console.log(err);
            });
    };
    getFileOverview = () => {
        const props: any = this.props;
        callApi("POST", "final_report_get.php", {
            file_idfk: props.match.params.id,
            check: true
        })
            .then(res => res.data)
            .then(response => {
                if (response && response.data && response.data.length) {
                    if (response.data[0].rep_id) {
                        const state = response.data[0];
                        this.setState({ ...state, isMainFrameLoading: false });
                    } else {
                        const props: any = this.props;
                        const user: any = sessionStorage.getItem("user");
                        const userId = user ? JSON.parse(user)["userId"] : "";
                        callApi("POST", "template/tests/scrutiny_report.php", {
                            file_idfk: props.match.params.id,
                            check: true, userId
                        })
                            .then(res => res.data)
                            .then(response => {
                                this.setState({ report: "" });
                                this.setState({ report: response.data, isMainFrameLoading: false });
                            }).catch(() => {
                                this.setState({ isMainFrameLoading: false })
                            })
                    }
                    this.setState({ isapproved: response.data[0].isapproved });
                } else this.setState({ isMainFrameLoading: false })
            }).catch(() => {
                this.setState({ isMainFrameLoading: false })
            })
    };
    getFileQA = () => {
        const props: any = this.props;
        const obj = {
            file_id: props.match.params.id
        }
        callApi("POST", "file_question_answer_get.php", obj)
            .then(res => res.data)
            .then(response => {
                let resData = response.responseJson.data;
                resData = _.sortBy(resData, (o) => {
                    return Number(o.id);
                });
                this.setState({ qaList: resData });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleChange = (name, e) => {
        console.log(name, e);
        this.setState({ [name]: e.value }, () => {
            if (name === 'selectedBank') {
                this.getBankBranch();
            }
        });

    }
    handleqaChange = (e, i) => {
        const { qaList } = this.state;
        const list: any = qaList;
        list[i].answer = e.target.value;
        this.setState({ qaList: list });
    }
    questionHandleChange = (e, i) => {
        const list: any[] = this.state.qaList;
        list[i]['question'] = e;
        this.setState({
            isEdited: true,
            qaList: list
        });
    }
    getFile = (id) => {
        this.setState({ isDocShow: true, currentFileId: id, fileFrameId: 1 });
        callApi("POST", "getbaseby_id.php", { id })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    const document = response.data[0].base[0].base;
                    this.setState({ document, fileFrameId: !this.state.fileFrameId });
                }
                this.setState({ isSideFrameLoading: false })
            })
            .catch(err => {
                this.setState({ isSideFrameLoading: false })
                console.log(err);
            });
    }
    saveQADetails = () => {
        const { selectedBank, selectedBranch, selectedLawBranch, notes, date, inEcFinalNotes } = this.state;
        const obj = {
            file_id: this.props.match.params.id,
            bank_id: selectedBank,
            in_ec_final: inEcFinalNotes,
            branch_id: selectedBranch,
            date: date,
            law_hand_branch_id: selectedLawBranch,
            cretificate_notes: notes
        }
        console.log(obj);
        callApi("POST", "verify_update.php", obj)
            .then(res => res)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    // ToastsStore.success("work file details saved succesfully")
                } else {
                    ToastsStore.error("Error in storing, Please try again!")
                }
            })
            .catch(err => {
                console.log(err);
            });
        this.saveQA();
    }
    saveQA = () => {
        const props: any = this.props;
        const obj = {
            file_id: props.match.params.id,
            answers: this.state.qaList
        }
        console.log(obj);

        callApi("POST", "answer_add.php", [obj])
            .then(res => res)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    // ToastsStore.success("work file Q & A are saved succesfully")
                    ToastsStore.success("Changes saved successfully")
                } else {
                    ToastsStore.error("Error in storing, Please try again!")
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    changeHandler = (stateName, value) => {
        this.setState({ [stateName]: value })
    }
    actionHandle = (action) => {
        this.setState({ action });
    }
    handleReasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }
    setVerify = (verify) => {
        this.setState({ verification: verify });
    }
    validateReason = () => {
        if (this.state.reason === "") {
            ToastsStore.error("Please Enter Reason")
        } else {
            if (this.state.action === "download") {
                this.downloadDoc();
            } else {
                $("#reason-modal").modal("hide");
                $("#verification-modal").modal("show");
            }
        }
    }
    downloadDoc = () => {
        $("#reason-modal").modal("hide");
        const props: any = this.props;
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const obj = {
            fileid: props.match.params.id,
            reason: this.state.reason,
            createdBy: userId
        }
        callApi("POST", "download_log.php", obj)
            .then(res => res)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    // ToastsStore.success("work file Q & A are saved succesfully")
                    ToastsStore.success("Reason saved successfully");
                    this.downloadDocs();
                    this.clearModal();
                } else {
                    ToastsStore.error("Error in storing, Please try again!")
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    //Download docs from external api
    downloadDocs = () => {
        const props: any = this.props;
        // ToastsStore.success("Downloaded Successful!");
        callApi("POST", "template/tests/shell.php", {
            id: props.match.params.id,
            responseType: 'blob'
        })
            .then(res => res.data)
            .then(response => {
                //ToastsStore.success("Downloaded Successfully!");
                window.open(API_ENDPOINT + "template/tests/shell.php?id=" + props.match.params.id, "_self")
            })
    }
    uploadDoc = () => {
        $("#verification-modal").modal("hide");
        const props: any = this.props;
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const obj: any = { ...this.state.verification };
        obj.fileId = props.match.params.id;
        obj.reason = this.state.reason;
        obj.file = this.state.uploadFile.base64.split(",")[1];
        obj.createdBy = userId;
        const errorTypes: any = [];
        obj.errorTypes.forEach((type: any) => {
            errorTypes.push({ type });
        });
        obj.errorTypes = errorTypes.length > 0 ? errorTypes : ['1', '2'];
        obj.errorTypes = ["1", "2"];
        callApi("POST", "template/tests/upload_log.php", obj)
            .then(res => res)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    // ToastsStore.success("work file Q & A are saved succesfully")
                    ToastsStore.success("Reason saved successfully");
                    this.clearModal();
                } else {
                    ToastsStore.error("Error in storing, Please try again!");
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    uploadFile = (file: any) => {
        console.log(file);
        this.setState({ uploadFile: file });
    }
    clearModal = () => {
        const verification = {
            highlight: false,
            dataEntry: false,
            flowChart: false,
            hintFile: false,
            errorIdentified: false,
            noOflod: "",
            errorSection: [],
            otherSection: "",
            errorTypes: [],
            errorDetails: "",
            totalTime: "",
        }
        this.setState({
            reason: "",
            verification
        });
    }
    updatedRemainingTime(time) {
    }
    render() {
        const { report, bank, selectedBank, bankBranch, selectedBranch, date, lawHandBranch, selectedLawBranch,
            qaList, currentIndex, docList, scrutinyList, notes, document, isDocShow, inEcFinalNotes,
            isMainFrameLoading, isSideFrameLoading, action, reason, verification, uploadFile, branches } = this.state;
        let initialTimeInMinutes: any = sessionStorage.getItem('updatedRemainingTime');
        console.log("branches", selectedBranch);

        return (
            <div className="pcoded-content" id="work-file-details">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {initialTimeInMinutes && initialTimeInMinutes > 0 && (
                                                    <CountdownTimer
                                                        initialTime={initialTimeInMinutes}
                                                        updatedTime={(time) => this.updatedRemainingTime(time)}
                                                        ref={(ref) => (this.countdownTimerRef = ref)}
                                                    />
                                                )}
                                            </div><br />
                                        </div>
                                        <div className="card-header policy-header">
                                            <h5>Work File Verification</h5>
                                            {/* <button className="btn btn-outline-dark download"
                                                onClick={() => this.actionHandle("download")}
                                                data-toggle="modal" data-target="#reason-modal">
                                                Download Doc
                                            </button>
                                            <button className="btn btn-outline-dark"
                                                onClick={() => this.actionHandle("upload")}
                                                data-toggle="modal" data-target="#reason-modal">
                                                Upload Doc
                                            </button> */}
                                            <button className="btn btn-primary" onClick={this.saveQADetails}>
                                                Save
                                            </button>
                                            <button className="btn btn-primary" onClick={() => this.props.history.goBack()}>
                                                Back
                                            </button>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {report ?
                                                        <div style={{ display: 'flex', marginLeft: '-10px', justifyContent: 'flex-start' }}>
                                                            <button className="btn btn-primary" disabled={isMainFrameLoading} onClick={() => this.setState({ mainFrameId: !this.state.mainFrameId, isMainFrameLoading: true }, () => {
                                                                this.getFileOverview();
                                                            })}>
                                                                <i className={`feather icon-refresh-cw ${isMainFrameLoading ? "fa fa-refresh fa-spin" : null}`} style={{ marginRight: 0 }} />
                                                            </button>
                                                        </div>
                                                        : null}
                                                    <div
                                                        className="row form-document"
                                                        style={{ height: "95vh", overflow: 'hidden' }}
                                                    >
                                                        {
                                                            report ?
                                                                <iframe width="99%" height="990" frameBorder="0" src={this.state.report} title="workfile"></iframe>
                                                                :
                                                                <CircularProgress style={{ marginLeft: '50%', marginTop: '30%' }} />
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6"
                                                    style={{
                                                        // border: '1px solid #dee2e6',
                                                        // borderRadius: '8px',
                                                        height: '95vh'
                                                    }}
                                                >
                                                    {!isDocShow ?

                                                        <div className="container"
                                                            style={{
                                                                width: "100%", height: `${isDocShow ? '50%' : '100%'}`, overflow: 'auto',
                                                                border: '1px solid #dee2e6',
                                                                borderRadius: '8px',
                                                            }}

                                                        >
                                                            {/* <div className="row mt-10">
                                                                <div className="col-sm-6">
                                                                    <label> Bank</label>
                                                                    {bank.length > 0 && bank.map((opt: any) => {
                                                                        opt.label = opt.name; opt.value = opt.id
                                                                    }) &&
                                                                        <Select options={bank} name="selectedBank"
                                                                            value={bank.find((o: any) => o.value === selectedBank)}
                                                                            onChange={(e) => this.handleChange('selectedBank', e)}
                                                                        />
                                                                    }
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label> Bank Branch</label>
                                                                    {branches.map((opt: any) => {
                                                                        opt.label = opt.name; opt.value = opt.id;
                                                                    }) &&
                                                                        <Select options={branches} name="selectedBranch"
                                                                            value={branches.find((o: any) => o.value === selectedBranch)}
                                                                            onChange={(e) => this.handleChange("selectedBranch", e)}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div> */}
                                                            <div className="row mt-10">
                                                                <div className="col-sm-6">
                                                                    <label> Date</label>
                                                                    <MaskedInput
                                                                        className="form-control"
                                                                        mask={[
                                                                            /[0-3]/,
                                                                            /\d/,
                                                                            ".",
                                                                            /[0-1]/,
                                                                            /\d/,
                                                                            ".",
                                                                            /[1-2]/,
                                                                            /\d/,
                                                                            /\d/,
                                                                            /\d/
                                                                        ]}
                                                                        placeholder="dd.mm.yyyy"
                                                                        value={date}
                                                                        onChange={(e) => this.handleChange('date', e.target)}
                                                                        name="date"
                                                                    />
                                                                </div>
                                                                {/* <div className="col-sm-6">
                                                                    <label> Law Hands Branch</label>
                                                                    {lawHandBranch.length > 0 && lawHandBranch.map((opt: any) => {
                                                                        opt.label = opt.name; opt.value = opt.id
                                                                    }) &&
                                                                        <Select options={lawHandBranch} name="selectedLawBranch"
                                                                            value={lawHandBranch.find((o: any) => o.value === selectedLawBranch)}
                                                                            onChange={(e) => this.handleChange("selectedLawBranch", e)}
                                                                        />
                                                                    }
                                                                </div> */}
                                                            </div>
                                                            <div className="mt-10">
                                                                <label>In EC Final <span className="inputEgMsg">Eg. Except ICICI Bank Limited Mortage</span></label>
                                                                <textarea className="form-control" name="inEcFinalNotes" value={inEcFinalNotes} onChange={(e) => this.handleChange('inEcFinalNotes', e.target)}></textarea>
                                                            </div>
                                                            <div className="mt-10">
                                                                <h6><strong>Description of Property</strong></h6>
                                                                <div className="dt-responsive table-responsive">
                                                                    <table
                                                                        id="bank-files-table"
                                                                        className="table nowrap"
                                                                    >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                <th className="text-center">As Per Doc No</th>
                                                                                <th className="text-center">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {docList && docList.map((item: any, index) =>
                                                                                <tr key={index} className="cursor-pointer">
                                                                                    <th onClick={() => this.getFile(item.file_id)}>{item.date}</th>
                                                                                    <th className="text-center" onClick={() => this.getFile(item.file_id)}>{item.doc_no}</th>
                                                                                    <th className="text-center">
                                                                                        <Link to={{ pathname: `/general-detail/1/${this.props.match.params.id}/${item.file_id}`, hash: "Verify" }} target="_blank">
                                                                                            <img src={edit} alt="edit" />
                                                                                        </Link>
                                                                                    </th>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="mt-10">
                                                                <h6><strong>List of Documents</strong></h6>
                                                                <div className="dt-responsive table-responsive">
                                                                    <table
                                                                        id="bank-files-table"
                                                                        className="table nowrap"
                                                                    >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>S.No</th>
                                                                                <th>Date</th>
                                                                                <th>Doc No</th>
                                                                                <th>Particulars</th>
                                                                                <th className="text-center">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {scrutinyList.map((item: any, index) =>
                                                                                <tr key={index} className="cursor-pointer">
                                                                                    <th onClick={() => this.getFile(item.fod_fk)}>{item.position}</th>
                                                                                    <th onClick={() => this.getFile(item.fod_fk)}>{item.date}</th>
                                                                                    <th onClick={() => this.getFile(item.fod_fk)}>{item.doc_no}</th>
                                                                                    <th onClick={() => this.getFile(item.fod_fk)}>{item.particulars}</th>
                                                                                    <th className="text-center">
                                                                                        <Link to={{ pathname: `/${item.type}/${this.props.match.params.id}/${item.fod_fk}/2`, hash: "Verify" }} target="_blank">
                                                                                            <img src={edit} alt="edit" />
                                                                                        </Link>
                                                                                    </th>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="mt-10">
                                                                <h6><strong>Q &amp; A</strong></h6>
                                                                <table className="table table-striped">
                                                                    <tbody>
                                                                        {qaList.map((qa: any, index) =>
                                                                            <tr key={qa.id}>
                                                                                <td style={{ borderRight: '1px solid #dee2e6', padding: '0' }}>
                                                                                    <SunEditor defaultValue={qa.question}
                                                                                        height='100'
                                                                                        name=" question"
                                                                                        disable={true}
                                                                                        hideToolbar={true}
                                                                                        readOnly={true}
                                                                                        setDefaultStyle="font-family: 'BookmanOldStyle'; font-size: 12px;"
                                                                                        onChange={(e) => this.questionHandleChange(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ width: '30%', border: '2px solid #dee2e6' }}>
                                                                                    <textarea className="form-control" id="exampleFormControlTextarea1" value={qa.answer}
                                                                                        onFocus={e => {
                                                                                            this.setState({ currentIndex: index })
                                                                                            e.target.select()
                                                                                        }}
                                                                                        onChange={(e) => this.handleqaChange(e, index)}
                                                                                        rows={2}
                                                                                    />
                                                                                    {currentIndex === index ?
                                                                                        <p id={`textarea_${index}`} style={{ fontSize: '10px', color: 'grey', marginTop: '3px' }}>Maximum 250 characters</p>
                                                                                        : null}
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {this.state.qaList.length === 0 &&
                                                                            <tr>
                                                                                <td>There is no Q &amp; A for this file.</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="mt-10">
                                                                <h6><strong>Remarks</strong></h6>
                                                                <textarea className="form-control" name="notes" value={notes} onChange={(e) => this.handleChange('notes', e.target)}></textarea>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {isDocShow &&
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <div className="row p-2 align-items-center">

                                                                    <button
                                                                        className="btn btn-primary"
                                                                        disabled={isSideFrameLoading}
                                                                        onClick={() => this.setState({
                                                                            isSideFrameLoading: true
                                                                        }, () => {
                                                                            this.getFile(this.state.currentFileId)
                                                                        })}
                                                                    >
                                                                        <i className={`feather icon-refresh-cw ${isSideFrameLoading ? "fa fa-refresh fa-spin" : null}`} style={{ marginRight: 0 }} />
                                                                    </button>
                                                                    <button className="btn btn-primary ml-3" onClick={() => this.changeHandler("isDocShow", false)}>
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="container mt-10"
                                                                style={{
                                                                    width: "100%", height: `${isDocShow ? '95vh' : '100%'}`,
                                                                    border: '1px solid #dee2e6',
                                                                    borderRadius: '8px'
                                                                }}
                                                            >
                                                                <div
                                                                    className="row form-document"
                                                                    style={{ overflowY: 'hidden' }}
                                                                >
                                                                    {
                                                                        document ?
                                                                            <iframe key={this.state.fileFrameId} width="99%" height="680" frameBorder="0" src={document} title="workfile"></iframe>
                                                                            :
                                                                            <CircularProgress style={{ marginLeft: '50%', marginTop: '30%' }} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VerificationEOD
                    verify={verification}
                    setVerify={this.setVerify}
                    saveUploadData={this.uploadDoc}
                />
                <div className="modal" id="reason-modal">
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                {action === "download" ?
                                    <h5 className="modal-title">Reason For Download</h5>
                                    :
                                    <h5 className="modal-title">Reason For Upload</h5>
                                }
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {action === "download" ?
                                        <label>Enter Reason*</label>
                                        :
                                        <React.Fragment>
                                            <label>Enter Updated Informations*</label>
                                            <ReactFileReader
                                                fileTypes={"image/*"}
                                                base64={true}
                                                // multipleFiles={true}
                                                handleFiles={e => this.uploadFile(e)}
                                            >
                                                <button
                                                    className="ml2 btn btn-success btnupload"
                                                // disabled={this.state.isFlowChartUploading}
                                                >
                                                    {/* {this.state.isFlowChartUploading ? (
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                ) : <i className="icon feather icon-upload" />} */}

                                                    Select File
                                                </button>
                                            </ReactFileReader>
                                            <span>{uploadFile.fileList[0].name}</span>
                                        </React.Fragment>
                                    }
                                    <textarea className="form-control mt-10" name="reason"
                                        value={reason}
                                        onChange={this.handleReasonChange}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn "
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                {action === "download" ?
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.validateReason}
                                    >
                                        Download
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.validateReason}
                                    >
                                        Upload
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkFileDetails
