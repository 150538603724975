import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
  } from "react-toasts";

interface AllProps {
    match: any;
    history: any;
}
export class ViewComplaint extends Component<AllProps> {
    state = {
        complaint: {
            fileNo: "",
            bankFormat: "",
            location: "",
            alreadyExist: "",
            toBeChange: "",
            remark: "",
            userId: "",
            files: []
        }
    }
    componentDidMount() {
        this.getComplaint();
    }
    getComplaint = () => {
        callApi("POST", "get_complaint_detail.php", {ticketId: this.props.match.params.id})
            .then(res => res.data)
            .then(response => {
                this.setState({ complaint: response.data[0] });
            })
            .catch(err => {
                console.log(err);
            });
    }
    render() {
        const {complaint} = this.state;
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>View Complaint</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="form-group row">
                                                            <div className="col-sm-2">
                                                                <label> File No</label>
                                                                <input type="text" className="form-control"
                                                                    name="fileNo" placeholder=""
                                                                    defaultValue={complaint.fileNo}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label> Bank Formats</label>
                                                                <input type="text" className="form-control"
                                                                    name="bankFormat" placeholder=""
                                                                    defaultValue={complaint.bankFormat}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label> Location</label>
                                                                <input type="text" className="form-control"
                                                                    name="location" placeholder=""
                                                                    defaultValue={complaint.location}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-5">
                                                                <label> Already Exist</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="alreadyExist"
                                                                    defaultValue={complaint.alreadyExist}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <label> To be Change</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="toBeChange"
                                                                    defaultValue={complaint.toBeChange}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-10">
                                                                <label> Remark</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="remark"
                                                                    defaultValue={complaint.remark}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            {complaint && complaint.files && complaint.files.length && complaint.files.map((file:any) =>
                                                                <div className="col-sm-3">
                                                                    <img src={file.fileContent} alt="fileimg" height="100" width="150"/>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group row justify-content-end">
                                                            <div className="col-sm-3">
                                                                <Link to="/register-complaint">
                                                                    <button type="button" className="btn btn-primary">Back</button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ViewComplaint
